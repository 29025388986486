export default class Calculator {
    constructor(params = null) {
        if (!params || params == null) {
            console.warn('bad initialization');
            return;
        }

		if (!params.totalAmount || params.totalAmount == null || params.totalAmount <= 0)
		{
			console.warn('No amount is specified');
			return;
		}

		//2022-11-25 : A la demande de CTU, on n'affiche pas le prix mensualisé si le prix > 300K€
		if (php_vars.max_price_for_monthly_fee < parseInt(params.totalAmount))
		{
			return;
		}

		this.totalAmount = params.totalAmount;
		this.duration = params.duration ? params.duration : 240 //20 ans par défaut
		this.rate = params.rate ? params.rate : 1.5 //1.5% par défaut

		this.calculate();
    }

	calculate() {
		let rateInPercentage = this.rate / 100;
		this.monthlyPayment = (this.totalAmount * rateInPercentage / 12) / (1 - Math.pow((1 + rateInPercentage / 12), (this.duration * -1)));
		this.totalCost = this.monthlyPayment * this.duration - this.totalAmount;
	}

	result() {
		return {
			'monthlyPayment': Math.round(this.monthlyPayment * 100) / 100,
			'totalCost': Math.round(this.totalCost * 100) / 100
		}
	}
}

// =========================================
// 	Tools > Share :
//
//  	Partager l'url de la page
// =========================================

/**
 * Partage de l'url de la page courrante
 *
 * 	Si le navigateur est compatible avec la Share API,
 *	-> préférer ce comportement.
 *	-> Sinon, nouverture d'une modale, permtant :
 *	- le partage sur :
 *		• les réseaux sociaux
 *		• via courriel
 *	- La copie du lien de la page en cours.
 *
 */
export default class Entities_Tools_Share
{
    constructor( Share_Component = null ) {

		if ( Share_Component == null ) {
			console.error("Share : Pas de conteneur trouvé")
			return false
		}
		// Gestion de la modale
		this.shareButton 		= Share_Component.querySelector('.js-tools-share-btn')
		this.shareDialog 		= Share_Component.querySelector('.js-tools-share-modale')
		this.closeButton 		= Share_Component.querySelector('.js-tools-share-close')
		// Copier le lien
		this.copyURL_link 		= Share_Component.querySelector('.js-tools-share-method-copyURL-link')
		this.copyURL_btn 		= Share_Component.querySelector('.js-tools-share-method-copyURL-btn')
		// Composer le lien de partage

		let ogTitle = document.head.querySelector("[property='og:title'][content]");
		let ogUrl = document.head.querySelector("[property='og:url'][content]")

		if (ogTitle && ogUrl)
		{
			this.pageToShare 		= {
				'title':			document.head.querySelector("[property='og:title'][content]").content,
				'url': 				document.head.querySelector("[property='og:url'][content]").content,
			}
		}
		const ref = this


		this.shareButton.addEventListener('click', async function(event) {
			// Si le navigateur est compatible avec la Navigator Share API ?
			// TODO : WebShareAPI bloquée pour l'instant car… Ticket Mantis#644
			//   ? 	: Demande client
			// if ( navigator.share ) {
			if ( 1 == 2 ) {
				// ceci affiche le menu de partage du navigateur
				// => On le gère en asynchrone comme un beau gosse…
				try {
					await navigator.share({
						title: 	ref.pageToShare.title,
						url: 	ref.pageToShare.url,
					})
					console.info('Share > Lien de la page partagé');
				}
				catch( err ) {
					console.error('Share > Error: ' + err)
				}
			}
			else
			{
				// Sinon, afficher la modale faite maison
				ref.shareDialog.classList.toggle('is-open');
				// TODO: Gérer ARIA
				// bouton fermer la modale
				ref.closeButton.addEventListener('click', event => {
					ref.shareDialog.classList.remove('is-open');
				})
			}
		})


		this.shareThePage(ref)
	}


	/**
	 * Partager le lien de la page
	 */
	shareThePage ( Share_Component ) {

		//#region	Méthode 1 : Partager via réseaux


			// Actions sur les liens de partage via pop-up :
			const action_links = document.querySelectorAll('.js-socialshare')
			if ( action_links.length > 0 ) {
				// Modifier le comportement des liens si >= 769px
				action_links.forEach( action_link => {
					action_link.addEventListener("click", (event) => {
						if ( Modernizr.mq('(min-width: 769px)') )
						{
							event.preventDefault();
							// récupérer l'action dans le lien
							let action_link_url = action_link.attributes.href.value
							let action_link_service = action_link.getAttribute('data-service')

							// Obtenir taille du viewport
							// Préparer la taille de la popup
							var viewport_width = document.documentElement.clientWidth
							var viewport_height = document.documentElement.clientHeight

							// Personnalisation en fonction du service
							if ( action_link_service== 'facebook' ){
								// mode pop-up dans l'url
								action_link_url = action_link_url.replace('display=page', 'display=popup')
							}
							if ( action_link_service== 'fb-messenger' ){
							}
							if ( action_link_service== 'whatsapp' ){
							}
							if ( action_link_service== 'instagram' ){
							}
							if ( action_link_service== 'twitter' ){
							}
							if ( action_link_service== 'pinterest' ){
							}
							if ( action_link_service== 'linkedIn' ){
							}
							window.open( action_link_url, '', 'width=560,height=485' )
						}
					})
				})
			}

		//#endregion	===========================================



		/*#region	Méthode 2 : Copier le lien de la page		*/

			// Copier l'url courrante
			this.copyURL_btn.addEventListener('click', event => {

				// Ajouter l'url au clipboard
				const copied = this.addToClipboard( Share_Component.pageToShare.url )
				if ( copied != null ) {
					const button = Share_Component.copyURL_btn
					const btn_original_content = button.textContent
					button.classList.add('is-copied')
					button.innerHTML = '<span class="icon-g-check"></span> Lien copié'
					// Puis, Retour à la normale deux secondes plus tard
					setTimeout(() => {
						button.textContent = btn_original_content;
						button.classList.remove('is-copied');
					}, 3000);
				}
			})

		//#endregion	===========================================	*/
	}



	/**
	 * Ajoute le lien au clipboard
	 */
	async addToClipboard( string ) {
		try {
			await navigator.clipboard.writeText( string );
			return string;
		} catch (err) {
			console.error('Partage > Erreur de copie : ', err);
			return false;
		}
	}

}


/**
 * Tester la présence du composant de partage
 * et initialiser le composant
 */
function shareThePage_init ()
{
	const Share_Components = document.querySelectorAll('.js-tools-shareThePage');
	if ( Share_Components.length > 0 ) {
		Share_Components.forEach(Share_Component => {
			new Entities_Tools_Share( Share_Component )
		});
	}
}

// Auto charger le composant
window.addEventListener('DOMContentLoaded', (e) => {
	shareThePage_init()
})




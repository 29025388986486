import Cookies from "../cookies";
import { EntitiesNotification } from "../notifications/notification";

export default class EntitiesFavorites {
	constructor(triggerSelector = null, params = {}) {
		if (triggerSelector == null) {
			console.warn(
				"Aucun sélecteur n'est défini pour la gestion de favoris"
			);
			return;
		}

		params.classes = params.classes ? params.classes : {};

		this.triggerSelector = triggerSelector;
		this.favoriteCookieName = "favorites-mfc";
		this.favoriteCookieExpirationDay = 365;
		this.favorites = [];
		this.favoriteIdAttribute = params.favoriteIdAttribute
			? params.favoriteIdAttribute
			: "data-favorite";
		this.classes = {
			inactive: params.classes.inactive
				? params.classes.inactive
				: "btn--fav-add",
			active: params.classes.active
				? params.classes.active
				: "btn--fav-remove",
		};
		this.userLoggedIn = php_vars.is_logged_in;

		this.initState();
		if (this.userLoggedIn) {
			this.mergeFavoritesFromBDDToCookie();
		}
		this.initEvents();
		this.reinitAfterSearch();
	}

	/**
	 * Récupère les éléments permettant d'ajouter / supprimer des favoris
	 */
	getTriggers() {
		return document.querySelectorAll(this.triggerSelector);
	}

	/**
	 * Récupère les favoris et change la classe des favoris actifs
	 */
	initState() {
		let ref = this;

		let favoritesInCookie = this.getFavoritesInCookie();

		this.triggers = this.getTriggers();

		if (favoritesInCookie == null) {
			return;
		}

		this.favorites = JSON.parse(this.getFavoritesInCookie());
		this.favorites.forEach((favorite) => {
			ref.addActiveClass(favorite);
		});
	}

	/**
	 * Branche les listeners aux triggers
	 */
	initEvents() {
		let ref = this;

		if (this.triggers != null)
		{
			this.triggers.forEach((trigger) => {
				if (!trigger.getAttribute('data-listener'))
				{
					trigger.setAttribute('data-listener', true);
					trigger.addEventListener("click", function () {
						let favoriteId = trigger.getAttribute(ref.favoriteIdAttribute);

						if (ref.isFavorite(favoriteId)) {
							ref.removeFavorite(favoriteId);
						} else {
							ref.addFavorite(favoriteId);
						}

						ref.saveFavoritesInCookie();
					});
				}
			});
		}
	}

	/**
	 * Après une recherche, réinitialise les triggers pour rebrancher les événements aux nouveaux éléments
	 */
	reinitAfterSearch() {
		let ref = this;

		document.addEventListener(
			"afterSearchRenderArea",
			function () {
				//On attend que les vignettes soient dans le DOM
				let thumbsLoaded = setInterval(function () {
					let triggers = ref.getTriggers();

					if (triggers && triggers.length > 0) {
						clearInterval(thumbsLoaded);
						ref.initState();

						if (ref.userLoggedIn) {
							ref.mergeFavoritesFromBDDToCookie();
						}

						ref.initEvents();
					}
				}, 100);
			}, false
		);
	}

	/**
	 * Vérifie que l'id passée en paramètre est dans la liste des favoris
	 * @param {*} item l'id du post à vérifier
	 */
	isFavorite(item) {
		return this.favorites.some((c) => c == item);
	}

	//#region Manipulation de la liste

	/**
	 * Ajoute des favoris à la liste
	 * @param {*} item l'id du post à ajouter en favori
	 */
	addFavorite(item) {
		if (!this.isFavorite(item)) {
			this.favorites.push(item);
			this.addFavoriteToBdd(item);
			this.addActiveClass(item);

			let qsSeparator = php_vars.connexion_url.indexOf('?') > -1 ? "&" : "?";

			if (!this.userLoggedIn) {
				let message = {
					title: '<span class="icon-g-check"></span> Favori <span class="highlight">enregistré</span>',
					text: `<p class="mbm">Retrouvez vos favoris dans votre&nbsp;espace&nbsp;personnel.</p>
				 <p class="alert alert-block"><strong>Créez votre espace personnel pour retrouver vos favoris, quelque soit l'endroit d'où vous vous connectez</p>`,
					ctas: [
						{
							attributes: {
								class: "btn",
							},
							anchor: '<span class="icon-g-profil"></span> Créer mon compte',
							// url:
							// 	php_vars.connexion_url +
							// 	"?return_url=" +
							// 	window.location,
							url:
								php_vars.connexion_url + qsSeparator +
								"return_url=" +
								window.location + "_**_actionCrm=EspacePersonnel_**_labelCrm=AjouterFavori#account-tab__connexion",
						},
					],
				};
				new EntitiesNotification(message);
			}
		}
	}

	/**
	 * Supprime des favoris de la liste
	 * @param {*} item l'id du post à supprimer des favoris
	 */
	removeFavorite(item) {
		let index = this.favorites.indexOf(item);
		if (index > -1) {
			this.favorites.splice(index, 1);
			this.removeFavoriteToBdd(item);
			this.removeActiveClass(item);
		}
	}

	//#endregion

	//#region Manipulation des classes CSS

	/**
	 * Ajoute la classe de favori actif au bouton
	 * @param {*} item la valeur de l'attribut contenant l'id du post
	 */
	addActiveClass(item) {
		let buttons = document.querySelectorAll(
			"[" + this.favoriteIdAttribute + '="' + item + '"]'
		);

		if (buttons && buttons.length > 0) {
			buttons.forEach((button) => {
				button.classList.remove(this.classes.inactive);
				button.classList.add(this.classes.active);
			});
		}
	}

	/**
	 * Enlève la classe de favori actif au bouton
	 * @param {*} item la valeur de l'attribut contenant l'id du post
	 */
	removeActiveClass(item) {
		let buttons = document.querySelectorAll(
			"[" + this.favoriteIdAttribute + '="' + item + '"]'
		);

		if (buttons && buttons.length > 0) {
			buttons.forEach((button) => {
				button.classList.remove(this.classes.active);
				button.classList.add(this.classes.inactive);
			});
		}
	}

	//#endregion

	//#region Manipulation du cookie

	/**
	 * Sauvegarde la liste des favoris dans le cookie
	 */
	saveFavoritesInCookie() {
		const json = JSON.stringify(this.favorites);
		Cookies.set(
			this.favoriteCookieName,
			json,
			this.favoriteCookieExpirationDay
		);
	}

	/**
	 * Récupère la liste des favoris stockés dans le cookie
	 */
	getFavoritesInCookie() {
		return Cookies.get(this.favoriteCookieName);
	}

	//#endregion

	//#region Manipulation de la BDD

	/**
	 * Ajoute un favori à la liste de favoris de l'utilisateur
	 * @param {*} item
	 */
	addFavoriteToBdd(item) {
		if (!this.userLoggedIn) {
			return;
		}

		let xhr = new XMLHttpRequest();
		xhr.open("POST", php_vars.ajaxurl, true);
		xhr.setRequestHeader(
			"Content-Type",
			"application/x-www-form-urlencoded;"
		);
		xhr.send("action=add_favorite&favorite_id=" + item);

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status !== 200) {
				console.log("une erreur est survenue");
			}
		};
	}

	/**
	 * Supprime un favori de la liste de favoris de l'utilisateur
	 * @param {*} item
	 */
	removeFavoriteToBdd(item) {
		if (!this.userLoggedIn) {
			return;
		}

		let xhr = new XMLHttpRequest();
		xhr.open("POST", php_vars.ajaxurl, true);
		xhr.setRequestHeader(
			"Content-Type",
			"application/x-www-form-urlencoded;"
		);
		xhr.send("action=remove_favorite&favorite_id=" + item);

		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status !== 200) {
				console.log("une erreur est survenue");
			}
		};
	}

	/**
	 * Si l'utilisateur est connecté, récupère la liste des favoris en base de données et l'ajoute à la liste des cookies
	 */
	mergeFavoritesFromBDDToCookie() {
		//Si l'utilisateur n'est pas connecté, on arrête.
		if (!this.userLoggedIn) {
			return;
		}

		let ref = this;

		//On récupère les favoris stockés en base de donnée
		let xhr = new XMLHttpRequest();
		xhr.open("POST", php_vars.ajaxurl, true);
		xhr.async = false;
		xhr.setRequestHeader(
			"Content-Type",
			"application/x-www-form-urlencoded;"
		);
		xhr.send("action=get_favorites");
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4 && xhr.status === 200) {
				let list = JSON.parse(xhr.response).data;
				//On ajoute les favoris absents du cookie dans celui-ci
				list.forEach((favorite) => {
					if (!ref.isFavorite(favorite)) {
						ref.favorites.push(favorite);
						ref.addActiveClass(favorite);
					}
				});

				ref.saveFavoritesInCookie();

				ref.mergeFavoritesFromCookieToBDD();
			}
		};
	}

	/**
	 * On ajoute les favoris présents dans le cookie dans la BDD si ils n'y sont pas
	 */
	mergeFavoritesFromCookieToBDD() {
		//Si l'utilisateur n'est pas connecté, on arrête.
		if (!this.userLoggedIn) {
			return;
		}

		let ref = this;
		let xhr = new XMLHttpRequest();
		xhr.open("POST", php_vars.ajaxurl, true);
		xhr.setRequestHeader(
			"Content-Type",
			"application/x-www-form-urlencoded;"
		);
		xhr.send("action=add_favorites&favorite_ids=" + ref.favorites);
	}

	//#endregion
}

import "./css/autocomplete-style.scss";

/***
 * Système d'autocompletion sur des champs de formulaire
 */
!(function () {
	document.addEventListener("DOMContentLoaded", function () {
		initAutocomplete();
	});
})();

/**
 * Classe d'autocompletion
 */
export default class Autocomplete {
	// Constructeur
	constructor(selector, params = {}) {
		if (!selector || selector == null) {
			console.warn(this.getAlerts("bad-initialization"));
			return;
		}

		this.selector = selector;
		this.classes = params.classes;
		this.allowTownSearch =
			params.allowTownSearch ||
			selector.hasAttribute("data-autocomplete-town-search"); //Autorise la recherche de ville
		this.allowDepartmentSearch =
			params.allowDepartmentSearch ||
			selector.hasAttribute("data-autocomplete-department-search"); //Autorise la recherche de département
		this.allowRegionSearch =
			params.allowRegionSearch ||
			selector.hasAttribute("data-autocomplete-region-search"); //Autorise la recherche de région
		this.multiSelect =
			params.type == "multi" ||
			(selector.hasAttribute("data-autocomplete-type") &&
				selector.getAttribute("data-autocomplete-type") == "multi"); //Autorise le mulitchoix
		this.autoLocate =
			params.autoLocate ||
			selector.hasAttribute("data-autocomplete-autolocate"); //Affiche le bouton pour localiser l'utilisateur
		this.minCharForSearch = 3; //Nombre minimum de caractère à saisir avant le déclenchement de la recherche //Todo permettre le paramétrage de cette valeur
		this.triggerField = null;
		this.resetButton = document.querySelector(
			".filter-group-geolocation-everywhere"
		);
		this.autoLocateUi = null;
		this.resultsField = null;

		if (this.checkInit()) {
			return;
		}

		this.prepareDom();
		this.initializeEvents();
		this.getResultsFromRequest();
	}

	/**
	 * Vérifie que l'autocompletion n'a pas déjà été initialisée
	 */
	checkInit() {
		if (!this.selector.getAttribute("autocomplete-initialized")) {
			this.selector.setAttribute("autocomplete-initialized", true);
			return false;
		}

		return true;
	}

	/**
	 * Ajoute les éléments nécessaires à l'autocompletion
	 */
	prepareDom() {
		this.createWrapper();
		this.createChoicesWrapper();
		this.createResultWrapper();
		this.createAutoLocateUI();
		this.createTriggerHiddenField();
		this.createLoader();
	}

	/**
	 * Créé le container du
	 * - champ d'autocompletion
	 * - du conteneur append
	 * - de la zone de résultats
	 */
	createWrapper() {
		let parent = this.selector.parentNode;

		let wrapper = document.createElement("div");
		wrapper.classList.add("autocomplete-wrapper", "input-group");

		if (
			this.classes &&
			this.classes.wrapper &&
			this.classes.wrapper != ""
		) {
			wrapper.classList.add(this.classes.wrapper);
		}

		this.wrapper = parent.insertBefore(wrapper, this.selector);
		wrapper.appendChild(this.selector);

		// groupe append
		let wrapper_append = document.createElement("div");
		wrapper_append.classList.add("input-group-append");
		this.wrapper_append = wrapper.appendChild(wrapper_append);
	}

	/**
	 * Binde les événements sur les frappes au clavier
	 */
	initializeEvents() {
		let ref = this;

		//Entrée de saisie
		this.selector.addEventListener("keydown", function (event) {
			// si c'est la touche Entrer :
			// ne rien faire pour éviter de valider le formulaire
			if (event.keyCode && event.keyCode == 13) {
				event.preventDefault();
			}

			ref.cleanChoices();
			ref.showLoader();
		});

		//Sortie de champ
		this.selector.addEventListener("blur", function () {
			ref.hideLoader();
			if (this.value == "") {
				ref.emptyResults();
			}
		});

		// Fin d'entrée de saisie
		this.selector.addEventListener(
			"keyup",
			debounce(function () {
				let query = ref.selector.value;

				if (query !== "" && query.length >= ref.minCharForSearch) {
					ref.search();
				} else {
					ref.hideLoader();
				}
			}, 1000)
		);

		//Réinitialisation
		if (this.resetButton) {
			this.resetButton.addEventListener("click", function () {
				ref.emptyResults();
			});
		}
	}

	/**
	 * Effecue la requête vers l'API localisation
	 */
	search() {
		let ref = this;
		let path = php_vars.ajaxurl;
		let xhr = new XMLHttpRequest();
		let parameters = {
			action: "autocomplete",
			term: ref.selector.value,
			"town-search": ref.allowTownSearch,
			"department-search": ref.allowDepartmentSearch,
			"regions-search": ref.allowRegionSearch,
		};
		xhr.open("GET", path + formatParams(parameters), true);
		xhr.send(parameters);
		xhr.onload = function () {
			if (xhr.status >= 200 && xhr.status < 300) {
				let response = JSON.parse(xhr.responseText);
				let datas = response.data;
				if (datas.regions) {
					ref.addRegionsToChoices(datas.regions);
				}
				if (datas.departments) {
					ref.addDepartmentsToChoices(datas.departments);
				}
				if (datas.towns) {
					ref.addTownsToChoices(datas.towns);
				}
				ref.hideLoader();
			} else {
				console.warn(
					"La requête vers " +
						path +
						formatParams(parameters) +
						" a échoué"
				);
			}
		};
	}

	/**
	 * Effecue la requête vers l'API localisation via le champ caché
	 */
	searchByCode(code, locationType) {
		let ref = this;
		let path = php_vars.ajaxurl;
		let xhr = new XMLHttpRequest();
		let parameters = {
			action: "autocomplete_by_code",
			code: code,
			type: locationType,
		};
		xhr.open("GET", path + formatParams(parameters), true);
		xhr.send(parameters);
		xhr.onload = function () {
			if (xhr.status >= 200 && xhr.status < 300) {
				let response = JSON.parse(xhr.responseText);
				let region = response.data;

				let item = {
					type: locationType,
					id: region.id,
					name: region.name,
				};

				ref.addChoiceToResults(item);
				ref.hideLoader();
			} else {
				console.warn(
					"La requête vers " +
						path +
						formatParams(parameters) +
						" a échoué"
				);
			}
		};
	}

	//#region 	Choix

	/**
	 * Créé le container de choix
	 */
	createChoicesWrapper() {
		let wrapper = document.createElement("div");
		wrapper.classList.add("autocomplete-choices-wrapper");

		if (
			this.classes &&
			this.classes.wrapper &&
			this.classes.choices != ""
		) {
			wrapper.classList.add(this.classes.choices);
		}

		this.wrapper.appendChild(wrapper);
		this.choicesWrapper = wrapper;
	}

	/**
	 * Supprime les choix actuels
	 */
	cleanChoices() {
		this.choicesWrapper.innerHTML = "";
	}

	/**
	 * Ajoute les choix retrounés par l'API dans le wrapper de choix
	 * @param { Le titre des résultats} title
	 * @param { Les choix proposés } choices
	 */
	addChoices(title = "", choices = []) {
		let ref = this;
		if (!choices || choices.length == 0) {
			return;
		}

		let wrapper = document.createElement("ul");
		wrapper.classList.add("autocomplete-choices");
		wrapper.setAttribute("role", "listbox"); // focussable au clavier
		wrapper.setAttribute("tabindex", "0"); // focussable au clavier

		if (title && title != "") {
			let titleWrapper = document.createElement("li");
			titleWrapper.classList.add("autocomplete-choices-title");

			if (
				this.classes &&
				this.classes.choices &&
				this.classes.choices != ""
			) {
				titleWrapper.classList.add(this.classes.choices + "-title");
			}

			titleWrapper.innerHTML = title;
			wrapper.appendChild(titleWrapper);
		}

		choices.forEach((item) => {
			if (!ref.isInResults(item)) {
				let elt = document.createElement("li");
				elt.classList.add("autocomplete-choices-item");
				elt.setAttribute("role", "option"); // focussable au clavier
				elt.setAttribute("tabindex", "0"); // focussable au clavier

				if (
					this.classes &&
					this.classes.choices &&
					this.classes.choices != ""
				) {
					elt.classList.add(this.classes.choices + "-item");
				}

				elt.innerHTML = item.name;
				wrapper.appendChild(elt);
				elt.addEventListener("click", function () {
					ref.addChoiceToResults(item);
				});
			}
		});

		this.choicesWrapper.appendChild(wrapper);
	}

	/**
	 * Ajoute les résultats de type région
	 * @param { Les régions à ajouter } regions
	 */
	addRegionsToChoices(regions) {
		let choices = [];
		regions.forEach((region) => {
			let item = {
				type: "region",
				id: region.id,
				name: region.name,
			};
			choices.push(item);
		});
		this.addChoices("Régions", choices);
	}

	/**
	 * Ajoute les résultats de type département
	 * @param { Les départements à ajouter } departments
	 */
	addDepartmentsToChoices(departments) {
		let choices = [];
		departments.forEach((department) => {
			let item = {
				type: "department",
				id: department.id,
				name: department.name + " (" + department.code + ")",
			};
			choices.push(item);
		});
		this.addChoices("Départements", choices);
	}

	/**
	 * Ajoute les résultats de type ville
	 * @param { Les villes à ajouter } towns
	 */
	addTownsToChoices(towns) {
		let choices = [];

		towns.forEach((town) => {
			let item = {
				type: "town",
				id: town.id,
				name: town.name + " (" + town.zipcode + ")",
				departmentId: town.department_id,
				departmentCode: town.department_code,
			};
			choices.push(item);
		});
		choices.reverse();
		this.addChoices("Villes", choices);
	}

	//#endregion

	//#region 	Loader via la liste

	/**
	 * Créée le loader
	 */
	createLoader() {
		let loader = this.wrapper_append.querySelector(".autocomplete-loader");

		if (loader == null) {
			loader = document.createElement("div");
			loader.classList.add("autocomplete-loader");
			loader.setAttribute("aria-label", "Actualisation des résultats…");

			if (
				this.classes &&
				this.classes.loader &&
				this.classes.loader != ""
			) {
				loader.classList.add(this.classes.loader);
			}
			// Constuire le spinner
			let spinner = document.createElement("div");
			spinner.classList.add("lds-spinner");
			// éléments du spinner
			for (var i = 0; i < 12; i++) {
				let spin = document.createElement("div");
				spinner.append(spin);
			}
			loader.appendChild(spinner);
			this.wrapper.appendChild(loader);
			this.loader = loader;
		}
	}

	/**
	 * Affiche l'indicateur de chargement de résultats
	 */
	showLoader() {
		if (!this.loader) {
			this.createLoader();
		}
		if (!this.loader.classList.contains("loading")) {
			this.loader.classList.add("loading");
		}

		if (this.autoLocate) {
			this.autoLocateUi.classList.add("hide");
		}
	}

	/**
	 * Cache l'indicateur de chargement de résultats
	 */
	hideLoader() {
		if (this.loader) {
			this.loader.classList.remove("loading");
		}

		if (this.autoLocate) {
			this.autoLocateUi.classList.remove("hide");
		}
	}

	//#endregion

	//#region 	Résultats

	/**
	 * Créer le container de résultats
	 */
	createResultWrapper() {
		//Création du wrapper pour l'affichage visuel des résultats sélectionnés
		if (this.multiSelect) {
			let wrapper = document.createElement("div");
			wrapper.classList.add("autocomplete-multiselect-wrapper");
			if (
				this.classes &&
				this.classes.results &&
				this.classes.results != ""
			) {
				wrapper.classList.add(this.classes.results);
			}
			this.wrapper.parentNode.appendChild(wrapper);
			this.resultWrapper = wrapper;
		}

		this.resultsField = this.addHiddenField("autocomplete-results", null);
	}

	/**
	 * Ajoute un champ caché dans le container de l'autocomplétion
	 * @param { Le nom du champ caché } name
	 * @param { L'id du champ caché } id
	 */
	addHiddenField(name, id = null) {
		let input = this.wrapper.querySelector('[name="' + name + '"]');

		if (!input || input.length == 0) {
			input = document.createElement("input");
			input.setAttribute("name", name);
			input.setAttribute("id", id == null ? name : id);
			input.setAttribute("value", "");
			input.setAttribute("type", "hidden");

			this.wrapper.appendChild(input);

			return input;
		}
	}

	/**
	 * Ajoute un champ caché qui ajoute une valeur dans les choix lorsque la valeur est modifiée
	 */
	createTriggerHiddenField() {
		let input = document.createElement("input");
		input.setAttribute("name", "autocomplete-trigger-field");
		input.setAttribute("id", "autocomplete-trigger-field");
		input.setAttribute("location-type", "region"); // région par défaut, possibilité de le passer en département (ou en ville ?)
		input.setAttribute("value", "");
		input.setAttribute("type", "hidden");

		this.wrapper.appendChild(input);

		this.triggerField = input;

		//On observe le champ
		let ref = this;
		const targetNode = input;
		var config = { attributes: true };

		const callback = function (mutationsList, observer) {
			for (const mutation of mutationsList) {
				if (mutation.type === "attributes") {
					if (targetNode.value == "") {
						ref.emptyResults();
					} else {
						ref.showLoader();
						ref.searchByCode(
							targetNode.value,
							targetNode.getAttribute("location-type")
						);
					}

					break;
				}
			}
		};

		const observer = new MutationObserver(callback);
		observer.observe(targetNode, config);

		return input;
	}

	/**
	 * Retourne la liste des résultats
	 * @returns
	 */
	getResults() {
		let results = [];
		if (this.resultsField.value != "") {
			results = JSON.parse(this.resultsField.value);
		}

		return results;
	}

	/**
	 * Ajoute un résultat dans la liste
	 * @param {*} item
	 */
	addResult(item) {
		let results = this.getResults();

		if (this.multiSelect) {
			if (!this.isInResults(item)) {
				results.push(item);
			}
		} else {
			results = [];
			results.push(item);
		}
		this.saveResults(results);
	}

	/**
	 * Vérifie qu'un choix est déjà dans les résultats sélectionnés
	 * @param { Le choix à tester } item
	 */
	isInResults(item) {
		const results = this.getResults();
		return results.some((r) => r.id == item.id);
	}

	/**
	 * Ajoute un choix à la liste des résultats
	 * @param { le choix à ajouter aux résultats } item
	 */
	addChoiceToResults(item) {
		if (this.multiSelect && this.isInResults(item)) {
			return;
		}

		if (!this.multiSelect) {
			this.selector.value = item.name;
		} else {
			this.addChip(item);
			this.selector.value = "";
		}

		this.addResult(item);
		document.dispatchEvent(
			new CustomEvent("autocompleteSelected", {
				item: item,
			})
		);
		this.cleanChoices();
	}

	/**
	 * Supprimer un choix des résultats
	 * @param {*} item Le choix a supprimer des résultats
	 */
	removeChoiceFromResults(item) {
		let results = this.getResults();
		results = results.filter((x) => x.id != item.id);
		this.saveResults(results);
	}

	/**
	 * Sauvegarde les résultats
	 */
	saveResults(results) {
		this.resultsField.value = JSON.stringify(results);
	}

	/** Vide le champ des résultats (Remise à 0) */
	emptyResults() {
		this.resultsField.value = "";

		//On vide les chips
		if (this.multiSelect) {
			this.resultWrapper.innerHTML = "";
		}
	}

	/**
	 * Ajoute un tag correspondant au choix sélectionné
	 * @param { * } item Le choix à ajouter en tag
	 */
	addChip(item) {
		let ref = this;
		let chip = document.createElement("button");
		chip.classList.add("chip");

		if (this.classes && this.classes.chip && this.classes.chip != "") {
			titleWrapper.classList.add(this.classes.chip);
		}
		chip.setAttribute("id", item.id);
		chip.innerHTML = item.name;
		chip.title = "Supprimer la recherche " + item.name;

		let closeWrapper = document.createElement("span");
		closeWrapper.classList.add("chip-close");
		let closeIcon = document.createElement("span");
		let closeInfo = document.createElement("span");

		closeIcon.classList.add("chip-close-icon");
		closeIcon.innerHTML = "X";
		closeInfo.classList.add("sr-only");
		closeInfo.innerHTML = "Supprimer la recherche " + item.name;

		closeWrapper.append(closeIcon, closeInfo);
		chip.appendChild(closeWrapper);

		chip.addEventListener("click", function () {
			ref.removeChip(chip);
			ref.removeChoiceFromResults(item);
		});

		this.resultWrapper.appendChild(chip);
	}

	/**
	 * Supprime un tag
	 * @param { * } chip Le tag a supprimer des résultats
	 */
	removeChip(chip) {
		chip.parentNode.removeChild(chip);
	}

	/**
	 * Récupère les données de recherche de la requête, envoyées via une variable js dans le PHP
	 */
	getResultsFromRequest() {
		if (typeof autocomplete_search == "undefined") {
			return;
		}

		if (Array.isArray(autocomplete_search)) {
			autocomplete_search.map((location) => {
				let item = {
					type: location.type,
					id: location.id,
					name: location.label,
				};

				if (!this.isInResults(item)) {
					if (this.multiSelect) {
						this.addChip(item);
					} else {
						this.selector.value = item.name;
					}

					this.addResult(item);
				}
			});
		}
	}

	//#endregion

	//#region AutoLocate

	/**
	 * Créée les éléments d'auto localisation
	 */
	createAutoLocateUI() {
		// si le navigateur comprend la locationAPI
		if (!this.autoLocate || !navigator.geolocation || this.autoLocateUi) {
			return;
		}
		let ref = this;
		// bouton d'auto-localisation
		let button = document.createElement("button");
		button.type = "button";
		button.classList.add("btn", "autocomplete-autolocate-button");
		if (
			this.classes &&
			this.classes.autolocate &&
			this.classes.autolocate != ""
		) {
			button.classList.add(this.classes.autolocate + "-button");
		}
		// icône
		let buttonIcon = document.createElement("span");
		buttonIcon.classList.add("icon", "icon-g-geolocalisation");
		buttonIcon.setAttribute("aria-hidden", true);
		// label
		let buttonSr = document.createElement("span");
		buttonSr.classList.add("sr-only");
		buttonSr.innerHTML = "Me localiser";

		button.append(buttonIcon, buttonSr);

		// les événements
		button.addEventListener("click", function () {
			// lancer la localisation et les intéractions
			ref.autoLocateUser();
		});

		this.wrapper_append.appendChild(button);
		this.autoLocateUi = button;
	}

	/**
	 * Définir la localisation de l'internaute
	 *
	 * ? > Note : à ne tester qu'en environnement https pour que cela fonctionne
	 */
	autoLocateUser() {
		let ref = this;

		//#region		UI : lancer l'intéraction
		// modifier le champ de recherche
		const field = ref.selector;
		ref.field_originalPlaceholder = field.getAttribute("placeholder");
		field.setAttribute("placeholder", "Recherche du lieu…");
		// Animation du bouton
		const autoLocate_button = ref.autoLocateUi;
		autoLocate_button.classList.add("pulse");
		//#endregion

		// demander coordonnées GPS
		let latitude = "a";
		let longitude = "b";
		navigator.geolocation.getCurrentPosition(
			function (position) {
				// Succès
				latitude = position.coords.latitude;
				longitude = position.coords.longitude;
				getLocalityByCoords(latitude, longitude);
			},
			function (err) {
				// échec
				console.error(
					"🗺  Géolocalisation : Imposible de localiser votre position",
					`ERREUR (${err.code}): ${err.message}`
				);
				switch (error.code) {
					case error.PERMISSION_DENIED:
						alert(
							"Votre localisation est désactivée, veuillez renseigner un lieu manuellemnt."
						);
						break;
					case error.POSITION_UNAVAILABLE:
						// Les informations de localisation ne sont pas disponibles
						alert(
							"Nous ne parvenons pas à vous localiser, veuillez saisir un lieu."
						);
						break;
					case error.TIMEOUT:
						// The request to get user location timed out
						alert(
							"Nous ne parvenons pas à vous localiser, veuillez saisir un lieu."
						);
						break;
					case error.UNKNOWN_ERROR:
						alert(
							"Une erreur inconnue est survenue, veuillez saisir un lieu."
						);
						break;
				}
			},
			// Options
			{
				enableHighAccuracy: true,
				timeout: 5000,
				maximumAge: 0,
			}
		);

		/**
		 *	Obtenir ville et code postale depuis des coordonnées
		 * @param {string} latitude
		 * @param {string} longitude
		 * @returns {string} Ville de l'emplacement
		 */
		function getLocalityByCoords(latitude, longitude) {
			let xhr = new XMLHttpRequest();
			let url = `https://api-adresse.data.gouv.fr/reverse/?lat=${latitude}&lon=${longitude}`;
			xhr.open("GET", url, true);
			xhr.send();
			xhr.onload = function () {
				if (xhr.status >= 200 && xhr.status < 300) {
					let response = JSON.parse(xhr.response);
					let infos = response.features[0].properties;
					let townName = infos.city;
					let zipCode = infos.postcode;
					ref.getItemFromAutoLocate(townName, zipCode);
				}
			};
		}
	}

	getItemFromAutoLocate(townName, zipCode) {
		let ref = this;
		if (townName == null || zipCode == null) {
			return;
		}
		let xhr = new XMLHttpRequest();
		if (!xhr) {
			console.error(
				"Abandon : Impossible de créer une instance de XMLHTTP"
			);
			return false;
		}
		xhr.onreadystatechange = xhrStateChange;

		// Construire et envoyer la requête
		let parameters = {
			action: "get_location_from_town_name",
			name: townName,
			zipcode: zipCode,
		};
		let path = php_vars.ajaxurl;
		xhr.open("GET", path + formatParams(parameters), true);
		xhr.send(parameters);

		// écouter l'évolution de la requête
		function xhrStateChange() {
			if (
				xhr.readyState === XMLHttpRequest.OPENED &&
				xhr.readyState === XMLHttpRequest.HEADERS_RECEIVED &&
				xhr.readyState === XMLHttpRequest.LOADING
			) {
				//#region		UI
				// réinitialiser le champ de recherche
				ref.selector.setAttribute("placeholder", "Recherche…");
				//#endregion
			}
			if (xhr.readyState === XMLHttpRequest.DONE) {
				//#region		UI
				// réinitialiser le champ de recherche
				ref.selector.setAttribute(
					"placeholder",
					ref.field_originalPlaceholder
				);
				// Animation du bouton
				ref.autoLocateUi.classList.remove("pulse");
				//#endregion
			}
		}

		xhr.onload = function () {
			if (xhr.status >= 200 && xhr.status < 300) {
				let response = JSON.parse(xhr.responseText);
				let data = response.data;

				let item = {
					type: "town",
					id: data.id,
					name: data.name + " (" + data.zipcode + ")",
					departmentId: data.department_id,
					departmentCode: data.department_code,
				};

				ref.addChoiceToResults(item);
				ref.hideLoader();
			} else {
				// console.warn('La requête vers ' + path + formatParams(parameters) +' a échoué' );
			}
		};
	}

	//#endregion

	//#region Utils

	/**
	 * Formatte un objet en query string
	 * @param { * } params paramètres à traiter
	 */
	formatParams(params) {
		return (
			"?" +
			Object.keys(params)
				.map(function (key) {
					return key + "=" + encodeURIComponent(params[key]);
				})
				.join("&")
		);
	}
	/**
	 * Récupère un élément du DOM via son sélecteur
	 */
	findElementBySelector(elementSelector = null, defaultSelector) {
		let element = null;

		//Sélecteur custom
		element = document.querySelector(elementSelector);
		if (element && element != null) {
			return element;
		}

		//Sélecteur par défaut
		element = document.querySelector(defaultSelector);
		if (element && element != null) {
			return element;
		}

		this.getAlerts("element-not-found", elementSelector);

		return element;
	}

	/**
	 * Gestionnaire d'alerte
	 * @param {*} alertType Type d'alerte
	 */
	getAlerts(alertType, elt) {
		let alertMessage = alertType;
		switch (alertType) {
			case "bad-initialization":
				alertMessage = "Le champ d'autocompletion n'a pas été trouvé";
				break;
			case "element-not-found":
				alertMessage = "L'élément n'a pas été trouvé (" + elt + ")";
				break;
		}
		console.error("[Autocomplete]", alertMessage);
	}

	//#endregion
}

/**
 * Active automatiquement l'autocompletion
 * pour les champs possédant l'attribut entities-autocomplete
 * (via js, non l'autocompletion html)
 * ```html
 *   <label for="leChamp">Ville de construction :</label>
 *   <input name="leChamp" id="leChamp" entities-autocomplete="" />
 * ```
 */
export function initAutocomplete() {
	let fields = document.querySelectorAll("[data-entities-autocomplete]");
	let options = {
		// allowTownSearch:true,
		// allowDepartmentSearch:true,
		// allowRegionSearch:true,
		// type: "multi",
		classes: {
			wrapper: "mfc-autocomplete-wrapper",
			field: "mfc-autocomplete-field",
			choices: "mfc-autocomplete-choices",
			results: "mfc-autocomplete-results",
			autolocate: "mfc-autocomplete-autolocate",
		},
	};

	fields.forEach((field) => {
		new Autocomplete(field, options);
	});
}

//#region Utils

/**
 * Formatte un objet en query string
 * @param {*} params paramètres à traiter
 */
function formatParams(params) {
	return (
		"?" +
		Object.keys(params)
			.map(function (key) {
				return key + "=" + encodeURIComponent(params[key]);
			})
			.join("&")
	);
}
/**
 * Déclenche une action au bout d'un délai
 * @param {*} callback La fonction a exéctuter
 * @param {*} delay
 */
function debounce(callback, delay) {
	var timer;
	return function () {
		var args = arguments;
		var context = this;
		clearTimeout(timer);
		timer = setTimeout(function () {
			callback.apply(context, args);
		}, delay);
	};
}
//#endregion

import Autocomplete from "./autocomplete/autocomplete";
import { getQueryString } from "./utils";
import { EntitiesNotification } from "./notifications/notification";
import { AnalyticsEvent } from "./tracking/trackAnalytics";

!(function () {
	document.addEventListener("DOMContentLoaded", function () {
		activateShowPasswordButtons()
		initAutocompleteProjectLocation()
		deleteAccountNotification()
		trackInscriptions()
	});
})();


!(function () {
	document.addEventListener("DOMContentLoaded", function () {
	let el_body = document.querySelector('body')

		// nous sommes sur une page account
		if ( el_body.classList.contains('account') )
		{
			// page SignIn + LogIn
			if ( el_body.classList.contains('account') )
			{
				account_login_disabledTabsOnDesktop()
			}
		}

	});
})();

function deleteAccountNotification()
{
	let action = getQueryString("action");

	if (!action || action != "delete_account")
	{
		return;
	}

	let message = {
		title: '<span class="highlight">Suppression</span> de&nbsp;votre&nbsp;compte',
		text: `<p class="mbm">Votre espace personnel a été supprimé.</p>`,
	};
	new EntitiesNotification(message);

	//Création du tracking
	new AnalyticsEvent({
		category: 'Formulaire',
		action: 'EspacePersonnel',
		label: 'SuppressionEspacePerso'
	});

}


/**
 * Active le bouton permettant d'afficher le mot de passe en clair
 */
function activateShowPasswordButtons() {
	let triggers = document.querySelectorAll("[data-show-password]");
	if (!triggers || triggers.length == 0) {
		return;
	}
	triggers.forEach((trigger) => {
		let passwordFieldId = trigger.getAttribute("data-show-password");
		// let passwordField = trigger.parentNode.parentNode.querySelector("#" + passwordFieldId);
		let passwordField = document.getElementById(passwordFieldId);
		// Toggle du password
		trigger.addEventListener("mousedown", function () {
			passwordField.setAttribute("type", "text");
		});
		trigger.addEventListener("mouseup", function () {
			passwordField.setAttribute("type", "password");
		});
	});
}

/**
 * Transforme le champ "Département de votre projet" en autocompletion
 */
function initAutocompleteProjectLocation() {
	let field = document.querySelector("#user_project_location");

	if (!field) {
		return;
	}

	let options = {
		allowDepartmentSearch: true,
		autolocate: false,
		classes: {
			wrapper: "mfc-autocomplete-wrapper",
			field: "mfc-autocomplete-field",
			choices: "mfc-autocomplete-choices",
			results: "mfc-autocomplete-results",
			autolocate: "mfc-autocomplete-autolocate",
		},
	};

	new Autocomplete(field, options);
}

/**
 * Ajoute le tracking au formulaire d'inscription
 */
function trackInscriptions()
{
	let statut = getQueryString("registration");

	if (!statut || statut != "ok")
	{
		return;
	}


	let message = {
		title: '<span class="highlight">Création</span> de&nbsp;votre&nbsp;compte',
		text: `<p class="mbm">Votre espace personnel a bien été créé.</p>`,
	};
	new EntitiesNotification(message);

	//Si la personne s'est inscrite pour visualiser les plans
	let actionCrm = getQueryString('actionCrm');
	let labelCrm = getQueryString('labelCrm') ?? 'undefined';

	if (actionCrm && actionCrm != null)
	{
		new AnalyticsEvent({
			category: 'Formulaire',
			action: actionCrm,
			label:labelCrm
		});
	}
	//Sinon
	else
	{
		//Création du tracking
		new AnalyticsEvent({
			category: 'Formulaire',
			action: 'EspacePersonnel',
			label: 'CreationEspacePerso'
		});
	}
}


//#region 			Actions sur les pages de Compte Utilisateur

	//#region 		Page Login & Register

		/**
		 * Désactiver les onglets sur ( Viewport > SMALL )
		 * TODO: OPI : voir avec mutation observer
		 */
		function account_login_disabledTabsOnDesktop() {
			/**
			 * Actualise au resize et autre
			 * @param {event} e
			 */
			function account_login_disabledTabsOnDesktop_refresh (e) {
				let tabs_pannels = document.querySelectorAll('.js-tabcontent[aria-hidden="true"]');
				if ( tabs_pannels.length > 0 )
				{
					// Modifier les pannels
					tabs_pannels.forEach( tabs_pannel => {
						if ( tabs_pannel.getAttribute('aria-hidden') == true )
						{
							if ( window.matchMedia("(min-width: 768px)").matches )
							{
								tabs_pannels.setAttribute('aria-hidden', false);
							}
							else
							{

							}
						}
					})
				}
			}

			// Rafraichir la position au scroll et resize
			window.addEventListener('resize', account_login_disabledTabsOnDesktop_refresh, false);
			account_login_disabledTabsOnDesktop_refresh()
		}


	//#endregion

//#endregion

import './css/form-step.scss';

export default class StepForm {
    constructor() {
        this.wrapper = document.querySelector('[data-form-step]');
		this.steps = Array();
		this.initialStep = "client";
		this.datas = [];

        //Récupération des étapes
        this.getFormSteps();

        //Mise en forme
        this.createFormStyle();

        //Navigation
        this.setNavigation();
    }

    /**
     * Met le formulaire en forme
     */
    createFormStyle() {
        let ref = this;
        this.wrapper.style.position = 'relative';

        this.steps.forEach((step) => {
            if (ref.isCurrentStep(step)) {
                ref.enableStep(step);
            }
        });
    }

    // #region Étapes

    /**
     * Récupère les étapes du formulaire
     */
    getFormSteps() {
        let ref = this;
        let steps = this.wrapper.querySelectorAll('[data-step]');

        if (steps && steps.length > 0) {
            steps.forEach((step) => {
                let stepObject = ref.setStepFromDom(step);

                step.style.transition = 'opacity ease-in-out .5s';
                step.style.position = 'absolute';

                this.steps.push(stepObject);
            });

            this.currentStep = this.steps.filter((s) => s.id_step == this.initialStep)[0];
        }
    }

    /**
     * Marque une étape comme active
     * @param {*} step
     */
    enableStep(step) {
        //Modification de la taille du wrapper en fonction de la div étape
        let domStep = step.step;
        let height = parseFloat(
            getComputedStyle(domStep, null).height.replace('px', '')
        );

        this.wrapper.style.height = height + 'px';

        step.step.style.opacity = 1;
        step.step.style.zIndex = 1;
        step.step.style.pointerEvents = 'all';
		// remonter en haut de l'étape à afficher
		window.scrollTo(0,0);

        //Active les événements de l'étape courante
        this.currentStep = step;
    }

    /**
     * Marque une étape comme inactive
     * @param {*} step
     */
    disableStep(step) {
        step.step.style.opacity = 0;
        step.step.style.zIndex = 0;
    }

    /**
     * Vérifie que l'objet étape passé en paramètre est l'étape courante
     * @param {object} step L'étape à vérifier
     */
    isCurrentStep(step) {
        return step.id_step == this.currentStep.id_step;
    }

    /**
     * Créée une objet étape à partir d'une div étape
     * @param {*} domStep
     */
    setStepFromDom(domStep) {
        return {
            step: domStep,
			id_step: domStep.getAttribute('data-step')
        };
    }

    //#endregion

    //#region Navigation

    /**
     * Permet de changer d'étape
     */
    setNavigation() {
        let ref = this;
        let triggers = document.querySelectorAll('[data-step-trigger]');
        if (triggers && triggers.length > 0) {
            triggers.forEach((trigger) => {

				ref.activateTrigger(trigger);

                trigger.addEventListener('mouseup', function () {
                    //Désactivation de l'étape courante
					ref.disableStep(ref.currentStep);

					//Enregistrement de la sélection
					ref.saveData(trigger);

                    //Activation de l'étape ciblée
					let id_step = trigger.getAttribute('data-step-trigger');

                    let targets = ref.steps.filter(
                        (s) => s.id_step == id_step
                    );
                    if (targets && targets.length > 0) {
                        targets.forEach((target) => {
							ref.enableStep(target);
							if (target.id_step == 'form')
							{
								ref.injectDatas();
							}
                            ref.currentStep = target;
                        });
                    }
                });
            });
        }
	}

	saveData(trigger)
	{
		let key = this.currentStep.step.getAttribute('data-step');
		let value;

		if (trigger.hasAttribute('data-step-depend'))
		{
			let target = this.currentStep.step.querySelector('[id="'+trigger.getAttribute('data-step-depend')+'"]');
			value = target.value;
		}
		else if (trigger.hasAttribute('data-step-value'))
		{
			value = trigger.getAttribute('data-step-value');
		}
		else if (trigger.value && trigger.value != "")
		{
			value = trigger.value;
		}
		else
		{
			value = trigger.innerHTML;
		}

		this.datas.push({
			key: key,
			value: value
		});
	}

	/**
	 * Active le bouton permettant de passer à l'étape suivante si cela nécessite un remplissage d'un autre champ au préalable
	 * @param {} trigger
	 */
	activateTrigger(trigger)
	{
		if (!trigger.hasAttribute('data-step-depend'))
		{
			return;
		}

		let target = this.wrapper.querySelector('[id="'+trigger.getAttribute('data-step-depend')+'"]');
		if (!target)
		{
			return;
		}

		if (target.value != "")
		{
			trigger.removeAttribute('disabled');
		}
		else
		{
			trigger.setAttribute('disabled', 'disabled');
		}

		target.addEventListener('keyup', function()
		{
			if (target.value != "")
			{
				trigger.removeAttribute('disabled');
			}
			else
			{
				trigger.setAttribute('disabled', 'disabled');
			}
		});

		return;
	}

    //#endregion

	//#region Formulaire

    injectDatas() {
        const ref = this;
        const datas = this.datas;
		let form = this.currentStep.step.querySelector('.wpcf7-form');

		datas.forEach(data => {
			ref.addHiddenField(form, data.key, data.value);
		});
    }

    //Ajout de champ caché
    addHiddenField(form, key, value) {
        let hiddenField = document.createElement('input');
        hiddenField.setAttribute('type', 'hidden');
        hiddenField.setAttribute('name', key);
        hiddenField.setAttribute('id', key);
        hiddenField.value = value;

		form.append(hiddenField);
    }

    //#endregion
}

!(function () {
    let page = document.querySelector('body');
    if (!page.classList.contains('form-step-page')) {
        return;
    }

    new StepForm();
})();

import './css/popup-style.scss';
import { formatParams } from '../utils';
import { EntitiesNotification } from '../notifications/notification';
import { initAutocomplete } from '../autocomplete/autocomplete';
import { AnalyticsEvent } from '../tracking/trackAnalytics';

export class EntitiesPopup {
    constructor(type = null, params = {}) {
        if (type == null) {
            console.error('You must define a type for the popup');
		}

        this.type = type;
        this.popup_title 		 = params.popup_title;
        this.popup_title_level 	 = params.popup_title_level;
        this.popup_class 		 = params.popup_class;
        this.closeButtonPosition = params.closeButtonPosition ?? 'inner';
        this.createPopup();
        this.getPopup();

        this.closeEvent();
    }

    createPopup() {

        // Création du wrapper / fond de page
        let wrapper = document.createElement('div');
        wrapper.classList.add('entities-popup');
        wrapper.classList.add('hidden');
        if (this.popup_class != null) {
            wrapper.classList.add(this.popup_class);
        }

		// Modifier le body pour éviter le scroll
        let body = document.querySelector('body');
        body.appendChild(wrapper);
        body.style.height = '100vh';
        body.style.overflow = 'hidden';

		// La pop-up : conteneur
		let popup = document.createElement('dialog');
		popup.classList.add('popup');
		popup.setAttribute('id', 'popup--content'); // Pour l'Aria du Dialog
		popup.setAttribute('open', '1')
		popup.setAttribute('role', 'dialog')
		popup.setAttribute('aria-labelledby', 'popup--title')

		// Ajout d'un Titre
		// via data-popup-title et data-popup-title-level
		if ( !!this.popup_title ){
			// Le contenu du titre + niveau du titre
			if ( !this.popup_title_level ){
				this.popup_title_level = 'p'
			}
			let popup_title_el = document.createElement( this.popup_title_level );
			popup_title_el.setAttribute('id', 'popup--title'); // Pour l'Aria du Dialog
			popup_title_el.classList.add('popup--title');
			popup_title_el.innerHTML = this.popup_title;
			popup.appendChild(popup_title_el);
		}

		// Ajout du bouton de fermeture
		let closeButton_inner_label = document.createElement('span');
		closeButton_inner_label.classList.add('sr-only');
		closeButton_inner_label.innerHTML='Fermer';
		let closeButton_inner_icon = document.createElement('span');
		closeButton_inner_icon.classList.add('dashicons');
		closeButton_inner_icon.classList.add('dashicons-no');
		// bouton
		let closeButton = document.createElement('button');
		closeButton.classList.add('close-button');
		closeButton.setAttribute('type', 'button');
		closeButton.setAttribute('title', 'Fermer cette fenêtre');
		closeButton.appendChild(closeButton_inner_label);
		closeButton.appendChild(closeButton_inner_icon);
		if (this.closeButtonPosition != 'inner') {
			wrapper.appendChild(closeButton);
		} else {
			popup.appendChild(closeButton);
		}
		// Ajout du bouton à l'objet
		this.closeButton = closeButton

		// Ajout du wrapper du contenu dynamique
		let popup_content_el = document.createElement('div')
		popup_content_el.classList.add('popup--content')
		let popup_content_el__html = `<div style="text-align:center;">
				<div class="loader--spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<br /><p style=text-align:center;>Chargement en cours…</p>
			</div>`;
		popup_content_el.innerHTML = popup_content_el__html
		popup.appendChild(popup_content_el)

		// On ajoute tout ça à la page :
		wrapper.appendChild(popup)
        this.wrapper_content = popup_content_el
        this.wrapper = wrapper
    }

	/**
	 * Charger le contenu dans la popup
	 */
    getPopup() {
        const ref = this;

        let xhr = new XMLHttpRequest();
        let parameters = {
            action: 'popupmaker',
            popup_type: ref.type,
        };
        let path = php_vars.ajaxurl;
        xhr.open('GET', path + formatParams(parameters), true);
        xhr.send(parameters);
        xhr.onload = function () {
            if (xhr.readyState == 4) {
                ref.loading = false;
                if (xhr.status >= 200 && xhr.status < 300) {
                    let response = JSON.parse(xhr.responseText);
                    let datas = response.data;

					// La pop-up : contenu
                    let popup_contenu = document.createElement('div');
                    popup_contenu.classList.add('popup--content');
                    popup_contenu.innerHTML = datas.popup_content;

                    if (ref.closeButtonPosition == 'inner') {
                        popup_contenu.appendChild(ref.closeButton);
                        ref.closeButton.classList.add('inner');
                    }

					// Ajouter au conteneur de la pop-up
					ref.wrapper_content.innerHTML = "";
                    ref.wrapper_content.appendChild(popup_contenu);


                    ref.popup = popup_contenu;
                    ref.closeEvent();

                    //On appelle le callback si il y en a
                    if (datas.callback) {
                        let callback_parameters = datas.callback.params;
                        callback_parameters['caller'] = ref;
                        eval(datas.callback.name + '(callback_parameters)');
                    }
                } else {
                    console.warn(
                        'La requête vers ' +
                            path +
                            formatParams(parameters) +
                            ' a échoué'
                    );
                }
            }
        };
    }

    /**
     * Événéments permettant de fermer la popup
     */
    closeEvent() {
        const ref = this;
		// Fermer au clic sur le background
        this.wrapper.addEventListener('click', function (e) {
            e.stopPropagation();
            ref.closePopup();
        });
		// Ajoute un bouton de fermeture
        this.closeButton.addEventListener('click', function () {
            ref.closePopup();
        });
        // Évite la fermeture de la popup quand on clique sur le corps de celle-ci
        if (this.popup) {
            this.popup.addEventListener('click', function (e) {
                e.stopPropagation();
            });
        }
    }

    /**
     * Détruit la popup
     */
    closePopup() {

		// retirer le conteneur de la popup
		if (!this.wrapper.parentNode)
		{
			return;
		}

        this.wrapper.parentNode.removeChild(this.wrapper);
		// Rétablir les style du body
        let body = document.querySelector('body');
        body.style.height = 'auto';
        body.style.overflow = 'auto';
    }
}

//TODO Déporter ce code pour l'alerte email dans un fichier séparé

/**
 * Événements exécutés après l'apparition de la popup alerte email
 * @param {*} params
 */
function alertEmailPopupEvents(params) {
    //Gestion de l'affichage en fonction du choix du type d'alerte
    let triggers = params.caller.wrapper.querySelectorAll(
        '[name="alert-type"]'
    );

    if (triggers) {
        triggers.forEach((trigger) => {
            if (trigger.checked) {
                alertEmailSetVisibility(trigger.value);
            }

            trigger.addEventListener('click', function () {
                alertEmailSetVisibility(trigger.value);
            });
        });
    }

    //Initialisation de l'autcompletion
    initAutocomplete();

    //Sauvegarde de l'alerte
    let buttonSave = document.querySelector('[data-save-alert]');

    //Désactivation tant que l'email n'est pas rempli
    buttonSave.setAttribute('disabled', true);
    let emailField = params.caller.wrapper.querySelector(
        '[name="alert-contact-email"]'
    );
    emailField.addEventListener('keyup', function () {
        if (this.value != '') {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(this.value)) {
				emailField.classList.remove('error');
                buttonSave.removeAttribute('disabled');
            }
			else
			{
				emailField.classList.add('error');
			}
        }
    });

    buttonSave.addEventListener('click', function () {
        let xhr = new XMLHttpRequest();
        let parameters = {
            action: 'create_mail_alert_without_search',
            criterias: JSON.stringify(getalertEmailCriterias(params.caller)),
            mail_address: emailField.value,
        };

        let path = php_vars.ajaxurl;

        xhr.open('GET', path + formatParams(parameters), true);
        xhr.send(parameters);
        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                let response = JSON.parse(xhr.responseText);
                let activate = response.data;

                let notificationTitle = 'Une erreur est survenue';

                if (activate) {
                    notificationTitle = "L'alerte email a bien été activée";

					new AnalyticsEvent({
						category: 'Formulaire',
						action: 'Remerciement',
						label: 'AlerteEmail'
					});
                }

                //Fermeture de la popup
                params.caller.closePopup();

                new EntitiesNotification({
                    title: notificationTitle,
                });
            } else {
                console.warn(
                    'La requête vers ' +
                        path +
                        formatParams(parameters) +
                        ' a échoué'
                );
            }
        };
    });
}

function getalertEmailCriterias(caller = document) {
    let criterias = [];

    // #region Type de l'offre
    let alert_offer_type = caller.wrapper.querySelector(
        '[name="alert-type"]:checked'
    ).value;
    if (alert_offer_type) {
        criterias.push({ name: 'offer-type', value: alert_offer_type });
    }
    //#endregion

    // #region Localisation
    let autocompleteField = caller.wrapper.querySelector(
        '[name="autocomplete-results"]'
    );
    if (autocompleteField && autocompleteField.value != '') {
        criterias.push({
            name: 'autocomplete-results',
            value: autocompleteField.value,
        });

        //Radius
        let radiusField = caller.wrapper.querySelector('[name="alert-radius"]');
        if (radiusField && radiusField.value != 0) {
            criterias.push({ name: 'radius', value: radiusField.value });
        }
    }

    //#endregion

    // #region Surface habitable
    let famnField = caller.wrapper.querySelector(
        '[name="alert-floor-area-min"]'
    );
    if (famnField && famnField.value != '') {
        criterias.push({ name: 'famn', value: famnField.value });
    }
    let famxField = caller.wrapper.querySelector(
        '[name="alert-floor-area-max"]'
    );
    if (famxField && famxField.value != '') {
        criterias.push({ name: 'famx', value: famxField.value });
    }
    //#endregion

    // #region Surface terrain
    let gamnField = caller.wrapper.querySelector(
        '[name="alert-ground-area-min"]'
    );
    if (gamnField && gamnField.value != '') {
        criterias.push({ name: 'gamn', value: gamnField.value });
    }
    let gamxField = caller.wrapper.querySelector(
        '[name="alert-ground-area-max"]'
    );
    if (gamxField && gamxField.value != '') {
        criterias.push({ name: 'gamx', value: gamxField.value });
    }
    //#endregion

    // #region Nombre de chambres

    let bdrnFields = caller.wrapper.querySelectorAll(
        '[name="alert-bedrooms-number"]'
    );
    let bdrnValues = Array();
    bdrnFields.forEach((bdrnField) => {
        if (bdrnField.checked) {
            bdrnValues.push(bdrnField.value);
            criterias.push({ name: 'bdr', value: bdrnField.value });
        }
    });
    // criterias.push({ name: 'bdr', value: bdrnValues });

    //#endregion

    // #region Nombre de pièces

    let rnFields = caller.wrapper.querySelectorAll(
        '[name="alert-rooms-number"]'
    );
    let rnValues = Array();
    rnFields.forEach((rnField) => {
        if (rnField.checked) {
            rnValues.push(rnField.value);
        }
    });
    criterias.push({ name: 'r', value: rnValues });

    //#endregion

    // #region Prix
    let pxmnField = caller.wrapper.querySelector('[name="alert-price-min"]');
    if (pxmnField && pxmnField.value != '') {
        criterias.push({ name: 'pxmn', value: pxmnField.value });
    }
    let pxmxField = caller.wrapper.querySelector('[name="alert-price-max"]');
    if (pxmxField && pxmxField.value != '') {
        criterias.push({ name: 'pxmx', value: pxmxField.value });
    }
    //#endregion

    return criterias;
}

function alertEmailSetVisibility(type) {
    let targets = document.querySelectorAll('[data-alert-criteria-type]');
    if (targets) {
        targets.forEach((target) => {
            if (target.getAttribute('data-alert-criteria-type') != type) {
                target.style.opacity = 0;
                target.style.height = 0;
            } else {
                target.style.opacity = 1;
                target.style.height = 'auto';
            }
        });
    }
}

import { isInViewport, debounce } from "./browser-helper";
import { getQueryString, formatParams } from "./utils";
import { doSearch } from "./services/wordpress";

!(function () {
	document.addEventListener("DOMContentLoaded", function () {
		let body = document.querySelector("body");
		if (!body.classList.contains("customer-reviews")) {
			return;
		}

		new CustomerReviewsLoader();
	});
})();

class CustomerReviewsLoader {
	constructor() {
		this.page = 1;
		this.wrapper = document.querySelector("[data-max-page]");

		if (!this.wrapper)
		{
			return;
		}

		this.maxPage = this.getMaxPage();
		this.initScrollEvent();
		this.lastItem = this.getLastReview();
	}

	/**
	 * Récupère le nombre maximum de page correspondant à la recherche
	 */
	getMaxPage() {
		return parseInt(this.wrapper.getAttribute("data-max-page"));
	}

	/**
	 * Déclenchement de l'action au scroll vers le bas
	 */
	initScrollEvent() {
		if (!this.hasMoreResults()) {
			return;
		}

		this.addLoadMoreButton();

		return;
	}

	addLoadMoreButton() {
		let ref = this;

		if (!ref.loadMoreButton) {

			let wrapper = document.createElement("div");
			wrapper.classList.add('load-more-button-wrapper');


			let button = document.createElement("button");
			button.classList.add('load-more-button');
			button.innerHTML = "Voir plus d'avis";

			button.addEventListener('click', function()
			{
				ref.removeLoadMoreButton();
				ref.loadNextReviews();
			});

			wrapper.appendChild(button);

			ref.loadMoreButton = wrapper;
		}

		ref.wrapper.appendChild(this.loadMoreButton);
	}

	removeLoadMoreButton()
	{
		if (!this.loadMoreButton)
		{
			return;
		}

		this.loadMoreButton.parentNode.removeChild(this.loadMoreButton);

	}

	/**
	 * Effectue une requête si la dernière review est à l'écran pour charger les suivantes
	 */
	loadNextReviews() {
		//On récupère le dernier avis client affiché et on vérifie qu'il est visible
		if (!this.lastItem) {
			return;
		}

		//on lance la nouvelle recherche
		if (this.hasMoreResults()) {
			//On met à jour la page actuelle
			this.page++;

			this.createLoader();

			//Exécution de la requête
		let command = {
			type: 'load_more_reviews',
			criterias: null,
			page: this.page,
			orderby: null,
		};

		doSearch(command)
			.finally(() => {
				this.removeLoader();
			})
			.then((response) => {
				let data = response.data.result;

				let reviews = data.reviews;
				reviews.forEach((review) => {
					this.addReviewToList(review.thumb);
				});

				//On met à jour le dernier élément
				this.lastItem = this.getLastReview();

				if (this.page < this.maxPage)
				{
					this.addLoadMoreButton();
				}
			})
			.catch(() =>
				console.warn("Impossible de récupérer le détail du marqueur")
			);


			// let ref = this;
			// let xhr = new XMLHttpRequest();
			// let parameters = {
			// 	action: "load_more_reviews",
			// 	page: ref.page,
			// };
			// let path = php_vars.ajaxurl;
			// xhr.open("GET", path + formatParams(parameters), true);
			// xhr.send(parameters);
			// xhr.onload = function () {
			// 	if (xhr.readyState == 4) {
			// 		ref.removeLoader();

			// 		if (xhr.status >= 200 && xhr.status < 300) {
			// 			let response = JSON.parse(xhr.responseText);
			// 			let data = response.data;

			// 			let reviews = data.reviews;
			// 			reviews.forEach((review) => {
			// 				ref.addReviewToList(review.thumb);
			// 			});

			// 			//On met à jour le dernier élément
			// 			ref.lastItem = ref.getLastReview();

			// 			//On relance l'événement si il reste des résultats
			// 			if (ref.hasMoreResults()) {
			// 				ref.initScrollEvent();
			// 			}
			// 		} else {
			// 			console.warn(
			// 				"La requête vers " +
			// 					path +
			// 					formatParams(parameters) +
			// 					" a échoué"
			// 			);
			// 		}
			// 	}
			// };
		}
	}

	/**
	 * Ajoute l'avis client à la liste
	 * @param {} reviewHtml la vignette en html
	 */
	addReviewToList(reviewHtml) {
		let thumb = document.createElement("div");
		thumb.innerHTML = reviewHtml;

		let review = thumb.querySelector("div");
		this.wrapper.appendChild(review);
	}

	/**
	 * Récupère le dernier avis client affiché
	 */
	getLastReview() {
		let reviews = document.querySelectorAll(".review-thumb");
		let lastReview = reviews[reviews.length - 1];

		return lastReview;
	}

	/**
	 * Vérifie qu'il reste des résultats à aller chercher
	 */
	hasMoreResults() {
		return this.maxPage - this.page > 0;
	}

	/**
	 * Créée un loader pendant la recherche
	 */
	createLoader() {
		let loaderWrapper = document.querySelector(".review-loader-wrapper");

		if (loaderWrapper) {
			return;
		}

		loaderWrapper = document.createElement("div");
		loaderWrapper.classList.add("review-loader-wrapper");

		let loader = loaderWrapper.querySelector(".review-loader");

		if (loader == null) {
			loader = document.createElement("div");
			loader.classList.add("review-loader");

			let spinner = document.createElement("div");
			spinner.classList.add("lds-spinner");

			for (var i = 0; i < 12; i++) {
				let spin = document.createElement("div");
				spinner.append(spin);
			}

			loader.appendChild(spinner);

			loaderWrapper.appendChild(loader);
			this.loader = loader;
			loader.classList.add("loading");

			this.wrapper.appendChild(loaderWrapper);
		}
	}

	/**
	 * Cache l'indicateur de chargement de résultats
	 */
	removeLoader() {
		if (this.loader) {
			this.loader.classList.remove("loading");
			this.loader = null;
		}

		let wrappers = this.wrapper.querySelectorAll(".review-loader-wrapper");
		if (wrappers && wrappers.length > 0) {
			wrappers.forEach((wrapper) => {
				wrapper.parentNode.removeChild(wrapper);
			});
		}
	}
}

export default class EntitiesCalendar {
	constructor() {
		this.startDaysAfter = 6; //On propose des rendez-vous à partir du surlendemain
		this.wrapper = document.querySelector("[data-calendar]");
		this.maxDays = 18; //Doit être un multiple du nombre de jour à afficher
		this.daysToShow = window.matchMedia("(min-width: 768px)").matches
			? 6
			: 1;
		this.unworkDays = [];
		this.rdvDays = [];
		this.holidayDates = [];
		this.setUnworkDays();
		this.setRDVDays();
		this.previousButton = this.wrapper.querySelector(".calendar-days-prev");
		this.nextButton = this.wrapper.querySelector(".calendar-days-next");
		this.dayWrappper = this.wrapper.querySelector(".calendar-day");
		this.timeWrapper = this.wrapper.querySelector(".calendar-times");
		this.startDate = this.getNextOpenDay(new Date(), this.startDaysAfter);
		this.setHolidayDates();
		this.initialized = this.wrapper.hasAttribute(
			"data-calendar-initialized"
		);
	}

	/**
	 * Initialise le calendrier
	 */
	initializeCalendar() {
		const ref = this;
		var index = 1;

		if (this.initialized) {
			return;
		}

		this.wrapper.setAttribute("data-calendar-initialized", true);

		//Sauvegarde la première date
		this.wrapper.setAttribute(
			"data-first-date",
			this.formatDate(this.startDate)
		);

		var date = new Date(this.startDate);
		while (index <= this.maxDays) {
			ref.createCalendarDay(date, index);
			date = this.getNextOpenDay(date, 1);
			index++;
		}

		//Gestion des liens précédent et suivant
		this.previousButton.addEventListener("click", function () {
			ref.nextButton.classList.remove("hide");
			ref.showPreviousCalendarItems();
		});

		this.nextButton.addEventListener("click", function () {
			ref.previousButton.classList.remove("hide");
			ref.showNextCalendarItems();
		});

		//Supprime les templates
		this.dayWrappper.remove();
		this.timeWrapper.remove();

		//Initialisation du moment sélectionné
		this.selectedValue = {
			date: "",
			time: "",
			both: "",
		};
	}

	/**
	 * Définit les jours chomés
	 */
	setUnworkDays() {
		this.unworkDays.push(0);
	}

	/**
	 * Définit les jours sur RDV
	 */
	setRDVDays() {
		this.rdvDays.push(6);
	}

	/**
	 * Définit les jours fériés
	 */
	setHolidayDates() {
		this.holidayDates.push(
			"01/01", //Jour de l'an
			"05/01", //Fête du travail
			"05/08", //Armistice 1945
			"07/14", //Fête nationale
			"08/15", //Assomption
			"11/01", //Toussaint
			"11/11", //Armistice 1918
			"12/25" //Noël
		);

		var date = new Date(this.startDate);
		this.addVariablesHolidayDates(date.getUTCFullYear());
	}

	/**
	 * Ajoute les jours fériés qui n'ont pas de date fixe
	 */
	addVariablesHolidayDates(year) {
		var date, a, b, c, m, d;

		//Instantiate the date object.
		date = new Date();

		//Set the timestamp to midnight.
		date.setHours(0, 0, 0, 0);

		//Set the year.
		date.setFullYear(year);

		//Find the golden number.
		a = year % 19;

		//Choose which version of the algorithm to use based on the given year.
		b = 2200 <= year && year <= 2299 ? (11 * a + 4) % 30 : (11 * a + 5) % 30;

		//Determine whether or not to compensate for the previous step.
		c = b === 0 || (b === 1 && a > 10) ? b + 1 : b;

		//Use c first to find the month: April or March.
        m = ( 1 <= c && c <= 19 ) ? 3 : 2;

		//Then use c to find the full moon after the northward equinox.
        d = ( 50 - c ) % 31;

		//Mark the date of that full moon—the "Paschal" full moon.
        date.setMonth( m, d );

		//Count forward the number of days until the following Sunday (Easter).
        date.setMonth( m, d + ( 7 - date.getDay() ) );

		//Dimanche de pâques
		if (new Date() < date)
		{
			this.holidayDates.push(this.getMonthFromDate(date) + '/' +  this.getDateFromDate(date));
		}

		//Lundi de pâques
		date.setDate(date.getDate() + 1);
		if (new Date() < date)
		{
			this.holidayDates.push(this.getMonthFromDate(date) + '/' +  this.getDateFromDate(date));
		}

		//Jeudi de l'ascension
		date.setDate(date.getDate() + 38);
		if (new Date() < date)
		{
			this.holidayDates.push(this.getMonthFromDate(date) + '/' +  this.getDateFromDate(date));
		}

		//Lundi de pentecôte
		date.setDate(date.getDate() + 11);
		if (new Date() < date)
		{
			this.holidayDates.push(this.getMonthFromDate(date) + '/' +  this.getDateFromDate(date));
		}
	}

	/**
	 * Créé le calendrier pour la date
	 * @param {any} date
	 */
	createCalendarDay(date, index) {
		const ref = this;
		let display = index <= this.daysToShow;
		let newHeaderElement = this.dayWrappper.cloneNode(true);
		let newBodyElement = this.timeWrapper.cloneNode(true);
		let formatedDate = this.formatDate(date);

		if (!display) {
			//Masque les éléments
			newHeaderElement.classList.add("hide");
			newBodyElement.classList.add("hide");
		}

		//Injecte le jour
		let dayNameElement =
			newHeaderElement.querySelector(".calendar-day-name");
		dayNameElement.innerHTML = this.getFrenchDay(date.getDay());
		let dayElement = newHeaderElement.querySelector(".calendar-day-date");
		dayElement.innerHTML =
			date.getDate() + " " + this.getFrenchMonth(date.getMonth());
		newHeaderElement.setAttribute("data-date", formatedDate);
		newBodyElement.setAttribute("data-date", formatedDate);

		if (this.isRDVDays(date.getDay())) {
			let agency = document.querySelector("#light-agency-url");

			if (agency) {
				let agencyUrl = agency.getAttribute("rel");
				if (agencyUrl && agencyUrl != "") {
					newBodyElement.innerHTML =
						'<div><a href="' +
						agencyUrl +
						'" target="_blank">Appeler l\'agence</a></div>';
				}
			} else {
				newBodyElement.innerHTML = "<div>Sur Rendez-Vous</div>";
			}
			newBodyElement.classList.add("on-rdv");
		}

		if (this.isHolidayDate(date)) {
			newBodyElement.innerHTML = "Férié";
			newBodyElement.classList.add("holiday");
		}

		//Gestion du clic sur le calendrier
		let momentElements = newBodyElement.querySelectorAll(".calendar-time");
		momentElements.forEach((momentElement) => {
			momentElement.addEventListener("click", function () {
				ref.selectCalendarMoment(momentElement);
			});
		});

		//Ajoute les nouveaux éléments
		this.dayWrappper.before(newHeaderElement);
		this.timeWrapper.before(newBodyElement);
	}

	/**
	 * Récupère le jour précédent
	 * @param {} date
	 */
	getPreviousOpenDay(date) {
		const ref = this;
		do {
			date.setDate(date.getDate() - 1);
		} while (ref.unworkDays.some((x) => x == date.getDay()));

		return date;
	}

	/**
	 * Récupère le jour suivant
	 * @param {*} date
	 * @param {*} increment
	 */
	getNextOpenDay(date, increment) {
		const ref = this;
		do {
			date.setDate(date.getDate() + increment);
			increment = 1;
		} while (ref.unworkDays.some((x) => x == date.getDay()));

		return date;
	}

	/**
	 * Affiche la vue précédente
	 */
	showPreviousCalendarItems() {
		const ref = this;

		//Récupère le premier élément affiché
		let firstElement = this.wrapper.querySelectorAll(
			".calendar-day:not(.hide)"
		)[0];

		let date = firstElement.getAttribute("data-date");
		date = ref.getPreviousOpenDay(new Date(date));

		//Masque les éléments affichés
		ref.hideVisibleCalendarItems();

		//Affiche les x éléments précédents
		let index = 1;
		let formatedDate;
		while (index <= ref.daysToShow) {
			formatedDate = ref.formatDate(date);
			let headerElement = this.wrapper.querySelector(
				'.calendar-day[data-date="' + formatedDate + '"]'
			);
			let bodyElement = this.wrapper.querySelector(
				'.calendar-times[data-date="' + formatedDate + '"]'
			);
			headerElement.classList.remove("hide");
			bodyElement.classList.remove("hide");

			date = ref.getPreviousOpenDay(date);
			index++;
		}

		//Masque le lien si c'est la première page
		var firstCalendarElement =
			ref.wrapper.querySelectorAll(".calendar-day")[0];
		date = firstCalendarElement.getAttribute("data-date");

		if (date == formatedDate) {
			this.previousButton.classList.add("hide");
		}
	}

	/**
	 * Affiche la vue suivante
	 */
	showNextCalendarItems() {
		const ref = this;

		//Récupère le dernier élément affiché
		var lastElement = ref.getLastChild(".calendar-day:not(.hide)");
		var date = lastElement.getAttribute("data-date");
		date = ref.getNextOpenDay(new Date(date), 1);

		//Masque les éléments affichés
		ref.hideVisibleCalendarItems();

		//Affiche les éléments suivants
		var index = 1;
		var formatedDate;
		while (index <= ref.daysToShow) {
			formatedDate = ref.formatDate(date);
			let headerElement = this.wrapper.querySelector(
				'.calendar-day[data-date="' + formatedDate + '"]'
			);
			var bodyElement = this.wrapper.querySelector(
				'.calendar-times[data-date="' + formatedDate + '"]'
			);
			if (headerElement) {
				headerElement.classList.remove("hide");
			}
			if (bodyElement) {
				bodyElement.classList.remove("hide");
			}

			date = ref.getNextOpenDay(date, 1);
			index++;
		}

		//Masque le lien si c'est la dernière page
		var lastCalendarElement = ref.getLastChild(".calendar-day");
		date = lastCalendarElement.getAttribute("data-date");

		if (date == formatedDate) {
			this.nextButton.classList.add("hide");
		}
	}

	/**
	 * Enregistre la date sélectionnée
	 */
	selectCalendarMoment(momentElement) {
		var ref = this;
		var selectedFormatedDate =
			momentElement.parentNode.getAttribute("data-date");
		var selectedTime = momentElement.innerHTML;

		if (momentElement.classList.contains("active")) {
			return;
		}

		//Si un autre élément était déjà sélectionné, on le déselectionne
		let selectedMoments = this.wrapper.querySelectorAll(
			".calendar-time.active"
		);
		selectedMoments.forEach((selectedMoment) => {
			ref.unselectCalendarMoment(selectedMoment);
		});

		//Enregistre la valeur
		this.selectedValue = {
			date: selectedFormatedDate,
			time: selectedTime,
			both: selectedFormatedDate + " - " + selectedTime,
		};

		//Marque cet élément comme sélectionné
		momentElement.classList.add("active");
	}

	/**
	 * Déselectionne une date dans le calendrier
	 * @param {any} momentElement
	 */
	unselectCalendarMoment(momentElement) {
		momentElement.classList.remove("active");

		this.selectedValue = {
			date: "",
			time: "",
			both: "",
		};
	}

	/**
	 * Masque les jours actuellement affichés dans le calendrier
	 */
	hideVisibleCalendarItems() {
		var elements = this.wrapper.querySelectorAll(
			".calendar-day:not(.hide), .calendar-times:not(.hide)"
		);
		elements.forEach(function (element) {
			element.classList.add("hide");
		});
	}

	/**
	 * Retourne le dernier élément correspondand au sélecteur
	 * @param {any} selector
	 */
	getLastChild(selector) {
		var elements = this.wrapper.querySelectorAll(selector);
		if (elements) {
			return elements[elements.length - 1];
		}
		return null;
	}

	/**
	 * Vérifie que le jour de la semaine est un jour sur rendez-vous
	 * @param {*} index
	 */
	isRDVDays(index) {
		return this.rdvDays.some((i) => i == index);
	}

	//#region Utils Date

	/**
	 * Formate une date
	 * @param {any} date
	 */
	formatDate(date) {
		let day = this.getDateFromDate(date);
		var month = this.getMonthFromDate(date);
		return date.getUTCFullYear() + "/" + month + "/" + day;
	}

	getDateFromDate(date) {
		let day = date.getDate();
		if (day < 10) {
			day = "0" + day;
		}

		return day;
	}

	getMonthFromDate(date) {
		var month = date.getMonth() + 1;
		if (month < 10) {
			month = "0" + month;
		}

		return month;
	}

	/**
	 * Vérifie que la date n'est pas un jour férié
	 * @param {*} date
	 */
	isHolidayDate(date) {
		let day = this.getDateFromDate(date);
		let month = this.getMonthFromDate(date);

		let formattedDate = month + "/" + day;

		return this.holidayDates.includes(formattedDate);
	}

	/**
	 * Retourne le jour de la semaine en français
	 * @param {any} day
	 */
	getFrenchDay(day) {
		switch (day) {
			case 0:
				return "Dimanche";
			case 1:
				return "Lundi";
			case 2:
				return "Mardi";
			case 3:
				return "Mercredi";
			case 4:
				return "Jeudi";
			case 5:
				return "Vendredi";
			case 6:
				return "Samedi";
			default:
				return "Not set";
		}
	}

	/**
	 * Retourne le mois en français
	 * @param {any} month
	 */
	getFrenchMonth(month) {
		switch (month) {
			case 0:
				return "janvier";
			case 1:
				return "février";
			case 2:
				return "mars";
			case 3:
				return "avril";
			case 4:
				return "mai";
			case 5:
				return "juin";
			case 6:
				return "juillet";
			case 7:
				return "août";
			case 8:
				return "septembre";
			case 9:
				return "octobre";
			case 10:
				return "novembre";
			case 11:
				return "décembre";
			default:
				return "Not set";
		}
	}

	/**
	 * Renvoie la valeur sélectionnée
	 */
	value(type = "both") {
		if (!this.selectedValue) {
			return;
		}

		if (type == "date") {
			return this.selectedValue.date;
		}

		if (type == "time") {
			return this.selectedValue.time;
		}

		return this.selectedValue.both;
	}

	//#endregion
}

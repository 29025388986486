import { formatParams, debounce } from "./utils";
import { EntitiesNotification } from "./notifications/notification";
import {
  AnalyticsEvent,
  DoubleClickEvent,
  AdwordsEvent,
} from "./tracking/trackAnalytics";

!(function () {
  formsEvents();
  catalogChecker();
})();

export default function formsEvents() {
  let cf7Forms = document.querySelectorAll(".wpcf7");
  let intervalId;
  let timeElapsed = 0;
  let currentForm;

  if (!cf7Forms || cf7Forms.length == 0) {
    return;
  }

  cf7Forms.forEach((cf7Form) => {
    currentForm = cf7Form;

    //Désactivation du bouton si les champs département ou ville de construction ne sont pas remplis
    forceProjectLocation(currentForm);

    //Ajout de l'attribut data-disabled au bouton submit
    currentForm.addEventListener(
      "submit",
      function (e) {
        let submit_btn = cf7Form.querySelector('[type="submit"]');
        if (submit_btn) {
          submit_btn.setAttribute("data-disabled", true);
          submit_btn.setAttribute("disabled", true);
        }

        //Création d'un bouton fake pour éviter l'erreur js
        let button = document.createElement("input");
        button.classList.add("wpcf7-submit");
        button.setAttribute("type", "submit");
        button.setAttribute("data-disabled", true);
        button.setAttribute("disabled", true);
        button.style.zIndex = -1;
        button.style.pointerEvents = "none";
        button.style.display = "none";

        cf7Form.appendChild(button);

        startTimeout();
      },
      false
    );

    //Événements une fois que le formulaire est validé
    currentForm.addEventListener(
      "wpcf7mailsent",
      function (event) {
        //Affichage du message de confirmation selon le formulaire
        stopTimeout();
        getConfirmationMessage(event.detail.contactFormId);
        getTracking(event.detail.contactFormId, event.detail.inputs);
      },
      false
    );

    //Événements si le formulaire est en erreur
    currentForm.addEventListener(
      "wpcf7spam ",
      function (e) {
        stopTimeout();
      },
      false
    );

    //Événements si le formulaire est invalide
    window.addEventListener("wpcf7invalid", function () {
      stopTimeout();
    });

    //Événements si l'envoi est considéré comme spam
    window.addEventListener("wpcf7spam", function () {
      stopTimeout();
      new EntitiesNotification({
        title: "Une erreur est survenue",
        text: "Votre demande n'a pas pu être envoyée. Veuillez réessayer s'il vous plaît",
      });
    });

    //Événements si le formulaire est en erreur
    window.addEventListener("wpcf7mailfailed", function () {
      stopTimeout();
      new EntitiesNotification({
        title: "Une erreur est survenue",
        text: "Votre demande n'a pas pu être envoyée. Veuillez réessayer s'il vous plaît",
      });
    });
  });

  /**
   * Début de l'interval
   */
  function startTimeout() {
    intervalId = setInterval(increaseTimeout, 1000);
  }

  /**
   * Suppression de l'interval
   * et réinitialisation du formulaire
   */
  function stopTimeout() {
    clearInterval(intervalId);
    resetForm(currentForm);
    timeElapsed = 0;
  }

  /**
   * On teste toutes les secondes
   * Si au bout de 30 secondes l'interval n'est pas clear
   * on renvoie un message d'erreur
   */
  function increaseTimeout() {
    timeElapsed += 1;

    if (timeElapsed >= 30) {
      stopTimeout();
      new EntitiesNotification({
        title: "Une erreur est survenue",
        text: "Votre demande n'a pas pu être envoyée. Veuillez réessayer s'il vous plaît",
      });
    }
  }

  /**
   * Réinitialisation du formulaire
   * @param {*} currentForm
   */
  function resetForm(currentForm) {
    let form = currentForm.querySelector("form");

    if (typeof wpcf7.resetForm === "function") {
      wpcf7.resetForm(form);
    } else {
      wpcf7.reset(form);
    }

    if (typeof wpcf7.refill === "function") {
      wpcf7.refill(form);
    }

    let submit_btn = currentForm.querySelector('[type="submit"]');
    if (submit_btn) {
      submit_btn.setAttribute("data-disabled", false);
      submit_btn.removeAttribute("disabled");
    }
  }

  function forceProjectLocation(cf7Form) {
    let submitButton = cf7Form.querySelector(".wpcf7-submit");

    if (!submitButton) {
      return;
    }

    let departmentField = cf7Form.querySelector(
      '[name="contact-project-location-select"]'
    );

    if (departmentField) {
      if (departmentField.value == "") {
        submitButton.setAttribute("disabled", "disabled");
      } else {
        submitButton.removeAttribute("disabled");
      }

      departmentField.addEventListener("change", function () {
        forceProjectLocation(cf7Form);
      });
    }

    let townField = cf7Form.querySelector(
      '[name="contact-project-location-town"], [name="land-town-location"]'
    );

    if (townField) {
      let results = cf7Form.querySelector('[name="autocomplete-results"]');

      if (!results || results.value == "" || townField.value == "") {
        submitButton.setAttribute("disabled", "disabled");
      } else {
        submitButton.removeAttribute("disabled");
      }

      townField.addEventListener(
        "keydown",
        debounce(function () {
          forceProjectLocation(cf7Form);
        }, 1000)
      );

      document.addEventListener("autocompleteSelected", function () {
        forceProjectLocation(cf7Form);
      });
    }
  }
}

function catalogChecker() {
  let fields = document.querySelectorAll(".catalog-checker-item");

  if (!fields) {
    return;
  }

  fields.forEach((field) => {
    field.addEventListener("click", function (event) {
      event.preventDefault();

      let input = field.querySelector(".catalog-checker-item-input");

      let others = document.querySelectorAll(
        '[name="' + input.getAttribute("name") + '"]'
      );

      others.forEach((other) => {
        if (other.value != input.value) {
          other.checked = false;
        } else {
          input.checked = true;
        }
      });
    });
  });
}

/**
 * Récupère le message de confirmation associé au formulaire
 * @param {*} form_id
 */
function getConfirmationMessage(form_id) {
  let xhr = new XMLHttpRequest();
  let parameters = {
    action: "get_confirmation_message",
    form_id: form_id,
  };

  let path = php_vars.ajaxurl;

  xhr.open("GET", path + formatParams(parameters), true);
  xhr.send(parameters);
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      let response = JSON.parse(xhr.responseText);
      let data = response.data;

      let confirmationMessage = {
        title: data.title,
        text: data.message,
        ctas: data.ctas,
      };

      new EntitiesNotification(confirmationMessage);
    }
  };
}

/**
 * Récupère le code de tracking associé au formulaire
 * @param {*} form_id
 */
function getTracking(form_id, inputs) {
  let xhr = new XMLHttpRequest();
  let parameters = {
    action: "get_tracking_code",
    form_id: form_id,
    inputs: JSON.stringify(inputs),
  };

  let path = php_vars.ajaxurl;

  xhr.open("GET", path + formatParams(parameters), true);
  xhr.send(parameters);
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      let response = JSON.parse(xhr.responseText);
      let data = response.data;

      if (data.analytics) {
        new AnalyticsEvent(data.analytics);
      }

      if (data.doubleClick) {
        new DoubleClickEvent(data.doubleClick);
      }

      if (data.adwords) {
        new AdwordsEvent(data.adwords);
      }
    }
  };
}

document.addEventListener("DOMContentLoaded", function () {

  if (typeof recaptcha_vars == 'undefined')
  {
    return;
  }

  const forms = ["#account-inscription-form"];
  const recpatcha_site_key = recaptcha_vars.site_key;

  if (!recpatcha_site_key) {
    return;
  }

  forms.forEach((formSelector) => {
    let form = document.querySelector(formSelector);

    form.addEventListener("submit", function (event) {
      event.preventDefault();
      grecaptcha.execute(recpatcha_site_key, { action: "mon_compte" }).then(
        function (token) {
          document.getElementById("g-recaptcha-response").value = token;
          event.target.submit();
        }
      );
    });
  });
});

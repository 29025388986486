import { downloadPdf } from "../services/wordpress";
import { EntitiesNotification } from "../notifications/notification";
import { AnalyticsEvent } from '../tracking/trackAnalytics';
import { getQueryString } from '../utils';

class DownloadPdf {
	constructor() {
		let buttons = document.querySelectorAll("[data-download]");

		if (!buttons || buttons.length == 0) {
			return;
		}

		this.buttons = buttons;
		this.bindEvent();
		this.userLoggedIn = php_vars.is_logged_in;
		this.afterLogin();
	}

	bindEvent() {
		let ref = this;

		this.buttons.forEach((button) => {
			button.addEventListener("click", function () {
				if (ref.userLoggedIn) {
					ref.download(button);
				} else {
					ref.forceLoginPopup(button);
				}
			});
		});
	}

	/**
	 * Après s'être identifié, on télcharge automatiquement le PDF
	 */
	afterLogin()
	{
		let button = this.buttons[0];

		if (getQueryString("labelCrm") && getQueryString("labelCrm") == "TelechargementPDF")
		{
			button.click();
		}
		else if (getQueryString("actionCrm") && getQueryString("actionCrm") == "TelechargementPDF")
		{
			button.click();
		}
	}

	//Appel à l'API pour fonction fpdf
	download(button) {

		let ref = this;
		let offer_name = button.getAttribute("data-offer-name");

		let command = {
			postId: button.getAttribute("data-download"),
		};

		ref.createLoader();

		new AnalyticsEvent({
			category: 'Clic',
			action: 'TelechargementPDF',
			label: offer_name
		});

		downloadPdf(command)
			.then((response) => {
				let data = response.data;

				const link = document.createElement("a");
				link.href = data.fileurl;
				link.setAttribute("download", data.filename);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);

				ref.removeLoader();
			})
			.catch((e) => console.warn("Une erreur est survenue", e));
	}

	/**
	 * Si l'utilisateur n'est pas identifé, on affiche une popup pour qu'il s'inscrive ou se connecte
	 */
	forceLoginPopup(button) {
		let offer_name = button.getAttribute("data-offer-name");
		let returnUrl = button.getAttribute("data-return-url");

		new AnalyticsEvent({
			category: 'Clic',
			action: 'TelechargementPDFNonInscrit',
			label: offer_name
		});

		let message = {
			title: `<span class="icon-g-check"></span> Télécharger l'annonce`,
			text: `<p class="mbm">Vous devez vous connecter pour télécharger cette annonce.</p>`,
			ctas: [
				{
					attributes: {
						class: "btn",
					},
					anchor: '<span class="icon-g-profil"></span> <span style="font-size: 13px;">Créer un compte</span>',
					url:
						php_vars.connexion_url +
						"?return_url=" +
						returnUrl +
						"_**_actionCrm=EspacePersonnel_**_labelCrm=TelechargementPDF#account-tab__inscription",
				},
				{
					attributes: {
						class: "btn",
					},
					anchor: '<span class="icon-g-compte"></span> Se connecter',
					url:
						php_vars.connexion_url +
						"?return_url=" +
						returnUrl +
						"_**_actionCrm=TelechargementPDF_**_labelCrm=" +
						offer_name +
						"#account-tab__connexion",
				},
			],
		};
		new EntitiesNotification(message);
	}

	/**
	 * Création du loader
	 */
	createLoader() {
		if (!this.loader) {
			let loaderWrapper = document.createElement("div");
			loaderWrapper.classList.add("entities-notification--wrapper");
			loaderWrapper.style.display = "none";

			let loader = document.createElement("div");
			loader.classList.add("entities-notification");

			let loaderTitle = document.createElement("div");
			loaderTitle.classList.add("entities-notification--title");
			loaderTitle.innerHTML = "Votre téléchargement va démarrer.";

			let loaderTxt = document.createElement("div");
			loaderTxt.classList.add("entities-notification--text");
			loaderTxt.innerHTML = "Merci de patienter quelques instants";

			loader.appendChild(loaderTitle);
			loader.appendChild(loaderTxt);
			loaderWrapper.appendChild(loader);

			let body = document.querySelector("body");
			body.appendChild(loaderWrapper);

			this.loader = loaderWrapper;
		}

		this.loader.style.display = "flex";
	}

	removeLoader() {
		if (this.loader) {
			this.loader.style.display = "none";
		}
	}
}

!(function () {
	new DownloadPdf();
	// let buttons = document.querySelectorAll("[data-download]");

	// if (!buttons || buttons.length == 0) {
	// 	return;
	// }

	// buttons.forEach((button) => {
	// 	button.addEventListener("click", function () {
	// 		//Appel à l'API pour fonction fpdf
	// 		let command = {
	// 			postId: button.getAttribute("data-download"),
	// 		};

	// 		this.createLoader();

	// 		downloadPdf(command)
	// 			.then((response) => {
	// 				let data = response.data;

	// 				const link = document.createElement("a");
	// 				link.href = data.fileurl;
	// 				link.setAttribute("download", data.filename);
	// 				document.body.appendChild(link);
	// 				link.click();
	// 				document.body.removeChild(link);

	// 				this.removeLoader();
	// 			})
	// 			.catch((e) => console.warn("Une erreur est survenue", e));
	// 	});
	// });
})();

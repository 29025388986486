// =====================================
// APP JS  (main)
// =====================================

import modernizr from './modernizr';
import { leaflet } from 'leaflet'; // Core
// Plugin des Clusters de Markers
import {
    MarkerClusterGroup,
    MarkerCluster,
} from 'leaflet.markercluster/dist/leaflet.markercluster';

//#region 	Maps (conserver avant les components…)
//  Avec Leaflet
//	import { leaflet } from './vendors/maps/leaflet/leaflet/leaflet';	// Core
//	import { MarkerClusterGroup, MarkerCluster } from './vendors/maps/leaflet/Leaflet.markercluster-master/dist/leaflet.markercluster.js';	// Plugin des Clusters de Markers
//#endregion

import '../css/style.scss';
import './compatibily';
import './utils';
import './big-picture';
import './maps';
import './offers';
import './browser-helper';
// Partager la page
import './share';
// Partage depuis shareApi
import './autocomplete/autocomplete';
import './searches';
import './account';
import './favorites';
import './customer-reviews';
import './forms';
import './rendez-vous/rendez-vous';
import './form-step/form-step';
import './popups';
import './detail';
import './tracking/tracking';
import './tracking';
import './contents';
import './components/_warning-popup';
import './download-pdf/download-pdf'

//#region 	Components
// Cards
import { cards_rectoVerso } from './components/_cards';
cards_rectoVerso();
// Chips
import { chips } from './components/_chips';
chips();
// onglets
import van11y from '../../node_modules/van11y-accessible-tab-panel-aria';
// Accordeon
import van11y_accordeon from '../../node_modules/van11y-accessible-accordion-aria';
// Sliders
import './sliders';
//#endregion

//#region	 Layout
import './navigation3d.js';

import './scroll-event';
//#endregion

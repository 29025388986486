/**
 * 	Actions sur les cartes
 */
export function chips () {

	// Retourner les chips avec bouton dez fermeture
	let chips = document.querySelectorAll('.js-chip-close');
	if ( chips.length > 0 ) {
		chips.forEach( (chip) => {
			chip.addEventListener( 'click', function( event ) {
				event = event || window.event;

				// Supprime un élément spécifié sans avoir à spécifier son noeud parent
				var node = this.parentNode;
				if (node.parentNode) {
					node.parentNode.removeChild(node);
				}
				event.preventDefault()
			});
		})
	}
}


import { getQueryString, formatParams, debounce } from "../utils";
import { EntitiesNotification } from "../notifications/notification";
import { EntitiesPopup } from "../popups/popup";
import { doSearch } from "../services/wordpress";

export default class EntitiesSearch {
	constructor(params = {}) {
		this.type = params.type;
		this.page = 1;
		this.maxPages = -1;
		this.loading = false;
		this.resultArea = params.resultArea
			? params.resultArea
			: this.findElementBySelector(".js-filtered-list-items");
		this.resultAreaContainer = this.findElementBySelector(
			".filtered-list--items-container"
		);
		this.searchEngine = params.searchEngine
			? params.searchEngine
			: document.querySelector(".filtered-list--filters-motor");
		this.fields = [];
		this.criterias = [];
		this.countArea = params.countArea
			? params.countArea
			: "[data-count-number]";
		this.saveSearchButtons = params.saveSearchButtonSelector
			? params.saveSearchButtonSelector
			: "[data-save-search]";
		this.orderByElement = this.findElementBySelector(
			params.orderBySelector
				? params.orderBySelector
				: "#filtered-list--sort"
		);

		this.idUserSearch = this.getUserSearchId();
		this.isLiveSearch = php_vars.is_live_search;
		this.searchUrlResult = window.location;
		this.autocompleteLoaded = false;

		if (!this.type) {
			console.error(
				'Search type is undefined. Please use "type" in params'
			);
		}

		this.addOrderByEventListener();
		this.loadMaxPages();
		// this.search();
		this.getAutocomplete();
		this.initThumbsSearchUrl();
		this.saveSearchEvents();
		this.initResetEvent();
		if (!this.isLiveSearch) {
			this.redirectSearch();
		}
	}

	/**
	 * Retourne le type de recherche
	 */
	getType() {
		return this.type;
	}
	/**
	 * Ajoute un écouteur d'évènement sur l'élément de tri
	 */
	addOrderByEventListener() {
		const me = this;
		if (this.orderByElement) {
			this.orderByElement.addEventListener("change", () => me.search());
		}
	}

	/**
	 * Récupère le nombre de pages à partir du DOM
	 */
	loadMaxPages() {
		let list = document.querySelectorAll("[data-max-pages]");
		if (list.length > 0) {
			this.maxPages = parseInt(list[0].getAttribute("data-max-pages"));
		}
	}

	/**
	 * Effectue une recherche en fonction des critères définis
	 * @param {*} newSearch
	 */
	internalDoSearch(newSearch) {
		//Dans le cas d'une nouvelle recherche, on repart à partir de la première page
		if (newSearch) {
			this.page = 1;
		} else {
			this.page += 1;
		}

		this.loading = true;

		//Affiche un loader le temps du chargement
		if (!newSearch) {
			this.addLoader();
		}

		if (newSearch && !this.isLiveSearch) {
			this.addPopupLoader();
		}

		//Exécution de la requête
		let command = {
			type: this.type,
			criterias: this.getCriterias(),
			page: this.page,
			orderby: this.getOrderBy(),
		};

		doSearch(command)
			.finally(() => {
				this.loading = false;
				this.removeLoader();
				this.removePopupLoader();
			})
			.then((response) => {
				let data = response.data.result;

				//Mise à jour du compteur d'annonces
				if (this.countArea && newSearch) {
					this.setCountNumber(data.count);
				}

				if (data.maxPages) {
					this.maxPages = parseInt(data.maxPages);
				}

				if (data.criterias) {
					this.searchCriterias = data.criterias;
					document.dispatchEvent(
						new CustomEvent("search", {
							detail: {
								criterias: data.criterias,
								newSearch: newSearch,
							},
						})
					);
				}

				if (this.resultArea) {
					this.searchUrlResult = data.search_url;
					this.render(newSearch, data.search);
				}
			})
			.catch(() =>
				console.warn("Impossible de récupérer le détail du marqueur")
			);
	}

	/**
	 * Lance la recherche
	 */
	search() {
		this.internalDoSearch(true);
	}

	/**
	 * Charge les éléments suivants
	 */
	loadMore() {
		if (this.page + 1 <= this.maxPages && !this.loading) {
			this.internalDoSearch(false);
		}
	}

	/**
	 * Affiche le nombre de résultats dans les éléments du DOM définis
	 * @param {*} number le nombre de résultats à afficher
	 */
	setCountNumber(number) {
		if (!this.countArea) {
			return;
		}

		let countArea = document.querySelectorAll(this.countArea);

		if (countArea instanceof NodeList) {
			countArea.forEach((area) => {
				area.innerHTML = number;
			});
		} else {
			countArea.innerHTML = number;
		}
	}

	//#region Loaders

	/**
	 * Ajoute un loader indiquant que le chargement est en cours
	 */
	addLoader() {
		if (!this.pulseLoader) {
			let list = this.resultAreaContainer.querySelectorAll(
				".filtered-list--loader"
			);
			if (list.length == 0) {
				let loader = document.createElement("div");
				loader.classList.add("filtered-list--loader");

				for (let l = 1; l < 4; l++) {
					let loaderDot = document.createElement("span");
					loaderDot.classList.add("loader-dot-" + l);
					loader.appendChild(loaderDot);
				}

				this.loaderPulse = loader;

				this.resultAreaContainer.append(loader);
			}
		}

		this.loaderPulse.classList.add("loader-pulse");
	}

	/**
	 * Retire le loader
	 */
	removeLoader() {
		if (this.loaderPulse) {
			this.loaderPulse.classList.remove("loader-pulse");
		}
	}

	/**
	 * Ajoute un loader popup
	 */
	addPopupLoader() {
		if (!this.popupLoader) {
			let loaderWrapper = document.createElement("div");
			loaderWrapper.classList.add("map-loader-wrapper");

			let loader = document.createElement("div");
			loader.classList.add("map-loader");
			loader.innerHTML = "Chargement en cours";
			loaderWrapper.append(loader);

			this.resultArea.append(loaderWrapper);
			this.popupLoader = loaderWrapper;
		}

		this.popupLoader.style.backgroundColor = "rgba(0,0,0,0.4)";
		this.popupLoader.classList.add("loading");
	}

	/**
	 * Supprime le loader popup
	 */
	removePopupLoader() {
		if (this.popupLoader) {
			this.popupLoader.classList.remove("loading");
			this.popupLoader.style.backgroundColor = "transparent";
		}
	}

	//#endregion

	//#region Vignettes

	render(newSearch, results) {
		if (newSearch) {
			this.removeThumbs();
		}
		this.addThumbs(results);
	}

	/**
	 * Vide la zone de résultat
	 */
	removeThumbs() {
		let thumbs = this.resultArea.querySelectorAll(
			".filtered-list--item.item"
		);
		thumbs.forEach((thumb) => {
			thumb.parentNode.removeChild(thumb);
		});
	}

	/**
	 * Enlève la classe hide des vignettes qui correspondent aux résultats
	 * @param {*} results les id's des posts à afficher
	 */
	addThumbs(results) {
		const ref = this;
		let thumbs = Array();

		results.forEach((thumb) => {
			let wrapperThumb = document.createElement("div");
			wrapperThumb.classList.add("filtered-list--item");
			wrapperThumb.classList.add("item");
			wrapperThumb.setAttribute("role", "listitem");
			wrapperThumb.innerHTML = thumb.thumb;

			ref.addSearchLinkToThumb(wrapperThumb);

			thumbs.push(wrapperThumb);
		});

		//On vérifie qu'il y a une manchette
		let manchette = this.resultAreaContainer.querySelector(
			".card-manchette-parent"
		);
		let new_manchette;
		if (manchette) {
			manchette.parentNode.removeChild(manchette);
			new_manchette = manchette.cloneNode(true);

			if (new_manchette.hasAttribute("data-popup")) {
				new_manchette.addEventListener("click", function () {
					new EntitiesPopup(trigger.getAttribute("data-popup"), {
						class: "test",
					});
				});
			} else if (new_manchette.querySelector("[data-popup]")) {
				let trigger = new_manchette.querySelector("[data-popup]");

				trigger.addEventListener("click", function () {
					new EntitiesPopup(trigger.getAttribute("data-popup"), {
						class: "test",
					});
				});
			}
		}

		document.dispatchEvent(
		new CustomEvent('afterSearchRenderArea', {
			detail: {
				'thumbs': thumbs
			}
		}));

		thumbs.forEach((thumb) => {
			this.resultArea.append(thumb);
		});

		if (new_manchette) {
			this.resultArea.append(new_manchette);
		}
	}

	initThumbsSearchUrl() {
		this.searchUrl = entities_search_vars.search_url ?? null;
		this.searchCriterias = entities_search_vars.search_criterias ?? null;
		this.addSearchLinkToThumb(this.resultArea);
	}

	addSearchLinkToThumb(thumbWrapper) {
		const ref = this;
		let links = thumbWrapper.querySelectorAll("[data-thumb-link]");

		if (links && links.length > 0) {
			links.forEach((link) => {
				let href = link.href;
				const criterias = encodeURIComponent(
					JSON.stringify(ref.searchCriterias)
				);

				let qs = getQueryString(href);

				let location_url = qs
					? href + "&criterias = " + criterias
					: href + "?criterias= " + criterias;

				link.addEventListener("click", function (e) {
					window.location.href = location_url;
					e.preventDefault();
				});
			});
		}
	}
	//#endregion

	/**
	 * Récupère la localisation de la requête et pré-remplit le champ d'autocompletion
	 */
	getAutocomplete() {
		let ref = this;

		//Récupération du champs autocomplete
		let autocompleteField = document.getElementById("autocomplete-results");

		var checkExist = setInterval(function () {
			autocompleteField = document.getElementById("autocomplete-results");
			if (autocompleteField) {
				clearInterval(checkExist);

				//Initialisation : on n'a pas le critère d'autocompletion
				let item = {
					name: autocompleteField.getAttribute("name"),
					value: autocompleteField.value,
				};

				ref.addCriteria(autocompleteField, item);
				ref.autocompleteLoaded = true;

				var config = { attributes: true, attributeOldValue: true };
				var callback = function (mutationsList) {
					for (var mutation of mutationsList) {
						if (mutation.type == "attributes") {
							item = {
								name: autocompleteField.getAttribute("name"),
								value: mutation.oldValue,
							};

							try {
								let parsedLocations = JSON.parse(
									autocompleteField.value
								);
								let locations = Object.entries(parsedLocations);
								let locationTypes = Array();

								locations.forEach((location) => {
									for (const [key, value] of Object.entries(
										location
									)) {
										if (value.type) {
											locationTypes.push(value.type);
										}
									}
								});

								ref.setSortDropDownOptions(locationTypes);
							} catch {
								console.log("une erreur est survenue");
							}

							if (ref.isCriteria(item)) {
								const index = ref.criterias.findIndex(
									(c) => c.name == autocompleteField.name
								);
								ref.criterias.splice(index, 1);

								if (autocompleteField.value != "") {
									item.value = autocompleteField.value;
									ref.criterias.push(item);
								}
							} else {
								item.value = autocompleteField.value;
								ref.addCriteria(autocompleteField, item);
							}

							if (ref.isLiveSearch) {
								ref.search();
							}
						}
					}
				};
				var observer = new MutationObserver(callback);
				observer.observe(autocompleteField, config);
			}
		}, 100);
	}

	//#region Recherche Utilisateur

	/**
	 * Récupère l'id de la recherche utilisateur
	 */
	getUserSearchId() {
		return getQueryString("update_search");
	}

	/**
	 * Enregistre la recherche courante de l'internaute
	 */
	saveSearch() {
		let ref = this;
		let xhr = new XMLHttpRequest();

		var checkAutoCompleteLoaded = setInterval(function () {
			let autocompleteLoaded = ref.autocompleteLoaded;
			if (autocompleteLoaded) {
				clearInterval(checkAutoCompleteLoaded);

				let parameters = {
					action: "save_search",
					type: ref.type,
					criterias: JSON.stringify(ref.getCriterias()),
				};

				let path = php_vars.ajaxurl;

				xhr.open("GET", path + formatParams(parameters), true);
				xhr.send(parameters);
				xhr.onload = function () {
					if (xhr.status >= 200 && xhr.status < 300) {
						//Mise à jour de l'id de la recherche
						let response = JSON.parse(xhr.responseText);
						let message;

						let returnUrl = ref.searchUrlResult
							.toString()
							.replaceAll("&", "_**_");
						let qsSeparator =
							php_vars.connexion_url.indexOf("?") > -1
								? "_**_"
								: "?";

						if (response.data.error) {
							message = {
								title: '<span class="icon-g-check"></span> Vous n\'êtes pas connecté</span>',
								text: `<p class="mbm">Créez votre espace&nbsp;personnel.</p>
								<p class="alert alert-block"><strong>C'est pratique :</strong> activez l'alerte par courriel sur cette recherche et restez informé…</p>`,
								ctas: [
									{
										attributes: {
											class: "btn",
										},
										anchor: '<span class="icon-g-profil"></span> <span style="font-size: 13px;">Créer mon compte</span>',
										url:
											php_vars.connexion_url +
											qsSeparator +
											"return_url=" +
											returnUrl +
											"_**_actionCrm=EspacePersonnel_**_labelCrm=EnregistrerRecherche#account-tab__inscription",
									},
									{
										attributes: {
											class: "btn",
										},
										anchor: '<span class="icon-g-compte"></span> Se connecter',
										url:
											php_vars.connexion_url +
											qsSeparator +
											"return_url=" +
											returnUrl +
											"_**_actionCrm=EspacePersonnel_**_labelCrm=EnregistrerRecherche#account-tab__connexion",
									},
								],
							};
						} else if (response.data.search_id) {
							ref.idUserSearch = response.data.search_id;

							//Ajout du message de confirmation
							message = {
								title: '<span class="icon-g-check"></span> Recherche <span class="highlight">enregistrée</span>',
								text: `<p class="mbm">Retrouvez vos recherches dans votre&nbsp;espace&nbsp;personnel.</p>
									<p class="alert alert-block"><strong>C'est pratique :</strong> activez l'alerte par courriel sur cette recherche et restez informé…</p>`,
								ctas: [
									{
										attributes: {
											class: "btn btn--primary",
											"data-action": "close",
										},
										anchor: '<span class="icon-g-fleche-alt-left"></span> les résultats',
										url: "#",
									},
									{
										attributes: {
											class: "btn",
										},
										anchor: '<span class="icon-g-profil"></span> Mes recherches',
										url: response.data.search_page,
									},
								],
							};
						}
						new EntitiesNotification(message);
					} else {
						console.warn(
							"La requête vers " +
								path +
								formatParams(parameters) +
								" a échoué"
						);
					}
				};
			}
		}, 100);
	}

	/**
	 * Modification d'une recherche enregistrée
	 * @param {*} searchId L'id de la recherche à modifier
	 */
	updateSearch(searchId) {
		let ref = this;
		let xhr = new XMLHttpRequest();
		let parameters = {
			action: "update_search",
			search_user_id: searchId,
			criterias: JSON.stringify(ref.getCriterias()),
		};

		let path = php_vars.ajaxurl;

		xhr.open("GET", path + formatParams(parameters), true);
		xhr.send(parameters);
		xhr.onload = function () {
			if (xhr.status >= 200 && xhr.status < 300) {
				//Mise à jour de l'id de la recherche
				let response = JSON.parse(xhr.responseText);

				//Ajout du message de confirmation
				let message = {
					title: `<span class="icon-g-check"></span> Recherche modifiée`,
					text: `<p class="mbm">Votre recherche a été mise à jour.</p>
							<p class="alert alert-block"><strong>C'est pratique :</strong> activez l'alerte par courriel sur cette recherche et restez informé…</p>`,
					ctas: [
						{
							attributes: {
								class: "btn btn--primary",
								"data-action": "close",
							},
							anchor: '<span class="icon-g-fleche-alt-left"></span> les résultats',
							url: "#",
						},
						{
							attributes: {
								class: "btn",
							},
							anchor: '<span class="icon-g-profil"></span> Mes recherches',
							url: response.data.search_page,
						},
					],
				};
				new EntitiesNotification(message);
			} else {
				console.warn(
					"La requête vers " +
						path +
						formatParams(parameters) +
						" a échoué"
				);
			}
		};
	}

	//#endregion

	//#region Critères

	/**
	 * Récupère les critères de la recherche
	 */
	getCriterias() {
		switch (this.type) {
			case "land_house":
				return this.getLandHouseCriterias();
			case "model":
				return this.getModelCriterias();
			case "agency":
				return this.getAgencyCriterias();
			default:
				throw new Error("Type NotImplemented");
		}
	}

	/**
	 * Récupère les critères d'une recherche de terrains ou terrains + maisons
	 */
	getLandHouseCriterias() {
		let offerTypes = document.querySelectorAll(
			"[name='offer-type'][checked]"
		);
		let bedrooms = document.getElementsByName("bdr");
		let rooms = document.getElementsByName("r");
		let floorAreaMin = document.getElementsByName("famn");
		let floorAreaMax = document.getElementsByName("famx");
		let groundAreaMin = document.getElementsByName("gamn");
		let groundAreaMax = document.getElementsByName("gamx");
		let priceMin = document.getElementsByName("pxmn");
		let priceMax = document.getElementsByName("pxmx");
		let virtualVisits = document.getElementsByName("vv");
		let agencyId = document.getElementsByName("agency_id");

		if (offerTypes) {
			this.addField(offerTypes);
		}
		if (bedrooms) {
			this.addField(bedrooms);
		}
		if (rooms) {
			this.addField(rooms);
		}
		if (floorAreaMin) {
			this.addField(floorAreaMin);
		}
		if (floorAreaMax) {
			this.addField(floorAreaMax);
		}
		if (groundAreaMin) {
			this.addField(groundAreaMin);
		}
		if (groundAreaMax) {
			this.addField(groundAreaMax);
		}
		if (priceMin) {
			this.addField(priceMin);
		}
		if (priceMax) {
			this.addField(priceMax);
		}
		if (virtualVisits) {
			this.addField(virtualVisits);
		}

		if (agencyId) {
			this.addField(agencyId);
		}

		return this.criterias;
	}

	/**
	 * Récupère les critères d'une recherche de modèle
	 */
	getModelCriterias() {
		let bedrooms = document.getElementsByName("bdr");
		let rooms = document.getElementsByName("r");
		let floorAreaMin = document.getElementsByName("famn");
		let floorAreaMax = document.getElementsByName("famx");
		let priceMin = document.getElementsByName("pxmn");
		let priceMax = document.getElementsByName("pxmx");
		let virtualVisits = document.getElementsByName("vv");
		let type = document.getElementsByName("model_type");
		let sector =
			typeof entities_sector_vars !== "undefined"
				? entities_sector_vars
				: null;

		let catalog = document.getElementsByName("model_catalog");
		if (catalog) {
			this.addField(catalog);
		}

		if (sector) {
			this.criterias.push({
				name: "model_sector",
				value: sector,
			});
		}

		if (bedrooms) {
			this.addField(bedrooms);
		}
		if (rooms) {
			this.addField(rooms);
		}
		if (floorAreaMin) {
			this.addField(floorAreaMin);
		}
		if (floorAreaMax) {
			this.addField(floorAreaMax);
		}
		if (priceMin) {
			this.addField(priceMin);
		}
		if (priceMax) {
			this.addField(priceMax);
		}
		if (virtualVisits) {
			this.addField(virtualVisits);
		}
		if (type) {
			this.addField(type);
		}

		return this.criterias;
	}

	/**
	 * Récupère les critères d'une recherche d'agence
	 */
	getAgencyCriterias() {
		return this.criterias;
	}

	/**
	 * Retourne le tri sélectionné
	 */
	getOrderBy() {
		if (this.orderByElement) {
			return this.orderByElement.value;
		}
		return null;
	}

	setSortDropDownOptions(locationType) {
		let optGroup = this.orderByElement.querySelector("optgroup");

		if (Array.isArray(locationType)) {
			if (!locationType.includes("town")) {
				locationType = false;
			}
		}

		if (locationType == "town") {
			let DistanceValue = document.createElement("option");
			DistanceValue.value = "dist";
			DistanceValue.innerHTML = "Les plus près";
			DistanceValue.setAttribute("selected", "selected");

			optGroup.append(DistanceValue);
		} else {
			let options = optGroup.querySelectorAll("option");

			options.forEach((option) => {
				if (option.value == "dist") {
					option.remove();
				}
			});
		}
	}

	/**
	 * Récupère un élement du DOM en tant que champ
	 * Enregistre ce champ en tant que critères
	 * Branche l'événement de détection de modification
	 * @param {string} selector le sélecteur de l'élément
	 */
	addField(selector) {
		let ref = this;

		if (selector && selector.length > 0) {
			selector.forEach((field) => {
				let item = {
					name: field.getAttribute("name"),
					value: field.value,
				};

				if (item.value != "stable")
				{
					ref.addCriteria(field, item);
					ref.addTrigger(field, item);
				}

			});
		}
	}

	/**
	 * Définit un critère de la recherche
	 * @param { Node } field le champ de formulaire définissant le critère de recherche
	 */
	addCriteria(field, item) {
		if (
			field &&
			field.getAttribute("type") == "checkbox" &&
			!field.checked
		) {
			if (this.isCriteriaWithSameValue(item))
			{
				this.removeCriteria(item);
			}
			return;
		}

		if (item.value != "") {
			if (this.isCriteria(item)) {
				this.updateCriteria(item, item.value);
			} else {
				this.criterias.push(item);
			}
		} else {
			this.removeCriteria(item);
		}
	}

	/**
	 * Mets à jour un critère de recherche
	 * @param {*} item Le critère de recherche à modifier
	 * @param {*} value La nouvelle valeur du critère
	 */
	updateCriteria(item, value) {
		this.removeCriteria(item);
		if (value !== "") {
			item.value = value;
			this.criterias.push(item);
		}
	}

	/**
	 * Supprime un critère de recherche
	 * @param {*} item Le critère de recherche à supprimer
	 */
	removeCriteria(item) {
		this.criterias = this.criterias.filter((c) => c.name != item.name);
	}

	resetCriteria() {
		this.criterias = [];
	}

	/**
	 * Vérifie qu'un critère de recherche est présent dans la recherche
	 * @param {*} item Le critère de recherche à rechercher
	 */
	isCriteria(item) {
		return this.criterias.some((c) => c.name == item.name);
	}

	isCriteriaWithSameValue(item)
	{
		return this.criterias.some((c) => c.name == item.name && c.value == item.value);
	}

	//#endregion

	//#region Événements

	/**
	 * Détecte les changements sur les champs et met à jour les critères de recherche
	 * @param {*} field Le champs qui a été modifié
	 * @param {*} item Le critère de recherche à modifier
	 */
	addTrigger(field, item) {
		let ref = this;

		field.addEventListener(
			"change",
			debounce(function () {
				if (ref.isCriteria(item)) {
					if (
						field.getAttribute("type") == "checkbox" &&
						!field.checked
					) {
						ref.removeCriteria(item);
					} else {
						ref.updateCriteria(item, field.value);
					}
				} else if (field.value !== "") {
					item.value = field.value;
					ref.addCriteria(field, item);
				}

				if (ref.isLiveSearch) {
					ref.search();
				}
			}, 300)
		);

		field.addEventListener(
			"keydown",
			debounce(function () {
				if (ref.isCriteria(item)) {
					if (
						field.getAttribute("type") == "checkbox" &&
						!field.checked
					) {
						ref.removeCriteria(item);
					} else {
						ref.updateCriteria(item, field.value);
					}
				} else if (field.value !== "") {
					item.value = field.value;
					ref.addCriteria(field, item);
				}
				if (ref.isLiveSearch) {
					ref.search();
				}
			}, 300)
		);
	}

	/**
	 * Branche l'événement de sauvegarde au bouton de sauvegarde de recherche
	 */
	saveSearchEvents() {
		let saveSearchButtons = document.querySelectorAll(
			this.saveSearchButtons
		);

		if (!saveSearchButtons || saveSearchButtons.length == 0) {
			return;
		}

		let ref = this;

		saveSearchButtons.forEach((button) => {
			button.addEventListener("click", function () {
				if (ref.idUserSearch) {
					ref.updateSearch(ref.idUserSearch);
				} else {
					ref.saveSearch();
				}
			});
		});

		if (getQueryString("registration")) {
			ref.getAutocomplete();
			ref.search();
			if (ref.idUserSearch) {
				ref.updateSearch(ref.idUserSearch);
			} else {
				ref.saveSearch();
			}
		}
	}

	/**
	 * Réinitialise la recherche au reset du formulaire
	 */
	initResetEvent() {
		let resetButton = document.querySelector('[type="reset"]');
		let ref = this;

		if (!resetButton) {
			return;
		}

		resetButton.addEventListener("click", function (e) {
			e.preventDefault();
			ref.resetSearch();
		});
	}

	/**
	 * Réinitialise la recherche
	 */
	resetSearch() {
		this.resetCriteria();

		//Réinitialisation du moteur de recherche
		this.searchEngine.reset();

		let autocompleteField = document.getElementById("autocomplete-results");
		let multiAutocompleteField = document.querySelector(
			".mfc-autocomplete-results"
		);
		if (autocompleteField) {
			autocompleteField.value = "";
		}

		if (multiAutocompleteField) {
			multiAutocompleteField.innerHTML = "";
		}

		//Exécution de la recherche
		this.search();
	}

	/**
	 * Si la recherche n'est pas en live, on redirige vers l'url de recherche au clic sur le bouton
	 */
	redirectSearch() {
		if (this.isLiveSearch) {
			return;
		}

		let ref = this;

		//Modification de la soumission du formulaire
		this.searchEngine.addEventListener("submit", function (e) {
			e.preventDefault();
			ref.search();
			ref.show_results_on_mobile();
			return false;
		});
	}

	show_results_on_mobile() {
		// Small + Medium
		if (Modernizr.mq("(max-width: 767px)")) {
			// bouton à cibler
			let filters_btn = document.querySelector(
				" #filters-visibles--toggle-btn "
			);

			var evt = document.createEvent("MouseEvents");
			evt.initMouseEvent(
				"click",
				true,
				true,
				window,
				0,
				0,
				0,
				0,
				0,
				false,
				false,
				false,
				false,
				0,
				null
			);
			var canceled = !filters_btn.dispatchEvent(evt);
			if (canceled) {
				// A handler called preventDefault
				console.error("canceled");
			} else {
				// Remonter en haut de la liste top…
				window.scrollTo(0, 0);
			}
		}
	}

	//#endregion

	/**
	 * Récupère un élément du DOM via son sélecteur
	 * @param {string} elementSelector le sélecteur de l'élément à récupérer
	 * @param {string} defaultSelector le sélecteur à récupérer si l'élément n'existe pas
	 */
	findElementBySelector(elementSelector = null, defaultSelector) {
		let element = null;

		//Sélecteur custom
		element = document.querySelector(elementSelector);
		if (element && element != null) {
			return element;
		}

		//Sélecteur par défaut
		element = document.querySelector(defaultSelector);
		if (element && element != null) {
			return element;
		}

		console.warn("element-not-found", elementSelector);

		return element;
	}
}

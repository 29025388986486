import getBrowserFingerprint from '../get-browser-fingerprint/src/index';
import { trackSearch } from '../services/wordpress';


export class TrackSearch {
    constructor(search) {
        this.search = search;

        this.addSearchEventListener();
    }

    /**
     * Ajoute un écouteur d'évènement sur la recherche
     */
    addSearchEventListener() {
        const me = this;
        document.addEventListener('search', (e) => {
            var data = e.detail;
            if (data.newSearch) {
                me.analyseCriterias(data.criterias);
            }
        });
    }

    /**
     * Analyse les critères de recherche
     * @param {} criterias
     */
    analyseCriterias(criterias) {
        if (Array.isArray(criterias)) {
            //Récupère le critère de localisation
            const locationCriteria = criterias.find(
                (x) => x.Name == 'location'
            );

            if (locationCriteria) {
                this.saveSearch(locationCriteria);
            }
        }
    }

    saveSearch(locationCriteria) {
        const fingerprint = getBrowserFingerprint();
        const searchType = this.search.getType();

        const command = {
            location: locationCriteria.Value,
            fingerprint,
            searchType,
        };

        trackSearch(command);
    }
}

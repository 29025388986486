import { getQueryString, formatParams } from "./utils";
import EntitiesSearch from "./search/search";
import { EntitiesNotification } from "./notifications/notification";
import { InfiniteScroll } from "./infinite-scroll";
import { TrackSearch } from "./tracking/trackSearch";
import { AnalyticsEvent } from './tracking/trackAnalytics'

!(function () {
	initLandHousesSearch();
	initModelsSearch();
	initAgenciesSearch();
	initActualitiesSearch();
	initRegionSearch();
	initDepartmentSearch();
	initDeleteUserSearchesButtons();
	activateMailAlerts();
})();

/**
 * Initialise la recherche des offres
 */
function initLandHousesSearch() {
	if (document.querySelector(".post-type-archive-entities_land_houses")) {
		let search = new EntitiesSearch({
			type: "land_house",
			countArea: ".js-items-counter",
		});
		new InfiniteScroll(search);
		new TrackSearch(search);
	}
}

/**
 * Initialise la recherche des modèles
 */
function initModelsSearch() {
	if (document.querySelector(".post-type-archive-entities_house_model")) {
		let search = new EntitiesSearch({
			type: "model",
			countArea: ".js-items-counter",
		});
		new InfiniteScroll(search);
	}
}

/**
 * Initialise la recherche des agences
 */
function initAgenciesSearch() {
	if (document.querySelector(".post-type-archive-entities_agencies")) {
		let search = new EntitiesSearch({
			type: "agency",
			countArea: ".js-items-counter",
		});
		new InfiniteScroll(search);
	}
}

/**
 * Initialise la recherche des agences
 */
function initActualitiesSearch() {
	if (document.querySelector(".post-type-archive-entities_actuality")) {
		let search = new EntitiesSearch({
			type: "actuality",
		});
		new InfiniteScroll(search);

		if (entities_search_vars) {
			let thumbs = document.querySelectorAll("[data-thumb-link]");
			thumbs.forEach((thumb) => {
				let href = thumb.href;
				const criterias = encodeURIComponent(
					JSON.stringify(entities_search_vars.search_criterias)
				);

				let qs = getQueryString(href);

				let location_url = qs
					? href + "&criterias = " + criterias
					: href + "?criterias= " + criterias;

				thumb.addEventListener("click", function (e) {
					window.location.href = location_url;
					e.preventDefault();
				});
			});
		}
	}
}

/** Carte de la recherche agences et modèles */
function initRegionSearch() {
	let map = document.querySelector(".map-svg-interactive svg");

	if (!map) {
		return false;
	}

	//On masque la map en attendant le chargement de l'autocompletion
	map.style.maxHeight = "1px";
	map.style.transition = "max-height ease-in-out .5s";

	// Les éléments cliquables de la carte
	let triggers = document.querySelectorAll(".map-svg-interactive-zone");
	if (!triggers || triggers.length == 0) {
		return false;
	}

	let autocomplete;

	var checkExist = setInterval(function () {
		autocomplete = document.querySelector("#autocomplete-trigger-field");
		if (autocomplete) {
			clearInterval(checkExist);

			//On affiche la map
			map.style.maxHeight = "100vh";

			triggers.forEach((trigger) => {
				if (autocomplete) {
					trigger.addEventListener("click", function (event) {
						event.preventDefault();

						let codeRegion = trigger.getAttribute("id");
						autocomplete.value = codeRegion;

						return false;
					});
				}
			});
		}
	}, 100);

	return false;
}

function initDepartmentSearch() {
	let buttons = document.querySelectorAll(".departments-list-button");

	if (!buttons) {
		return false;
	}

	let autocomplete;

	var checkExist = setInterval(function () {
		autocomplete = document.querySelector("#autocomplete-trigger-field");
		if (autocomplete) {
			clearInterval(checkExist);
			buttons.forEach((button) => {
				if (autocomplete) {
					button.addEventListener("click", function (event) {
						event.preventDefault();

						autocomplete.setAttribute(
							"location-type",
							"department"
						);
						let idDepartment =
							button.getAttribute("data-id-department");
						autocomplete.value = idDepartment;

						return false;
					});
				}
			});
		}
	}, 100);

	return false;
}

/**
 * Branche l'événement sur les boutons de suppression de recherche
 */
function initDeleteUserSearchesButtons() {
	let buttons = document.querySelectorAll("[data-delete-user_search]");

	if (!buttons || buttons.length == 0) {
		return;
	}

	buttons.forEach((button) => {
		button.addEventListener("click", function () {
			let searchId = this.getAttribute("data-delete-user_search");
			let xhr = new XMLHttpRequest();
			let parameters = {
				action: "delete_search",
				search_user_id: searchId,
			};

			let path = php_vars.ajaxurl;

			xhr.open("GET", path + formatParams(parameters), true);
			xhr.send(parameters);
			xhr.onload = function () {
				if (xhr.status >= 200 && xhr.status < 300) {
					//Suppressiond de la vignette de la recherche
					let thumbs = document.querySelectorAll(
						'[data-user-search="' + searchId + '"]'
					);

					if (thumbs) {
						thumbs.forEach((thumb) => {
							let parent = thumb.parentNode;

							if (parent) {
								parent.classList.add("hide");
							}
						});
					}
					new EntitiesNotification({
						title: '<span class="icon-g-poubelle"></span> Recherche supprimée',
						text: `La recherche a été supprimée de vos recherches sauvegardées,<br /> ainsi que ses notifications par email.`,
					});
				} else {
					console.warn(
						"La requête vers " +
							path +
							formatParams(parameters) +
							" a échoué"
					);
				}
			};
		});
	});
}

/**
 * Branche l'événement sur les boutons d'activation d'alerte mail sur les recherches
 */
function activateMailAlerts() {
	let buttons = document.querySelectorAll("[data-activate-mail-alert]");

	if (!buttons || buttons.length == 0) {
		return;
	}

	buttons.forEach((button) => {
		button.addEventListener("click", function () {
			let searchId = this.getAttribute("data-activate-mail-alert");
			let xhr = new XMLHttpRequest();
			let parameters = {
				action: "activate_mail_alert",
				search_user_id: searchId,
			};

			let path = php_vars.ajaxurl;

			xhr.open("GET", path + formatParams(parameters), true);
			xhr.send(parameters);
			xhr.onload = function () {
				if (xhr.status >= 200 && xhr.status < 300) {
					let response = JSON.parse(xhr.responseText);
					let activate = response.data;

					let notificationTitle = "Une erreur est survenue";

					if (activate) {
						button.classList.add("on");
						notificationTitle = "L'alerte email a bien été activée";

						new AnalyticsEvent({
							category: 'Formulaire',
							action: 'Remerciement',
							label: 'AlerteEmail'
						});
					} else {
						button.classList.remove("on");
						notificationTitle =
							"L'alerte email a bien été désactivée";

							new AnalyticsEvent({
								category: 'Formulaire',
								action: 'Remerciement',
								label: 'AlerteEmailDelete'
							});
					}
					new EntitiesNotification({
						title: notificationTitle,
					});
				} else {
					console.warn(
						"La requête vers " +
							path +
							formatParams(parameters) +
							" a échoué"
					);
				}
			};
		});
	});
}

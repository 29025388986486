import { AnalyticsEvent } from "./tracking/trackAnalytics";
import { getQueryString } from "./utils";

!(function () {
	TrackClicksOnPhoneNumbers();
})();

function TrackClicksOnPhoneNumbers()
{
	//Récupération des numéros de téléphone sur la page
	let links = document.querySelectorAll('[href^="tel:"]');

	links.forEach(link => {
		link.addEventListener('click', function()
		{
			new AnalyticsEvent({
				category: 'Clic',
				action: 'Telephone',
				label: link.innerHTML
			})
		});
	});
}

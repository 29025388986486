import { getMarkers, getMarkerPopup } from "../services/wordpress";

/**
 * Gestion asynchrone de la carte
 */
export default class EntitiesAsyncMap {
	constructor(container, mapSelector) {
		if (container == null) {
			throw new Error("Aucun container défini pour cette carte");
		}
		if (mapSelector == null) {
			throw new Error("Aucun mapSelector défini pour cette carte");
		}

		this.container = container;
		this.mapSelector = mapSelector;
		this.contentType = null;
		this.params = null;
		this.map = null;
	//	this.accessToken = null;
		this.searchCriterias = null;
		this.selector = null;
		this.bounds = [];
		this.markers = [];
		this.visible = false;
		this.clusterGroup = null;
		this.icon = L.icon({
			iconUrl:
				php_vars.stylesheet_directory_active_theme +
				"/assets/images/leaflet/marker-icon.png",
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [0, -30],
		});
	}

	/**
	 * Initialise la classe
	 */
	init() {
		this.initContentType();
		this.initParams();
		this.initSelector();
	//	this.initAccessToken();
		this.addToggleMapListener();
		this.addSearchEventListener();
	}

	/**
	 * Initialise le type de contenu géré par la carte
	 */
	initContentType() {
		this.contentType = this.container.getAttribute("data-map-content-type");

		if (this.contentType === null || this.contentType === "") {
			throw new Error(
				"Un type de contenu est nécessaire pour utiliser la carte (attribut data-map-content-type sur le container)"
			);
		}
	}

	/**
	 * Récupère les paramètres passés à la carte
	 */
	initParams() {
		this.params = entities_map_vars.params;
		this.searchCriterias = entities_map_vars.searchCriterias;
	}

	/**
	 * Définit le sélecteur du container de la carte
	 */
	initSelector() {
		this.selector = null;

		if (this.container.getAttribute("id") != null) {
			this.selector = this.container.getAttribute("id");
		} else {
			this.selector = "map-entities";
			this.container.setAttribute("id", this.selector);
		}
	}

	/**
	 * Initialise l'access Token nécessaire pour l'affichage de la carte
	 */
	/*
	initAccessToken() {
		this.accessToken = null;

		if (
			this.params &&
			this.params.accessToken &&
			this.params.accessToken != ""
		) {
			this.accessToken = this.params.accessToken;
		} else if (entities_map_vars && entities_map_vars.accessToken) {
			this.accessToken = entities_map_vars.accessToken;
		}

		if (this.accessToken === null) {
			throw new Error(
				"Un accès Token doit être spécifié pour utiliser la carte"
			);
		}
	}
	*/

	/**
	 * Ajoute un écouteur d'évènement sur le switch
	 */
	addToggleMapListener() {
		const me = this;
		this.mapSelector.addEventListener("change", (e) =>
			me.switchMapChanged(e.target.checked)
		);
	}

	/**
	 * Ajoute un écouteur d'évènement sur la recherche
	 */
	addSearchEventListener() {
		const me = this;
		document.addEventListener("search", (e) => {
			me.searchCriterias = e.detail;

			if (me.visible) {
				//Supprime les marqueurs affichés
				me.loadMarkers(true);
			}
		});
	}

	/**
	 * Evènement déclenché lorsque le switch de sélection de la carte change
	 * @param {bool} checked
	 */
	switchMapChanged(checked) {
		if (checked) {
			if (this.createMap()) {
				//Premier affichage de la carte, récupération des marqueurs
				this.loadMarkers();
			} else {
				//Réinitialise la position de la carte en fonction des marqueurs
				this.map.closePopup();
				this.center();
			}
			this.visible = true;
		} else {
			// this.hideMap();
		}
	}

	/**
	 * Créé une instance de la map Leaflet
	 */
	createMap() {
		if (this.map === null) {
			this.map = L.map(this.selector, {
				zoomControl: false,
				scrollWheelZoom: false,
			}).setView([46.69, 2.531], 5);

			let offset = this.map.getSize().x * 0.07;
			this.map.panBy(new L.Point(-offset, 0), { animate: true });

			this.createLayer();

			//Ajoute les boutons de contrôle du zoom
			L.control.zoom({ position: "bottomright" }).addTo(this.map);

			return true;
		} else {
			return false;
		}
	}

	createLayer() {
		this.layer = L.tileLayer(
			"//{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
		//	"https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
			{
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
		//			'Données &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Carte © <a href="https://www.mapbox.com/">Mapbox</a>',
				minZoom: 5,
				maxZoom: 16,
		//		id: "mapbox/streets-v11",
				tileSize: 512,
				zoomOffset: -1,
		//		accessToken: this.accessToken,
			}
		);
		this.map.addLayer(this.layer);
	}

	/**
	 * Charge les marqueurs à partir de l'API ajax de WordPress
	 */
	loadMarkers(clear = false) {
		const me = this;

		let parameters = {
			type: this.contentType,
			criterias: this.searchCriterias,
		};

		//Affiche un loader le temps du chargement
		this.addLoader();

		getMarkers(parameters)
			.finally(() => me.removeLoader())
			.then((response) => {
				let data = response.data.data;

				if (data && data.markers) {
					me.addMarkers(data.markers, clear);
				}
			})
			.catch(() =>
				console.warn(
					"Impossible de récupérer les marqueurs de la carte"
				)
			);
	}

	addMarkers(markers, clear) {
		let me = this;

		//Création d'un cluster
		if (this.clusterGroup === null) {
			this.clusterGroup = new L.MarkerClusterGroup({
				iconCreateFunction: function (clusterObj) {
					var digits = (clusterObj.getChildCount() + "").length;
					return L.divIcon({
						html: clusterObj.getChildCount(),
						className: "cluster digits-" + digits,
						iconSize: null,
					});
				},
			});
			this.map.addLayer(this.clusterGroup);
		} else if (clear) {
			//Suppression des anciens marqueurs
			this.clusterGroup.clearLayers();
			this.bounds = [];
		}

		//Ajout des marques du cluster
		markers.map((marker) => {
			// Ajouter l'élément au tableau de points pour gérer le zoom et le centrage
			this.bounds.push([marker.latitude, marker.longitude]);

			// Définir le marker
			let latLng = new L.LatLng(marker.latitude, marker.longitude);
			let markerObj = new L.Marker(latLng, {
				icon: this.icon,
				title: marker.tooltip,
			});

			// Ajouter la popup
			if (marker.popup) {
				markerObj.bindPopup(item.popup);
			} else {
				markerObj.on("click", () =>
					me.onMarkerClick(marker, markerObj)
				);
			}
			this.clusterGroup.addLayers(markerObj);
		});

		this.center();
	}

	/**
	 * Centre la carte
	 */
	center() {
		if (this.bounds.length > 0) {
			this.map.flyToBounds(this.bounds, { padding: [120, 50] });
		}
	}

	/**
	 * Evènement déclenché au clic sur un marqueur
	 * @param {*} marker
	 * @param {*} markerObj
	 */
	onMarkerClick(marker, markerObj) {
		//retire l'écouteur
		const popup = markerObj.getPopup();
		if (popup == null) {
			this.loadMarkerPopup(marker, markerObj);
		} else {
			popup.openPopup();
		}
	}

	/**
	 * Charge le contenu d'un marqueur
	 * @param {*} marker
	 * @param {*} markerObj
	 */
	loadMarkerPopup(marker, markerObj) {
		const me = this;
		let parameters = {
			id: marker.id,
			type: marker.type,
		};

		//Affiche un loader le temps du chargement
		this.addLoader();

		getMarkerPopup(parameters)
			.finally(() => me.removeLoader())
			.then((response) => {
				let data = response.data.data;

				if (data && data.popup) {
					markerObj.bindPopup(data.popup).openPopup();
				}
			})
			.catch(() =>
				console.warn("Impossible de récupérer le détail du marqueur")
			);
	}

	addLoader() {
		if (!this.loader) {
			let loaderWrapper = document.createElement("div");
			loaderWrapper.classList.add("map-loader-wrapper");

			let loader = document.createElement("div");
			loader.classList.add("map-loader");
			loader.innerHTML = "Chargement en cours";
			loaderWrapper.append(loader);

			this.container.append(loaderWrapper);
			this.loader = loaderWrapper;
		}

		this.loader.classList.add("loading");
	}

	removeLoader() {
		this.loader.classList.remove("loading");
	}
}

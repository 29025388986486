import "./css/rendez-vous-style.scss";
import { getQueryString, formatParams } from "../utils";
import Glide from "@glidejs/glide";
import EntitiesCalendar from "./calendar";

export default class RendezVousForm {
	constructor() {
		this.wrapper = document.querySelector("[data-form-step]");
		this.steps = Array();
		this.railroad = null;

		//Initialisation des données nécessaires au rendez-vous
		this.instantiateDatas();

		//Récupération des étapes
		this.getFormSteps();

		//Mise en forme
		this.createFormStyle();

		//Création du chemin de fer
		this.createRailRoad();

		//Navigation
		this.setNavigation();
	}

	/**
	 * Initialise les données nécessaires au rendez-vous
	 */
	instantiateDatas() {
		this.datas = {
			agency_id: null,
			agency_source_code: null,
			agency_name: null,
			agency_selected_thumb: null,
			project_location: null,
			rdv_location: null,
			rdv_date: null,
			rdv_time: null,
			contact: [],
		};
	}

	/**
	 * Met le formulaire en forme
	 */
	createFormStyle() {
		let ref = this;
		this.wrapper.style.position = "relative";

		this.steps.forEach((step) => {
			if (ref.isCurrentStep(step)) {
				ref.enableStep(step);
			}
		});
	}

	// #region Étapes

	/**
	 * Récupère les étapes du formulaire
	 */
	getFormSteps() {
		let ref = this;
		let steps = this.wrapper.querySelectorAll("[data-step]");

		if (steps && steps.length > 0) {
			steps.forEach((step) => {
				let stepObject = ref.setStepFromDom(step);

				step.style.transition = "opacity ease-in-out .5s";
				step.style.position = "absolute";

				this.steps.push(stepObject);
			});

			this.currentStep = this.steps.filter((s) => s.step_number == 1)[0];
		}
	}

	/**
	 * Marque une étape comme active
	 * @param {*} step
	 */
	enableStep(step) {
		//Modification de la taille du wrapper en fonction de la div étape
		let domStep = step.step;
		let height = parseFloat(
			getComputedStyle(domStep, null).height.replace("px", "")
		);

		this.wrapper.style.height = height + "px";

		step.step.style.opacity = 1;
		step.step.style.zIndex = 1;
		step.step.style.pointerEvents = "all";
		// remonter en haut de l'étape à afficher
		window.scrollTo(0, 0);

		//Active les événements de l'étape courante
		this.currentStep = step;
		this.specialEvents();
	}

	/**
	 * Marque une étape comme inactive
	 * @param {*} step
	 */
	disableStep(step) {
		step.step.style.opacity = 0;
		step.step.style.zIndex = 0;
	}

	/**
	 * Vérifie que l'objet étape passé en paramètre est l'étape courante
	 * @param {object} step L'étape à vérifier
	 */
	isCurrentStep(step) {
		return step.step_number == this.currentStep.step_number;
	}

	/**
	 * Créée une objet étape à partir d'une div étape
	 * @param {*} domStep
	 */
	setStepFromDom(domStep) {
		return {
			step: domStep,
			step_number: parseInt(domStep.getAttribute("data-step")),
			step_type: domStep.getAttribute("data-step-type"),
		};
	}

	//#endregion

	//#region Navigation

	/**
	 * Permet de changer d'étape
	 */
	setNavigation() {
		let ref = this;
		let triggers = document.querySelectorAll("[data-step-trigger]");
		if (triggers && triggers.length > 0) {
			triggers.forEach((trigger) => {
				trigger.addEventListener("mouseup", function () {
					//Désactivation de l'étape courante
					ref.disableStep(ref.currentStep);

					//Activation de l'étape ciblée
					let step_number = trigger.getAttribute("data-step-trigger");
					let targets = ref.steps.filter(
						(s) => s.step_number == parseInt(step_number)
					);
					if (targets && targets.length > 0) {
						targets.forEach((target) => {
							ref.enableStep(target);
							ref.currentStep = target;
							ref.activateRailroadStep();
						});
					}
				});
			});
		}
	}

	/**
	 * Création du chemin de fer
	 */
	createRailRoad() {
		let currentStep = null;
		let railroad = document.createElement("div");
		railroad.classList.add("rendez-vous-railroad");

		//Création du rail
		let rail = document.createElement("div");
		rail.classList.add("rendez-vous-railroad-track");
		railroad.appendChild(rail);

		this.steps.forEach((step) => {
			let number = step.step_number;
			let railroadStep = document.createElement("div");
			railroadStep.classList.add("rendez-vous-railroad-step");
			railroadStep.setAttribute("data-step-trigger", number);
			railroadStep.innerHTML = number;

			if (currentStep == null) {
				railroad.appendChild(railroadStep);
			} else {
				railroad.insertBefore(railroadStep, currentStep);
			}

			currentStep = railroadStep;
		});

		this.railroad = railroad;
		this.activateRailroadStep();
		this.wrapper.parentNode.insertBefore(railroad, this.wrapper);
	}

	activateRailroadStep() {
		let currentStepNumber = this.currentStep.step_number;

		let railroadSteps = this.railroad.querySelectorAll(
			"[data-step-trigger]"
		);
		railroadSteps.forEach((railroadStep) => {
			let step_number = railroadStep.getAttribute("data-step-trigger");
			if (currentStepNumber > parseInt(step_number)) {
				railroadStep.classList.remove("current");
				railroadStep.classList.remove("inactive");
				railroadStep.style.pointerEvents = "all";
			} else if (currentStepNumber < parseInt(step_number)) {
				railroadStep.classList.remove("current");
				railroadStep.classList.add("inactive");
				railroadStep.style.pointerEvents = "none";
			} else {
				railroadStep.classList.remove("inactive");
				railroadStep.classList.add("current");
				railroadStep.style.pointerEvents = "none";
			}
		});
	}

	//#endregion

	//#region Actions
	specialEvents() {
		switch (this.currentStep.step_type) {
			case "project-location":
				this.projectLocationEvent();
				break;
			case "rendez-vous-agency":
				this.rdvAgencyEvent();
				break;
			case "rendez-vous-location":
				this.rdvLocationChoiceEvent();
				break;
			case "rendez-vous-date":
				this.dateChoiceEvent();
				break;
			case "rendez-vous-form":
				this.formEvent();
				break;
			case "rendez-vous-resume":
				this.resumeEvent();
				break;
		}
	}

	//#region Actions de la localisation du projet

	/**
	 * Action de l'étape de localisation du projet
	 */
	projectLocationEvent() {
		if (this.currentStep.step_type != "project-location") return;

		let ref = this;

		//Désactivation des triggers
		let trigger = this.currentStep.step.querySelector(
			"[data-step-trigger]"
		);
		trigger.style.opacity = 0;
		trigger.style.pointerEvents = "none";
		trigger.setAttribute("aria-hidden", "true");

		//On attend que l'autocompletion se charge
		let autocompleteField = document.getElementById("autocomplete-results");

		var checkExist = setInterval(function () {
			autocompleteField = document.getElementById("autocomplete-results");
			if (autocompleteField) {
				clearInterval(checkExist);

				var config = { attributes: true, attributeOldValue: true };
				var callback = function (mutationsList) {
					for (var mutation of mutationsList) {
						if (mutation.type == "attributes") {
							if (autocompleteField.value != "") {
								ref.datas.project_location =
									autocompleteField.value;
								trigger.style.opacity = 1;
								trigger.style.pointerEvents = "all";
								trigger.setAttribute("aria-hidden", "false");
							}
						}
					}
				};
				var observer = new MutationObserver(callback);
				observer.observe(autocompleteField, config);
			}
		}, 100);
	}

	//#endregion

	//#region Actions du choix du lieu et de la sélection de l'agence
	/**
	 * Action de l'étape de choix du lieu de rendez-vous
	 */
	rdvAgencyEvent() {
		let ref = this;

		//Création du loader
		let agenciesChoiceWrapper = this.currentStep.step.querySelector(
			"[data-agencies-choice]"
		);
		agenciesChoiceWrapper.innerHTML = "";

		let loader = agenciesChoiceWrapper.querySelector(".rendez-vous-loader");

		if (loader == null) {
			loader = document.createElement("div");
			loader.classList.add("rendez-vous-loader");
			loader.classList.add("loading");
			let spinner = document.createElement("div");
			spinner.classList.add("lds-spinner");

			for (var i = 0; i < 12; i++) {
				let spin = document.createElement("div");
				spinner.append(spin);
			}

			loader.appendChild(spinner);

			agenciesChoiceWrapper.appendChild(loader);
		}

		let xhr = new XMLHttpRequest();
		let parameters = {
			action: "rdv_form_agency_search",
			location: ref.datas.project_location,
		};

		let path = php_vars.ajaxurl;
		xhr.open("GET", path + formatParams(parameters), true);
		xhr.send(parameters);
		xhr.onload = function () {
			if (xhr.readyState == 4) {
				ref.loading = false;
				if (xhr.status >= 200 && xhr.status < 300) {
					let response = JSON.parse(xhr.responseText);
					let data = response.data;

					let thumbs = data.agencies;

					//Création du container du slider
					let agencyChoiceWrapper =
						ref.currentStep.step.querySelector(
							"[data-agencies-choice]"
						);

					loader.classList.remove("loading");
					agencyChoiceWrapper.innerHTML = "";
					agencyChoiceWrapper.classList.add("glide");

					let track = document.createElement("div");
					track.setAttribute("data-glide-el", "track");
					track.classList.add("glide__track");

					let slides = document.createElement("ul");
					slides.classList.add("glide__slides");
					track.append(slides);

					//Injection des agences dans le slider
					thumbs.forEach((agency, index) => {
						let thumbWrapper = document.createElement("li");
						thumbWrapper.classList.add("glide__slide");
						thumbWrapper.innerHTML = agency.thumb;

						// #region Création du radio button
						let radio = document.createElement("input");
						radio.setAttribute("type", "radio");
						radio.setAttribute("name", "agency_choice");
						radio.setAttribute("id", "agency_choice_" + agency.id);
						radio.style.position = "absolute";
						radio.style.display = "none";

						if (index == 0) {
							radio.checked = true;
							thumbWrapper.classList.add("active-agency");
							ref.datas.agency_id = agency.id;
							ref.datas.agency_source_code = agency.source_code;
							ref.datas.agency_name = agency.name;
							ref.datas.agency_selected_thumb =
								agency.selected_thumb;
						}

						thumbWrapper.appendChild(radio);
						//#endregion

						let thumbLinks = thumbWrapper.querySelectorAll("a");
						thumbLinks.forEach((thumbLink) => {
							thumbLink.setAttribute("rel", thumbLink.href);
							thumbLink.href = "javascript:";
						});

						//Ajout de l'événement de sélection de l'agence
						thumbWrapper.addEventListener("click", function () {
							let activeAgency =
								agencyChoiceWrapper.querySelector(
									".active-agency"
								);
							if (activeAgency) {
								activeAgency.classList.remove("active-agency");
							}
							radio.checked = true;
							thumbWrapper.classList.add("active-agency");
							ref.datas.agency_id = agency.id;
							ref.datas.agency_source_code = agency.source_code;
							ref.datas.agency_name = agency.name;
							ref.datas.agency_selected_thumb =
								agency.selected_thumb;
						});

						slides.appendChild(thumbWrapper);
					});

					agencyChoiceWrapper.append(track);

					//Initialisation du slider
					ref.agencyChoiceSlidersEvents();
				} else {
					console.warn(
						"La requête vers " +
						path +
						formatParams(parameters) +
						" a échoué"
					);
				}
			}
		};
	}

	/**
	 * Activation du slider pour le choix des agences
	 */
	agencyChoiceSlidersEvents() {
		let sliderWrapper = this.currentStep.step.querySelector(
			"[data-agencies-choice]"
		);

		if (!sliderWrapper) return;

		let numberToShow = 3;

		let slider = new Glide(sliderWrapper, {
			type: "slider",
			autoplay: false,
			animationDuration: 1000,
			rewind: true,
			animationTimingFunc: "ease-out",
			perView: numberToShow,
			gap: 0,
			bound: true,
			focusAt: "center",
			breakpoints: {
				1200: {
					perView: 2,
					gap: 0,
				},
				768: {
					perView: 1.15,
					gap: 0,
				},
			},
		});

		//Si le slider n'a qu'une image, on le désactive
		slider.on("mount.before", function () {
			if (sliderWrapper.querySelectorAll("div").length <= numberToShow) {
				slider.update({
					rewind: false,
				});
				slider.disable();
			}
		});

		slider.mount();
	}

	//#endregion

	//#region Activation du choix du lieu de RDV
	rdvLocationChoiceEvent() {
		const ref = this;

		let locationChoiceWrapper = this.currentStep.step.querySelector(
			"[data-rdv-location-choice]"
		);
		if (locationChoiceWrapper) {
			let choices = locationChoiceWrapper.querySelectorAll(
				"[data-step-trigger]"
			);
			if (choices) {
				choices.forEach((choice) => {
					choice.addEventListener("click", function (e) {
						ref.datas.rdv_location = choice.value;
					});
				});
			}
		}
	}
	//#endregion

	//#region Actions du choix de la date
	/**
	 * Actions de l'étape de choix du moment du rendez-vous
	 */
	dateChoiceEvent() {
		const ref = this;

		//Récupération de l'agence

		let selectedAgencyWrapper = ref.currentStep.step.querySelector(
			"[data-selected-agency]"
		);

		selectedAgencyWrapper.innerHTML = "";

		//Injection de la vignette
		selectedAgencyWrapper.innerHTML = ref.datas.agency_selected_thumb;

		//Calendrier
		if (!ref.calendar) {
			let calendar = new EntitiesCalendar();
			calendar.initializeCalendar();
			ref.calendar = calendar;
		}
		let calendarWrapper = document.querySelector("[data-calendar]");
		let choices = calendarWrapper.querySelectorAll("[data-step-trigger]");

		if (choices) {
			choices.forEach((choice) => {
				choice.addEventListener("click", function (e) {
					ref.datas.rdv_date = ref.calendar.value("date");
					ref.datas.rdv_time = ref.calendar.value("time");
				});
			});
		}
	}

	//#endregion

	//#region Actions du Formulaire

	/**
	 * Action de l'étape du formulaire
	 */
	formEvent() {
		let ref = this;
		let requiredFields =
			ref.currentStep.step.querySelectorAll("[aria-required]");
		let valid = ref.checkFormValidity(requiredFields); //Validité des champs
		let confirmButton = ref.currentStep.step.querySelector("[type=submit]"); //Bouton de confirmation

		ref.activateSubmitButton(confirmButton, valid);

		// console.log("datas", ref.datas);

		confirmButton.setAttribute("data-step-trigger", "6");
		ref.setNavigation();

		requiredFields.forEach((requiredField) => {
			requiredField.addEventListener("keydown", function () {
				valid = ref.checkFormValidity(requiredFields);
				ref.activateSubmitButton(confirmButton, valid);

				if (valid) {
					ref.addDatasInForm();
					ref.datas.contact = ref.saveFormDatas(requiredFields);
				}
			});
		});
	}

	/**
	 * Vérifie que le formulaire est correctement rempli
	 * @param {} fields
	 */
	checkFormValidity(fields) {
		let valid = true;
		let ref = this;

		fields.forEach((field) => {
			if (field.value.length == 0) {
				valid = false;
			}

			if (field.getAttribute("type") == "tel") {
				if (!ref.validatePhoneNumber(field.value)) {
					valid = false;
					if (field.value.length > 0) {
						field.classList.add("error");
					}
				} else {
					field.classList.remove("error");
				}
			}

			if (field.getAttribute("type") == "email") {
				if (!ref.validateEmail(field.value)) {
					valid = false;
					if (field.value.length > 0) {
						field.classList.add("error");
					}
				} else {
					field.classList.remove("error");
				}
			}

			if (field.getAttribute("type") == "checkbox" && !field.checked) {
				valid = false;
			}
		});

		return valid;
	}

	/**
	 * Active ou désactive la soumission du formulaire
	 * @param {*} confirmButton
	 * @param {*} valid
	 */
	activateSubmitButton(confirmButton, valid) {
		if (valid) {
			confirmButton.removeAttribute("disabled");
		} else {
			confirmButton.setAttribute("disabled", "disabled");
		}
	}

	/**
	 * Vérifie que le numéro de téléphone est correct
	 * @param {*} num
	 */
	validatePhoneNumber(num) {
		var regex = new RegExp(
			"(\\+[0-9]{1,3}-)?(\\([0-9]{1,3}\\)){0,1}[0-9+\\-]{1,24}[0-9]"
		);

		return regex.test(num);
	}

	/**
	 * Vérifie que l'adresse email est correcte
	 * @param {*} email
	 */
	validateEmail(email) {
		var re =
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(email);
	}

	/**
	 * Enregistre les valeurs des champs de formulaire dans l'objet
	 * @param {} fields
	 */
	saveFormDatas(fields) {
		let datas = [];

		fields.forEach((field) => {
			datas[field.getAttribute("name")] = field.value;
		});

		return datas;
	}

	addDatasInForm() {
		// debugger;
		const ref = this;
		const datas = this.datas;
		let form = this.currentStep.step.querySelector("form");
		let project_location_label = "";
		let project_location_dep_code = "";

		try {
			if (ref.datas.project_location) {
				const project_location = JSON.parse(ref.datas.project_location);
				if (project_location) {
					project_location_label = project_location[0].name;
					project_location_dep_code =
						project_location[0].departmentCode;
				}
			}
		} catch (error) { }

		this.addHiddenField(
			form,
			"contact-project-location",
			project_location_label
		);
		this.addHiddenField(
			form,
			"contact-project-department-code",
			project_location_dep_code
		);
		this.addHiddenField(form, "code-source", datas.agency_source_code);
		this.addHiddenField(form, "agency-id", datas.agency_id);
		this.addHiddenField(form, "agency-name", datas.agency_name);
		this.addHiddenField(form, "rdv-location", datas.rdv_location);
		this.addHiddenField(form, "rdv-date", datas.rdv_date);
		this.addHiddenField(form, "rdv-time", datas.rdv_time);
	}

	//#endregion

	//#region Actions du récapitulatif
	resumeEvent() {
		const ref = this;
		const datas = this.datas;

		//Affichage du récapitulatif
		let recapWrapper = this.wrapper.querySelector("[data-step-resume]");
		recapWrapper.innerHTML = "";

		if (datas.rdv_location == "house") {
			ref.addRecapElement(recapWrapper, "à votre domicile");
		} else {
			ref.addRecapElement(
				recapWrapper,
				"dans nos locaux : " + datas.agency_name
			);
		}

		let dateParsed = Date.parse(datas.rdv_date);
		let dateRDV = new Date(dateParsed).toLocaleDateString("fr-FR", {
			weekday: "long",
			year: "numeric",
			month: "long",
			day: "numeric",
		});

		ref.addRecapElement(recapWrapper, "Le " + dateRDV);
		ref.addRecapElement(recapWrapper, datas.rdv_time);
	}

	//Ajout de champ caché
	addHiddenField(form, key, value) {
		let field = document.querySelector('[name="' + key + '"]');

		if (field) {
			field.value = value;
		} else {
			let hiddenField = document.createElement("input");
			hiddenField.setAttribute("type", "hidden");
			hiddenField.setAttribute("name", key);
			hiddenField.setAttribute("id", key);
			hiddenField.value = value;

			form.append(hiddenField);
		}
	}

	//Ajout des récapitulatifs
	addRecapElement(recapWrapper, value) {
		let element = document.createElement("li");
		element.classList.add(".rendez-vous-recap-item");
		element.innerHTML = value;

		recapWrapper.append(element);
	}

	//#endregion

	//#endregion
}

!(function () {
	let page = document.querySelector("body");
	if (!page.classList.contains("rendez-vous")) {
		return;
	}

	new RendezVousForm();
})();

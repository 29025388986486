import "./css/map-style.scss";

// =====================================
// Gestion des Cartes
//		Leaflet
// =====================================

/**
 * EntitiesMap :
 * Markers créés via les éléments .js-map-marker présent dans la page
 */
export default class EntitiesMap {
	constructor(container = null, params = {}) {
		if (container == null) {
			console.warn("Aucun container défini pour cette carte");
			return;
		}

		this.container = container;
		this.selector = this.getSelector();
		this.params = params;
		this.map = null;
		this.layer = null;
		this.bounds = [];
//		this.accessToken = this.getAccessToken();
		this.items = entities_map_vars.items;
		this.items = [];
		this.defaultMarker = this.getDefaultMarker();
		this.clusters;
		this.bounds = [];

		this.getItems();
		this.setMap();
		this.setLayer();
		this.initClusters();
		this.setZoomPosition();
		this.setMarkers();
		let ref = this;

		document.addEventListener("DOMContentLoaded", function () {
			ref.observeDom();
		});
	}

	/**Récupère l'access Token nécessaire pour l'affichage de la carte */
	//	getAccessToken() {
	//		if (
	//			this.params &&
	//			this.params.accessToken &&
	//			this.params.accessToken != ""
	//		) {
	//			return this.params.accessToken;
	//		}
	//
	//		if (entities_map_vars && entities_map_vars.accessToken) {
	//			return entities_map_vars.accessToken;
	//		}
	//
	//		console.error("Vous devez spécifier un acces token");
	//		return false;
	//	}

	/**
	 * Définit le sélecteur du container de la carte
	 */
	getSelector() {
		if (this.container.getAttribute("id") != null) {
			return this.container.getAttribute("id");
		}

		this.container.setAttribute("id", "map-entities");
		return "map-entities";
	}

	/**
	 * Initialise la carte
	 */
	setMap() {
		const map = L.map(this.selector, {
			zoomControl: false,
			scrollWheelZoom: false,
		}).setView([46.69, 2.531], 5);

		let offset = map.getSize().x * 0.07;
		map.panBy(new L.Point(-offset, 0), { animate: true });

		this.map = map;
	}

	updateMap() {
		this.map.removeLayer(this.clusters);
		this.items = [];
		this.getItems();
		this.initClusters();
		this.setLayer();
		this.setMarkers();
		this.map.invalidateSize();
	}

	observeDom() {
		let ref = this;
		const targetNode = document.querySelector(".filtered-list--items-list");
		const config = { childList: true };

		const callback = function (mutationsList, observer) {
			for (const mutation of mutationsList) {
				if (mutation.type === "childList") {
					// observer.disconnect();
					ref.updateMap();
					break;
				}
			}
		};

		const observer = new MutationObserver(callback);
		observer.observe(targetNode, config);
	}

	/**
	 * Définit le fond de carte
	 */
	setLayer() {
		this.layer = L.tileLayer(
				"//{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
			// "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
			{
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
			//		'Données &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Carte © <a href="https://www.mapbox.com/">Mapbox</a>',
				minZoom: 5,
				maxZoom: 16,
			//	id: "mapbox/streets-v11",
				tileSize: 512,
				zoomOffset: -1,
			//	accessToken: this.accessToken,
			}
		);
		this.map.addLayer(this.layer);
	}

	/**
	 * Définit la position des boutons de zoom
	 */
	setZoomPosition() {
		L.control.zoom({ position: "bottomright" }).addTo(this.map);
	}

	/**
	 * Définit le marqueur par défaut
	 */
	getDefaultMarker() {
		return L.icon({
			iconUrl:
				php_vars.stylesheet_directory_active_theme +
				"/assets/images/leaflet/marker-icon.png",
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [0, -30],
		});
	}

	/**
	 * Initialise les clusters
	 */
	initClusters() {
		this.clusters = new L.MarkerClusterGroup({
			iconCreateFunction: function (cluster) {
				var digits = (cluster.getChildCount() + "").length;
				return L.divIcon({
					html: cluster.getChildCount(),
					className: "cluster digits-" + digits,
					iconSize: null,
				});
			},
		});
	}

	/**
	 * Ajoute les marqueurs sur la carte
	 */
	setMarkers() {
		let ref = this;
		this.items.forEach((item) => {
			// Ajouter l'élément au tableau de points pour gérer le zoom et le centrage
			this.bounds.push([item.latitude, item.longitude]);

			// Définir le marker
			let latLng = new L.LatLng(item.latitude, item.longitude);
			let marker = new L.Marker(latLng, {
				icon: ref.defaultMarker,
				title: item.town,
			});

			// Ajouter la popup
			if (item.popup) {
				marker.bindPopup(item.popup);
			}
			ref.clusters.addLayers(marker);
		});
		this.map.addLayer(this.clusters);
		this.center(); // Centrer la carte
	}

	/**
	 * Centrer la carte
	 */
	center() {
		if (this.bounds.length > 0) {
			this.map.flyToBounds(this.bounds, { padding: [120, 50] });
		}
	}

	getItems() {
		this.items = [];
		let ref = this;
		let wrapper = document.querySelector("#filtered-list--items-container");
		let thumbs = wrapper.querySelectorAll(".js-map-marker");
		thumbs.forEach((thumb) => {
			if (!thumb.parentNode.classList.contains("hide")) {
				let clone = thumb.cloneNode(true);
				let item = {
					latitude: thumb.getAttribute("data-lat"),
					longitude: thumb.getAttribute("data-lng"),
					id: thumb.getAttribute("data-map-text"),
					popup: clone,
				};
				ref.items.push(item);
			}
		});
	}
}

export class EntitiesSimpleMap {
	constructor(container = null, params = {}) {
		if (container == null) {
			console.warn("Aucun container défini pour cette carte");
			return;
		}

		this.container = container;
		this.selector = this.getSelector();
		this.params = params;
		this.map = null;
		this.layer = null;
		this.bounds = [];
//		this.accessToken = this.getAccessToken();
		this.items = entities_map_vars.items ?? this.getMarker();
		this.defaultMarker = this.getDefaultMarker();
		this.clusters;

		this.setMap();
		this.setLayer();
		this.initClusters();
		this.setZoomControlersPosition();
		this.setMarkers();
	}

	getMarker() {
		let items = Array();
		let offerCoordinates = document.querySelector("[data-offer-id]");

		if (offerCoordinates) {
			let item = {
				latitude: offerCoordinates.getAttribute("data-lat"),
				longitude: offerCoordinates.getAttribute("data-lng"),
				town: null,
				type: offerCoordinates.getAttribute("data-type"),
			};

			if (item.latitude != 0 && item.longitude != 0)
			{
				items.push(item);
			}
		}

		let agencyCoordinates = document.querySelector('[data-type="agency"]');
		if (agencyCoordinates) {
			let agencyItem = {
				latitude: agencyCoordinates.getAttribute("data-lat"),
				longitude: agencyCoordinates.getAttribute("data-lng"),
				town: agencyCoordinates.getAttribute("data-txt"),
				type: agencyCoordinates.getAttribute("data-type"),
			};

			if (agencyItem.latitude != 0 && agencyItem.longitude != 0)
			{
				items.push(agencyItem);
			}
		}

		return items;
	}

	/**Récupère l'access Token nécessaire pour l'affichage de la carte */
//	getAccessToken() {
//		if (
//			this.params &&
//			this.params.accessToken &&
//			this.params.accessToken != ""
//		) {
//			return this.params.accessToken;
//		}
//
//		if (entities_map_vars && entities_map_vars.accessToken) {
//			return entities_map_vars.accessToken;
//		}
//
//		console.error("Vous devez spécifier un acces token");
//		return false;
//	}

	/**
	 * Définit le sélecteur du container de la carte
	 */
	getSelector() {
		if (this.container.getAttribute("id") != null) {
			return this.container.getAttribute("id");
		}

		this.container.setAttribute("id", "map-entities");
		return "map-entities";
	}

	setMap() {
		const map = L.map(this.selector, {
			zoomControl: false,
			scrollWheelZoom: false,
		}).setView([46.69, 2.531], 5);

		let offset = map.getSize().x * 0.07;
		map.panBy(new L.Point(-offset, 0), { animate: true });

		this.map = map;
	}

	/**
	 * Définit le fond de carte
	 */
	setLayer() {
		this.layer = L.tileLayer(
			"//{s}.tile.openstreetmap.fr/osmfr/{z}/{x}/{y}.png",
		//	"https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
			{
				attribution:
					'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
		//			'Données &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a>, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Carte © <a href="https://www.mapbox.com/">Mapbox</a>',
				minZoom: 5,
				maxZoom: 16,
		//		id: "mapbox/streets-v11",
				tileSize: 512,
				zoomOffset: -1,
		//		accessToken: this.accessToken,
			}
		);
		this.map.addLayer(this.layer);
	}

	/**
	 * Définit la position des boutons de zoom
	 */
	setZoomControlersPosition() {
		L.control.zoom({ position: "bottomright" }).addTo(this.map);
	}

	/**
	 * Définit le marqueur par défaut
	 */
	getDefaultMarker() {
		return L.icon({
			iconUrl:
				php_vars.stylesheet_directory_active_theme +
				"/assets/images/leaflet/marker-icon.png",
			iconSize: [25, 41],
			iconAnchor: [12, 41],
			popupAnchor: [0, -30],
		});
	}

	getAgencyMarker() {
		return L.icon({
			iconUrl:
				php_vars.stylesheet_directory_active_theme +
				"/assets/images/leaflet/marker-agence.png",
			iconSize: [25, 37],
			iconAnchor: [12, 41],
			popupAnchor: [0, -30],
		});
	}

	/**
	 * Initialise les clusters
	 */
	initClusters() {
		this.clusters = new L.MarkerClusterGroup({
			iconCreateFunction: function (cluster) {
				var digits = (cluster.getChildCount() + "").length;
				return L.divIcon({
					html: cluster.getChildCount(),
					className: "cluster digits-" + digits,
					iconSize: null,
				});
			},
		});
	}

	/**
	 * Centrer la carte
	 */
	center() {
		this.map.flyToBounds(this.bounds);
	}

	/**
	 * Ajoute les marqueurs sur la carte
	 */
	setMarkers() {
		let ref = this;
		if (!this.items) {
			return false;
		}
		this.items.forEach((item) => {
			// Ajouter l'élément au tableau de points pour gérer le zoom et le centrage
			this.bounds.push([item.latitude, item.longitude]);
			// Création du marker
			let latLng = new L.LatLng(item.latitude, item.longitude);
			let marker = new L.Marker(latLng, {
				icon:
					item.type == "agency"
						? ref.getAgencyMarker()
						: ref.defaultMarker,
				title: item.town,
			});

			if (item.popup) {
				marker.bindPopup(item.popup);
			}
			ref.clusters.addLayers(marker);
		});
		this.map.addLayer(this.clusters);
		this.center(); // Centrer la carte
	}
}

/**
 * 	Actions sur les cartes
 */
export function cards_rectoVerso () {

	// Retourner les cartes recto-verso
	let cardsRectoVerso = document.querySelectorAll('.js-card-recto-verso-switcher');
	if ( cardsRectoVerso.length > 0 ) {

		cardsRectoVerso.forEach( (card) => {

			card.addEventListener( 'click', function( event ) {
				event = event || window.event;
				//change la valeur de aria-pressed quand le bouton est basculé :
				let btn = this
				let btn_state = btn.getAttribute("aria-pressed") == "true";
				btn.setAttribute("aria-pressed", btn_state ? "false" : "true");
				// Appliquer la class
				card.classList.toggle('is-flipped')
				event.preventDefault()
			});
		})
	}
}


import api from './wpjson'

/**
 * Retourne les marqueurs de la carte
 * @param {*} params
 */
export const getMarkers = function (data) {
    return api.post('entities/map/getMarkers', data)
}

/**
 * Retourne le détail de la popup du marqueur
 * @param {*} data
 */
export const getMarkerPopup = function (data) {
    return api.post('entities/map/getMarkerPopup', data)
}

/**
 * Traque une recherche
 * @param {*} data
 */
export const trackSearch = function(data){
    return api.post('entities/tracking/trackSearch', data)
}


/**
 * Exécute une recherche
 * @param {*} params
 */
 export const doSearch = function (data) {
    return api.post('entities/search/doSearch', data)
}

/**
 * Génère et télécharge un PDF
 */
export const downloadPdf = function(data) {
	return api.post('entities/download/downloadPdf', data)
}

import { activateScrollTo } from "../utils";

export default class ContentParser {
	constructor() {
		this.parseSeoPageContent();
	}

	//Modifie le contenu des pages SEO
	parseSeoPageContent() {
		//Si on n'est pas sur la page SEO, on arrête
		if (!document.querySelector(".seo-page-detail")) {
			return;
		}

		//Récupération du texte
		let container = document.querySelector(".detail-item-description");
		let paragraphs = container.querySelectorAll("p");

		//Si il y a plus de deux paragraphes, on ajoute des boutons
		if (paragraphs && paragraphs.length > 2) {
			let ctaWrapper = document.createElement("div");
			ctaWrapper.classList.add("cta-wrapper");

			//Ajout du CTA agences
			if (document.querySelector('.detail-item-agencies'))
			{
				let ctaAgency = this.createCta('button', 'Voir nos agences', ['seo-page-cta'], [{
					key: 'data-scroll-to',
					value: '.detail-item-agencies'
				}]);

				ctaWrapper.appendChild(ctaAgency);
			}

			//Ajout du CTA réalisations
			if (document.querySelector('.detail-item-realizations'))
			{
				let ctaRealization = this.createCta('button', 'Voir nos réalisations', ['seo-page-cta'], [{
					key: 'data-scroll-to',
					value: '.detail-item-realizations'
				}]);

				ctaWrapper.appendChild(ctaRealization);
			}

			paragraphs.forEach((paragraph, index) => {
				//On ajoute les CTA's après le deuxième paragraphe
				if (index == 0) {
					paragraph.insertAdjacentElement('afterend', ctaWrapper);
					activateScrollTo()
				}

				//On change la position du bloc catalogue si le texte fait plus de 5 paragraphes
				if (paragraphs.length > 2 && index == 2)
				{
					let catalogBlock = document.querySelector('.detail-item-manchette-catalog');
					if (catalogBlock)
					{
						let newCatalogBlock = catalogBlock.cloneNode(true);
						newCatalogBlock.classList.add('in-text');
						paragraph.insertAdjacentElement('afterend', newCatalogBlock);
						catalogBlock.parentNode.removeChild(catalogBlock);
					}
				}
			});
		}
	}

	createCta(type='button', label = 'en savoir +', classNames = ['cta'], attributes = [])
	{
		//Création du bouton
		let cta = document.createElement(type);

		//Ajout des classes au bouton
		if (classNames && classNames.length > 0)
		{
			classNames.forEach(className => {
				cta.classList.add(className);
			});

		}

		//Attributs du bouton
		if (attributes && attributes.length > 0)
		{
			attributes.forEach(attribute => {
				cta.setAttribute(attribute.key, attribute.value);
			})
		}

		//Libellé du bouton
		cta.innerHTML = label;

		return cta;
	}
}

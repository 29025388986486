import EntitiesMap, { EntitiesSimpleMap } from './map/map';
import EntitiesAsyncMap from './map/entitiesAsyncMap';

!function () {

	// Les listes
	let filteredlist_asyncMap = document.querySelector('#js-filtered-list--async-map');
	if ( filteredlist_asyncMap )
	{
		initMapCliping()
		initFilteredListMap()
	}

	// Les Détails : Agencie, Offers,…
	let mapSimple__Container = document.querySelector("#js-map-simple");
	if ( mapSimple__Container )
	{
		initSimpleMap( mapSimple__Container )
	}
}();


/**
 * 	Initialise le cliping de la carte au centre du bouton switch "Liste <> Carte"
 *	pour débuter l'animation d'ouverture de la carte depuis le bouton
 */
function initMapCliping() {
	let switch_map__el = document.querySelector("#layout-choice-map");
	let mapContainer__el = document.querySelector("#js-filtered-list--map,#js-filtered-list--async-map");

	if (switch_map__el && switch_map__el != null && mapContainer__el && mapContainer__el != null) {
		// Rafraichir la position au scroll et resize
		window.addEventListener('scroll', () => setMapCliping(mapContainer__el, switch_map__el), false);
		window.addEventListener('resize', () => setMapCliping(mapContainer__el, switch_map__el), false);
		setMapCliping(mapContainer__el, switch_map__el)

		switch_map__el.addEventListener('change', function()
		{
			let body = document.querySelector('body');
			if (switch_map__el.checked)
			{
				body.setAttribute('style', 'height:100vh; overflow:hidden');
			}
			else
			{
				body.setAttribute('style', 'height:auto; overflow:auto');
			}
		});
	}
}
/**
 * Affecte le cliping de la carte au centre du switch Liste-Carte
 */
function setMapCliping(mapContainer__el, switch_map__el) {
	// Obtenir la position du switch
	let switch_map__pos = switch_map__el.getBoundingClientRect();
	let map_origine__x = parseInt(switch_map__pos.left + (switch_map__pos.width / 2))
	let map_origine__y = parseInt(switch_map__pos.top + (switch_map__pos.height / 2))
	// Modifier le clip-path de la carte
	mapContainer__el.setAttribute("style", `clip-path: circle(1px at ${map_origine__x}px ${map_origine__y}px ); -webkit-clip-path: circle(1px at ${map_origine__x}px ${map_origine__y}px );`)
}


/**
 * 	Lists : Offers | Agencies…
 */
function initFilteredListMap() {
	let mapContainer = document.querySelector("#js-filtered-list--map")
	if (mapContainer) {
		new EntitiesMap(mapContainer)
	}
	else {
		mapContainer = document.querySelector("#js-filtered-list--async-map")
		let mapChoiceSelector = document.querySelector("#layout-choice-map")
		if (mapContainer && mapChoiceSelector) {
			const map = new EntitiesAsyncMap(mapContainer, mapChoiceSelector)
			map.init()
		}
	}
}



/**
 * SimpleMaps : petite carte simple, par exemple sur les details [Offers | Agencies…]
 *
 */
function initSimpleMap( mapSimple__Container ) {
	if (mapSimple__Container && mapSimple__Container != null) {
		new EntitiesSimpleMap( mapSimple__Container );
	}
}

/*
	Sliders
*/
import Glide, { Breakpoints } from "@glidejs/glide";
import BigPicture from "bigpicture"; // Lightbox avec BigPicture

//> Préférences de mouvement de l'utilisateur
const user_setting_motionReduce = window.matchMedia(
	"(prefers-reduced-motion: reduce)"
);
let user_setting_motionReduce_state = user_setting_motionReduce.matches;
/*
TODO : Ça plante dans Safari…
window.matchMedia('(prefers-reduced-motion: reduce)').addEventListener('change', () => {
	user_setting_motionReduce_state = window.matchMedia('(prefers-reduced-motion: reduce)').matches
})
*/

/* Note pour les slider :
 *
 * Désactiver les transition si demandé par l'utilisateur :
 *
 * options = {
 *	animationDuration: 1000 * Number( ! user_setting_motionReduce_state),
 * 	…
 * }
 */

!(function () {
	let sliders = document.querySelectorAll(".glide");
	if (sliders.length > 0) {
		//#region 		Réglages des slider GlideJS

		sliders.forEach((sliderItem) => {
			let options = {
				type: "slider",
				gap: 10,
				// autoplay: 			4000,
				autoplay: false,
				animationDuration:
					1000 * Number(!user_setting_motionReduce_state), // Désactiver les transition si demandé par l'utilisateur
				perView: 1,
				rewind: true,
				animationTimingFunc: "ease-in-out",
				focusAt: 0,
				breakpoints: {
					575: {
						animationDuration:
							300 * Number(!user_setting_motionReduce_state), // explication plus haut…
					},
				},
			};

			// ===========================================
			// Spécifités des sliders
			// ===========================================
			// Wrapper approx. 1.15 = small / 1.07 = medium

			// Passer en type:carrousel
			if (sliderItem.classList.contains("is-carrousel")) {
				options = { ...options, type: "carousel" };
			}

			// Component : Hero
			if (sliderItem.classList.contains("hero-slider")) {
				options = {
					...options,
					type: "carousel",
					rewind: false,
					autoplay: 4000,
				};
			}

			// Event Offers (annonces)
			if (sliderItem.classList.contains("event-offers-wrapper")) {
				options = { ...options,autoplay: 4000, gap: 0 };
			}

			// Carrousel en ligne : posts
			if (sliderItem.classList.contains("slider--in-line-carrousel")) {
				options = {
					...options,
					type: "carousel",
					perView: 4.1547,
					gap: 0,
					breakpoints: {
						...options.breakpoints,
						1920: { perView: 3.1547 },
						1600: { perView: 2.1547 },
						992: { perView: 2.1547 },
						768: { perView: 1.8 },
						575: {
							type: "slider",
							focusAt: "center",
							perView: "1.1",
						},
					},
				};
			}

			// Similars des détails
			if (sliderItem.classList.contains("slider--similars-carrousel")) {
				options = {
					...options,
					type: "carousel",
					perView: 4,
					gap: 0,
					breakpoints: {
						...options.breakpoints,
						1800: { perView: 4 },
						1600: { perView: 3 },
						992: { perView: 3 },
						768: { perView: 1.8 },
						575: {
							type: "slider",
							focusAt: "center",
							perView: "1.1",
						},
					},
				};
			}

			// Direction
			if (sliderItem.classList.contains("slider--direction--rtl")) {
				options = {
					...options,
					direction: "rtl",
					breakpoints: {
						...options.breakpoints,
						575: {
							direction: "ltr",
							type: "slider",
							focusAt: "center",
							perView: "1.1",
						},
					},
				};
			}

			// Centrer sur 1 &l&ment : Sur mobile
			if (sliderItem.classList.contains("slider-small--center")) {
				options = {
					...options,
					breakpoints: {
						...options.breakpoints,
						575: {
							type: "slider",
							focusAt: "center",
							perView: 1.2,
						},
					},
				};
			}

			let slider = new Glide(sliderItem, options);

			//#region 		Slider à pagination exterieure
			if (
				sliderItem.classList.contains(
					"js-slider-pagination-exterieur--slider"
				)
			) {
				// glide_exterior_navigation( sliderItem, '#js-slider-pagination-exterieur--pagination' )
				const el_pagination = document.querySelector(
					"#js-slider-pagination-exterieur--pagination"
				);
				if (el_pagination) {
					Array.from(
						el_pagination.querySelectorAll("[data-slider-index]")
					).forEach((el) => {
						el.addEventListener("click", (e) => {
							let goToSlide = "=" + e.target.dataset.index;
							slider.go(goToSlide);
							// el_pagination.go('='+(e.target.dataset.index))
						});
					});
					/* Ajouter les comportements si la pagination est un slider */
					//  slider.on('swipe.end', function() {
					//  	el_pagination.go('='+(slider.index))
					//  })
					//  slider.on('move.after', function() {
					//  	el_pagination.go('='+(slider.index))
					//  })
				}
			}
			//#endregion

			// Si le slider n'a qu'une image, on le désactive
			slider.on("mount.before", function () {
				if (sliderItem.querySelectorAll(".glide__slide").length <= 1) {
					slider.update({
						rewind: false,
					});
					slider.disable();
				}
			});

			if (sliderItem.classList.contains("glide--special_form")) {
				activerFormBySteps(sliderItem, 0, 768);
			}

			/**
			 * Définit l'état du slider en function ds breakpoints
			 */
			function activerFormBySteps(
				slideritem,
				breakpoint_Min,
				breakpoint_Max
			) {
				let wp_width = window.innerWidth;
				if (wp_width <= breakpoint_Max && wp_width >= breakpoint_Min) {
					slider.enable();
				} else {
					slider.disable();
				}
				slider.on("resize", function () {
					activerFormBySteps(window.innerWidth, sliderItem, 0, 768);
				});
				slider.on("load", function () {
					activerFormBySteps(window.innerWidth, sliderItem, 0, 768);
				});
			}

			slider.mount();

			//#region 	Lightbox avec BigPicture : bouton Fullscreen

			// Activer BigPicture sur le bouton full des images de slider
			var sliders_fullscreen_images_buttons = sliderItem.querySelectorAll(
				".glide__slides .slider-slide:not(.glide__slide--clone) .js-btn-fullscreen"
			);

			if (sliders_fullscreen_images_buttons)
			{

				sliders_fullscreen_images_buttons.forEach((btn) => {
					btn.addEventListener("click", function (e) {
						openGalleryBigPicture(e, sliders_fullscreen_images_buttons);
					});

					let slide_triggers = document.querySelectorAll('[data-slide-bp]');
					if (slide_triggers && slide_triggers.length > 0)
					{
						slide_triggers.forEach(slide_trigger => {
							slide_trigger.addEventListener('click', function(e)
							{
								openGalleryBigPicture(e, sliders_fullscreen_images_buttons, slide_trigger.getAttribute('data-slide-bp'));
							})
						});
					}
				});
			}

			//#endregion
		});
		//#endregion
	}
})();

function openGalleryBigPicture(elt, gallery, position = null)
{
	let collection = [];

	gallery.forEach(item => {
		if(item.getAttribute('data-bp'))
		{
			item.src = item.getAttribute('data-bp');
			item.caption = item.getAttribute('data-caption');
			collection.push(item);
		}
	});

	let options = {
		el: elt.target,
		gallery: collection,
		galleryAttribute: "data-bp",
	};

	if (position != null)
	{
		options.position = parseInt(position);
	}

	BigPicture(options);
}

import BigPicture from 'bigpicture'
import Calculator from './calculators/calculator'

// =====================================
// Offers
// =====================================

//#region  		filtered-list 		*/

/**
 * Comportement des pages de liste avec filtres et tri
 */
function filteredList_init() {
	if (document.querySelectorAll("#js-filtered-list").length > 0) {
		// Afficher la carte
		let show_filters_btn = document.querySelector(
			"#filters-visibles--toggle-btn"
		);
		if (show_filters_btn) {
			show_filters_btn.addEventListener("click", (e) => {
				// Le toggle est géré via data-toggle

				// ⬆️ Remonter en haut du formulaire
				let form = document.querySelector(".filtered-list--filters");
				form.scrollTo({
					top: 0,
					left: 0,
					behavior: "smooth",
				});
			});
		}

		//#region		Traiter la recherche
		let location_btn = document.querySelector(" .js-btn-show-results ");
		if (location_btn) {
			location_btn.addEventListener("click", () => {
				list_form_submited();
			});
		}

		/**
		 * Validation du formulaire
		 */
		function list_form_submited() {
			list_form_show_results();
		}

		/**
		 * Afficher les résultats :
		 *  Tiny + Small + Medium :
		 *   Il s'agit ici de masquer le formulaire,
		 *   pour laisser apparaitre les résultats en cochant trigant le bouton déjà existant
		 */
		function list_form_show_results() {
			// Small + Medium
			if (Modernizr.mq("(max-width: 767px)")) {
				// bouton à cibler
				let filters_btn = document.querySelector(
					" #filters-visibles--toggle-btn "
				);

				var evt = document.createEvent("MouseEvents");
				evt.initMouseEvent(
					"click",
					true,
					true,
					window,
					0,
					0,
					0,
					0,
					0,
					false,
					false,
					false,
					false,
					0,
					null
				);
				var canceled = !filters_btn.dispatchEvent(evt);
				if (canceled) {
					// A handler called preventDefault
					console.error("canceled");
				} else {
					// Remonter en haut de la liste top…
					window.scrollTo(0, 0);
				}
			}
		}

		//#endregion
	}
}
filteredList_init();
//#endregion

// #region Big Pictures
!(function () {
	let triggers = document.querySelectorAll("[data-bp-trigger]");
	triggers.forEach((trigger) => {
		trigger.addEventListener("click", function (e) {
			BigPicture({
				el: e.target,
				gallery: trigger.getAttribute('id'),
				galleryAttribute: "data-bp",
			});
		});
	});
})();
//#endregion

//Mensualité
document.addEventListener('DOMContentLoaded', function()
{
	let wrapper = document.querySelector('.price-monthly');

	if (!wrapper || wrapper.length > 0)
	{
		return;
	}

	if (!offer_vars || !offer_vars.offer_price || offer_vars.offer_price == 0)
	{
		return;
	}

	let price = offer_vars.offer_price;

	let calculator = new Calculator({
		'totalAmount': price
	});

	let result = calculator.result();

	if (result.monthlyPayment)
	{
		wrapper.innerHTML = "à partir de "+result.monthlyPayment+" € / mois<br />hors apport personnel";
	}
});

import EntitiesFavorites from './favorites/favorites';

/**
 * Actualiser les Favoris au chargement du DOM
 */
! function () {
    document.addEventListener('DOMContentLoaded', function () {
        initFavorites();
    });
}();

/**
 *
 */
function initFavorites()
{
    let triggers = document.querySelectorAll('[data-favorite]');

    if (triggers && triggers.length > 0)
    {
        new EntitiesFavorites('[data-favorite]');
    }
}

/**
 * Déclenche des événements d'apparition / disparition au clic sur un élément
 *
 * data-trigger = "xxx" L'élément cliquable
 * data-target = "xxx" L'élément à afficher / masquer
 * data-target-class = "is-expanded" >
 *
 * Au clic sur data-trigger, la classe "on" [+ la classe optionnelle précisée] est ajouté au déclencheur et à la cible
 *
 * Exemple :
 * 		<div class="cible" data-target="burger"> ;-) </div>
 * 		<button class="header-burger"
 * 			data-trigger="burger"
 * 			data-target-class="nav-is-expanded"> Hey ! </button>
 */
! function () {
    let triggers = document.querySelectorAll("[data-trigger]");
    triggers.forEach(function (trigger) {

		// Ajout des attributs Aria
		/*
			aria-expanded="false"
			aria-hidden="true"
			aria-labelledby="label_expand_1"
		*/



        // Au clic sur chaque trigger
        trigger.onclick = function () {

			// on toggle la classe "on"
			trigger.classList.toggle(['on'])

			// Ajout des attributs Aria
			/*
			aria-expanded="false"
			aria-hidden="true"
			aria-labelledby="label_expand_1"
			*/

            // on récupère les classes optionnelles associées
			let targets__class = trigger.getAttribute('data-target-class')

            // on récupère les target associées
            let value = trigger.getAttribute('data-trigger')
            let targets__list = document.querySelectorAll('[data-target="' + value + '"]')

            // on toggle la classe "on" sur chaque target
            targets__list.forEach(function (target) {
                target.classList.toggle('on')
                target.classList.toggle(targets__class)
			})

			// Aria :
			//change la valeur de aria-pressed quand le bouton est basculé :
			if ( trigger.getAttribute("aria-pressed") ) {
				let trigger_aria__state = trigger.getAttribute("aria-pressed") == "true";
				trigger.setAttribute("aria-pressed", trigger_aria__state ? "false" : "true");
			}
        }
    });
}();


/**
* Affiche / Masque des éléments au clic
* Applique la class "on", mais possibilité de la définir par data-target-class sur le trigger
*

*/
export function toggleEvents() {
	// Gère l'affichage / masquage d'éléments au clic sur un trigger
	$('[data-trigger]').each(function () {
		$(this).click(function () {

			var trigger = $(this)
			var target = trigger.attr('data-trigger')
			let triggerClass = ["on"]

			// classe cible
			let specialTargetClass = trigger.data("target-class")
			if ( specialTargetClass !== null &&
					specialTargetClass !== undefined )
			{
				triggerClass.push(trigger.data("target-class"))
			}
			// ajouter/suppr. chacune des classes du tableau

			triggerClass.forEach(theClass => {
				trigger.toggleClass( theClass )
			});

			triggerClass.forEach(theClass => {
				$('[data-target="' + target + '"]').each(function (e) {
					$(this).toggleClass(theClass)
				})
			});
		});
	});
};


/**
* Ajout de class au survol :
*
* Au survol d'un élément *[data-trigger-hover]
* le contenu du data est ajouté comme classe aux [target_hover]
*/
export function hoverEvents() {
	jQuery('[data-trigger-hover]').each(function () {
		var trigger_hover = jQuery(this)
		var target_hover = trigger_hover.attr('data-trigger-hover')
		jQuery(this).mouseenter(function () {
			jQuery('[data-target-hover="' + target_hover + '"]').each(function (e) {
				jQuery(this).addClass('on-hover')
				trigger_hover.addClass('on-hover')
			})
		})
		jQuery(this).mouseout(function () {
			jQuery('[data-target-hover="' + target_hover + '"]').each(function (e) {
				jQuery(this).removeClass('on-hover')
				trigger_hover.removeClass('on-hover')
			})
		})
	})
}


export function formatParams(params) {
    return "?" + Object
        .keys(params)
        .map(function (key) {
            return key + "=" + encodeURIComponent(params[key])
        })
        .join("&")
}

/**
 * Retourne la valeur d'un query string
 * @param { string } key La clé de la valeur a retourner
 */
export function getQueryString(key)
{
	const queryString = window.location.search;

	if (queryString)
	{
		const urlParams = new URLSearchParams(queryString);
		return  urlParams.get(key);
	}

	return false;
}

/**
 * Déclenche une action au bout d'un délai
 * @param { La fonction a exéctuter} callback
 * @param { Le temps de latence à appliquer avant le déclenchement de la fonction } delay
 */
export function debounce(callback, delay) {
    var timer;
    return function () {
        var args = arguments;
        var context = this;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, delay);
    };
}

/**
 * Permet de déclencher des événements de scroll au clic sur un élément
 * data-scroll-to = "xxx" Le sélecteur de l'élément cible
 */
!(function () {
	activateScrollTo();
})();

export function activateScrollTo()
{
	let triggers = document.querySelectorAll("[data-scroll-to]");

	if (!triggers || triggers.length == 0) {
		return;
	}

	triggers.forEach((trigger) => {
		let elt = document.querySelector(
			trigger.getAttribute("data-scroll-to")
		);

		if (!elt || elt.length == 0) {
			return;
		}

		trigger.addEventListener("click", function () {
			elt.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
		});
	});
}

import Cookies from "../cookies";

export class EntitiesTracking {
  constructor() {
    this.initTracking();
    // this.allowImmodvisor();
  }

  initTracking() {
    if (typeof tracking_vars == "undefined" || !tracking_vars) {
      return;
    }

    this.mainProperty = this.getMainProperty();
    this.userMarketingAcceptance = this.getUserMarketingAcceptance();

    if (this.mainProperty != null) {
      this.initGoogleTagTracking();
      this.initGoogleTagDate();

      if (tracking_vars.ga) {
        this.initGoogleAnalyticsTracking();
      }

      if (tracking_vars.adwords) {
        this.initAdwordsTracking();
      }

      if (tracking_vars.doubleClick) {
        this.initDoubleClickTracking();
      }
    }

    if (tracking_vars.facebook && this.userMarketingAcceptance) {
      this.initFacebookTracking();
      this.configFacebookTracking();
    }

    if (tracking_vars.gtm && this.userMarketingAcceptance) {
      this.configGTMTracking();
    }
  }

  /**
   * Récupère la propriété principale pour initialiser le script Google Analytics
   */
  getMainProperty() {
    if (tracking_vars.ga) {
      return tracking_vars.ga[0]; //On peut mettre plusieurs propriétés Analytics, c'est forcément un tableau
    }

    if (tracking_vars.adwords) {
      return tracking_vars.adwords;
    }

    if (tracking_vars.doubleClick) {
      return tracking_vars.doubleClick;
    }

    return null;
  }

  /**
   * Récupère le consentement de l'utilisateur
   */
  getUserMarketingAcceptance() {
    return Cookies.get("cmplz_marketing") == "allow";
  }

  /**
   * Ajoute le script Google Tag
   */
  initGoogleTagTracking() {
    let s = document.createElement("script");
    s.async = true;
    s.src = "https://www.googletagmanager.com/gtag/js?id=" + this.mainProperty;

    let head = document.querySelector("head");
    head.appendChild(s);

    if (typeof gtag == "undefined") {
      this.createGTagFunction();
    }
  }

  initGoogleTagDate(retry = false) {
    if (typeof gtag == "undefined" && retry == false) {
      setTimeout(function () {
        this.createGTagFunction();
        this.initGoogleTagDate(true);
      }, 500);
    }

    gtag("js", new Date());
  }

  //#region Analytics
  initGoogleAnalyticsTracking(retry = false) {
    let gaProperties = tracking_vars.ga;

    if (typeof gtag == "undefined" && retry == false) {
      setTimeout(function () {
        this.createGTagFunction();
        this.initGoogleAnalyticsTracking(true);
      }, 500);
    }

    if (typeof gtag != "undefined") {
      gaProperties.forEach((gaProperty) => {
        gtag("config", "" + gaProperty + "", {
          anonymize_ip: !this.userMarketingAcceptance,
        });
      });
    }
  }
  //#endregion

  //#region Adwords
  initAdwordsTracking(retry = false) {
    if (typeof gtag == "undefined" && retry == false) {
      setTimeout(function () {
        this.createGTagFunction();
        this.initAdwordsTracking(true);
      }, 500);
    }

    if (typeof gtag != "undefined") {
      gtag("config", "" + tracking_vars.adwords + "", {
        anonymize_ip: !this.userMarketingAcceptance,
      });
    }
  }
  //#endregion

  //#region DoubleClick
  initDoubleClickTracking(retry = false) {
    if (typeof gtag == "undefined") {
      setTimeout(function () {
        this.createGTagFunction();
        this.initDoubleClickTracking(true);
      }, 500);
    }

    if (typeof gtag != "undefined") {
      gtag("config", "" + tracking_vars.doubleClick + "", {
        anonymize_ip: !this.userMarketingAcceptance,
      });
    }
  }
  //#endregion

  //#region Facebook
  initFacebookTracking() {
    !(function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = "2.0";
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      "script",
      "https://connect.facebook.net/en_US/fbevents.js"
    );
  }

  configFacebookTracking() {
    fbq("init", "" + tracking_vars.facebook + "");
    fbq("track", "PageView");
  }
  //#endregion

  //#region GTM
  configGTMTracking() {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        "gtm.start": new Date().getTime(),
        event: "gtm.js",
      });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l != "dataLayer" ? "&l=" + l : "";
      j.async = true;
      j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
      f.parentNode.insertBefore(j, f);
    })(window, document, "script", "dataLayer", "" + tracking_vars.gtm + "");
  }
  //#endregion

  createGTagFunction() {
    if (typeof gtag == "undefined") {
      let gtagScript = document.createElement("script");
      gtagScript.innerHTML =
        "window.dataLayer = window.dataLayer || []; function gtag() { window.dataLayer.push(arguments); }";
      let head = document.querySelector("head");
      head.appendChild(gtagScript);
    }
  }

  allowImmodvisor() {
    if (this.getUserMarketingAcceptance()) {
      const widgetImmodvisorScript = document.querySelector(
        ".widget-avis script"
      );
      widgetImmodvisorScript.src =
        widgetImmodvisorScript.getAttribute("data-src");
    }
  }
}

!(function () {
  new EntitiesTracking();
})();

/**
 * Détecte le user agent et ajout une classe correspondant sur le body de la page
 */
! function () {
    var elt = document.querySelector('body');

    //#region Opera 8.0+
    var isOpera = (!!window.opr && !!opr.addons) || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
    if (isOpera)
        elt.classList.add('opera');
    //#endregion

    //#region Firefox 1.0+
    var isFirefox = typeof InstallTrigger !== 'undefined';
    if (isFirefox)
        elt.classList.add('firefox');
    //#endregion

    //#region Safari 3.0+ "[object HTMLElementConstructor]"
    var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) {
        return p.toString() === "[object SafariRemoteNotification]";
    })(!window['safari'] || safari.pushNotification);
    if (isSafari)
        elt.classList.add('safari');
    //#endregion

    //#region Internet Explorer 6-11
    var isIE = /*@cc_on!@*/ false || !!document.documentMode;
    if (isIE)
        elt.classList.add('internet-explorer');
    //#endregion

    //#region Edge 20+
    var isEdge = !isIE && !!window.StyleMedia;
    if (isEdge)
        elt.classList.add('edge');
    //#endregion

    //#region Chrome 1+
    var isChrome = !!window.chrome && !!window.chrome.webstore;
    if (isChrome)
        elt.classList.add('chrome');
    //#endregion

    //#region Blink engine detection
    var isBlink = (isChrome || isOpera) && !!window.CSS;
    if (isBlink)
        elt.classList.add('blink');
    //#endregion
}();


//#region Polyfills

/**
 * Polyfills de forEach (ES5)
 */
! function () {
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}
}();

//#endregion


/**
 * Tester le support de fonctions CSS nous couvertent par Modernizr
 */
(function(Modernizr){
	var tests = [
		// Variantes de ClipPath :
		// { name: 'svg', value: 'url(#test)' }, // False positive in IE, supports SVG clip-path, but not on HTML element
		// { name: 'inset', value: 'inset(10px 20px 30px 40px)' },
		// { name: 'circle', value: 'circle(60px at center)' },
		// { name: 'ellipse', value: 'ellipse(50% 50% at 50% 50%)' },
		{ name: 'polygon', value: 'polygon(50% 0%, 0% 100%, 100% 100%)' }
	];
	var t = 0, name, value, prop;
	for (; t < tests.length; t++) {
		name = tests[t].name;
		value = tests[t].value;
		Modernizr.addTest('cssclippath' + name, function(){
			// Essayez d'utiliser window.CSS.supports
			if ( 'CSS' in window && 'supports' in window.CSS ) {
				for (var i = 0; i < Modernizr._prefixes.length; i++) {
					prop = Modernizr._prefixes[i] + 'clip-path'
					if ( window.CSS.supports(prop,value) ) { return true; }
				}
			return false;
			}
			// Sinon, on passe par Modernizr.testStyles et tester la propriété manuellement
			return Modernizr.testStyles('#modernizr { '+Modernizr._prefixes.join('clip-path:'+value+'; ')+' }',function(elem, rule) {
				var style = getComputedStyle(elem),
					clip = style.clipPath;
				if ( !clip || clip == "none" ) {
					clip = false;
					for (var i = 0; i < Modernizr._domPrefixes.length; i++) {
						test = Modernizr._domPrefixes[i] + 'ClipPath';
						if ( style[test] && style[test] !== "none" ) {
							clip = true;
							break;
						}
					}
				}
				return Modernizr.testProp('clipPath') && clip;
			});
		});
	}
})(Modernizr);


/**
 * indique si le header est naturellement fixé en haut de la page
 * ou si le scroll est en cours
 *
 */
export function header_fixed() {
	return window.pageYOffset || docElem.scrollTop
}



/**
 * Se base sur le viewport pour vérifier l'affichage
 *
 */
export function isMobile() {
	return window.matchMedia("(max-width: 576px)").matches // true or false
}



/**
 * Détermine si un élément est complètement visible ou non
 *
 */
export function isInViewport(elem) {
    var bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

/**
 * Déclenche une action au bout d'un délai
 * @param { La fonction a exéctuter} callback 
 * @param { Le temps de latence à appliquer avant le déclenchement de la fonction } delay 
 */
export function debounce(callback, delay) {
    var timer;
    return function () {
        var args = arguments;
        var context = this;
        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, delay)
    }
}

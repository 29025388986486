/*
	BigPicture :
	Vanilla JavaScript image / video viewer. Doesn't sit on the DOM when inactive.
	=> https://github.com/henrygd/bigpicture
*/
import BigPicture from 'bigpicture'	// Lightbox avec BigPicture

/* Utilisation :

	Indiquer  =>  ".js-bigpicture"  pour activer le comportement
	Ensuite, il est possible d'utiliser data-bigp-xxx =*
	pour spécefier un comportement.

	Ajouter une description :
	==============
		Ajouter une description en bas de l'écran :
		<element data-caption="Ma description à afficher." />

	Image :
	==============
		<img src="img-1.jpg" data-bigp-img="img-2.jpg"
			data-caption="Ma description à afficher."
		/>

	Galerie d'images :
	==============
		indiquer sur le conteneur data-bigp-galerie="#images_container"
		+ ajouter : data-bp="image_a_afficher.jpg" sur l'élément
		```
		<div id="image_container" data-bigp-galerie="#image_container"  >
			<img src="img1_thumb.jpg" data-bp="img1-size-big.jpg" />
			<img src="img2_thumb.jpg" data-bp="img2-size-big.jpg" />
			<img src="img3_thumb.jpg" data-bp="img3-size-big.jpg" />
		</div>
		```

	iFrame :
	==============
		<button data-bigp-iframe="https://google.fr"> Ouvrir l'iframe </button>

	Vidéos :
	==============
		Vidéo simple : 	<button data-bigp-video="https://mon-url/video.mp4"> Lancer la vidéo </button>
		Vidéo Vimeo : 	<button data-bigp-vimeo="XXXXXXXXX"> Lancer la vidéo </button>
		Vidéo Youtube : <button data-bigp-youtube="abc_youtube_ID"> Lancer la vidéo </button>

	Dimensions : Spécifier des dimensions à l'embed / Vidéo / … :
	==============
		<img src="img.jpg" data-bigp-dimensions = "[1920, 1080]" />

	Audio :
	==============
		<button data-bigp-audio="https://mon-url/xxx.mp3" /> Balance le son… <button>

	Autres options : les callback :
	==============
		animationStart: () => {},		// open animation callback
		animationEnd: 	() => {},		// open animation callback
		onClose:		() => {},		// close callback
		onChangeImage: 	() => {},		// gallery image change callback

*/

! function () {
	let elmts_bigPicture = document.querySelectorAll('.js-bigpicture');
	if ( elmts_bigPicture.length > 0 )
	{

		elmts_bigPicture.forEach( el_bigP => {
			el_bigP.addEventListener('click', function ( e ) {
				e.preventDefault()

				let options = {
					el: el_bigP,				// L'élément bigPicture (required)
					galleryAttribute: 'data-bp', // attribute used to find gallery elements
					noLoader: true,				// Afficher le loader
					onError: function () {
						console.log('Une erreur BigPicture ou d\'image sur cet élément : ', el_bigP)
					},
				};

				// ===========================================
				// Options
				// ===========================================
				//
				// Image simple
				//   <img data-bigp-img="xxx-big.jpg" />
				if ( el_bigP.dataset.bigpImg ) {
					const val = el_bigP.dataset.bigpImg;
					options = {...options,
						imgSrc:  val,
					}
				}
				//
				// Galerie d'images
				// 		data-bigp-galerie = "#galerie-container"
				if ( el_bigP.dataset.bigpGalerie ) {
					const galerie_container = el_bigP.dataset.bigpGalerie;
					options = {...options,
						gallery: galerie_container,
						galleryAttribute: 'data-bp',
					}
				}
				//
				// iFrame   iframeSrc
				// 		data-bigp-iframe : https://url-de-l-iframe.html
				if ( el_bigP.dataset.bigpIframe ) {
					const url_iframe = el_bigP.dataset.bigpIframe;
					options = {...options,
						iframeSrc: url_iframe,
					}
				}
				//
				// Vidéo distante via url
				// 	String -ou- Array
				// 		data-bigp-video: ['https://la-video.mp4', 'https://l-autre-video.webm'],
				if ( el_bigP.dataset.bigpVideo ) {
					const url = el_bigP.dataset.bigpVideo;
					options = {...options,
						vidSrc: url,
					}
				}
				//
				// Vidéo : Vimeo
				// 		data-bigp-vimeo = "XXXXXXXXX"
				if ( el_bigP.dataset.bigpVimeo ) {
					const vimeo_ID = el_bigP.dataset.bigpVimeo;
					options = {...options,
						vimeoSrc: vimeo_ID,
					}
				}
				//
				// Vidéo : YouTube
				// 		data-bigp-youtube = "abc_youtube_ID"
				if ( el_bigP.dataset.bigpYoutube ) {
					const youtube_ID = el_bigP.dataset.bigpYoutube;
					options = {...options,
						ytSrc: youtube_ID,
					}
				}
				//
				// Option : dimensions personnalisées pour l'embed / Vidéo …
				// 		data-bigp-dimensions = "[1920, 1080]"
				if ( el_bigP.dataset.bigpDimention ) {
					const dimensions_array = el_bigP.dataset.bigpDimention;
					options = {...options,
						dimensions: dimensions_array,
					}
				}
				//
				// Audio
				// 		data-bigp-audio = "https://mon-url/xxx.mp3"
				if ( el_bigP.dataset.bigpAudio ) {
					const url = el_bigP.dataset.bigpAudio;
					options = {...options,
						vidSrc: url,
					}
				}



				// ===========================================
				// Spécifités
				// ===========================================

				// =======================
				// fin des spéficités
				// =======================




				// initialiser l'élément
				let bp = new BigPicture( options );
			})
		});
	}

}();

import { EntitiesNotification } from './notifications/notification';
import { EntitiesPopup } from './popups/popup';
import { AnalyticsEvent } from './tracking/trackAnalytics';

!(function () {
    initPopups();
	logInForAction();
})();

function initPopups() {

    //Récupération des triggers
    let triggers = document.querySelectorAll('[data-popup]');

    if (triggers && triggers.length > 0) {

        triggers.forEach((trigger) => {
			trigger.setAttribute('aria-haspopup', 'dialog'); // indique l'ouverture de la pop-up
			trigger.setAttribute('role', 'button'); // pour que l'Aria soit pris en compte

			// Pour chaque trigger :
            trigger.addEventListener('click', function () {
				// Récupérer les informations de la popup
				let popup_title_level = ( !!trigger.getAttribute("data-popup-title-level") ? trigger.getAttribute("data-popup-title-level") : null )
				let popup_title		  = ( !!trigger.getAttribute("data-popup-title")  ? trigger.getAttribute("data-popup-title") : null )
				let popup_class 	  = ( !!trigger.getAttribute("data-popup-class")  ? trigger.getAttribute("data-popup-class") : 'test' )
				// Passer tout ça…
                new EntitiesPopup(trigger.getAttribute('data-popup'), {
                    popup_title: popup_title,
                    popup_title_level: popup_title_level,
                    popup_class: popup_class,
                });
			});

        });
    }
}

function logInForAction()
{
	let triggers = document.querySelectorAll('[data-login-action]');

    if (triggers && triggers.length > 0) {

        triggers.forEach((trigger) => {
			trigger.setAttribute('aria-haspopup', 'dialog'); // indique l'ouverture de la pop-up
			trigger.setAttribute('role', 'button'); // pour que l'Aria soit pris en compte

			let returnUrl = trigger.getAttribute('data-return-url');
			let action = trigger.getAttribute('data-login-label');
			let offer_name = trigger.getAttribute('data-offer-name');

			// Pour chaque trigger :
            trigger.addEventListener('click', function () {

				//Ajout d'une action clic pour mesurer la demande
				new AnalyticsEvent({
					category: 'Clic',
					action: action,
					label: offer_name
				});

				let message = {
					title: `<span class="icon-g-check"></span> Visualiser les plans`,
					text: `<p class="mbm">Vous devez vous connecter pour accéder aux plans de cette maison.</p>`,
					ctas: [
						{
							attributes: {
								class: "btn",
							},
							anchor:
								'<span class="icon-g-profil"></span> <span style="font-size: 13px;">Créer un compte</span>',
							url:
								php_vars.connexion_url +
								"?return_url=" +
								returnUrl + "_**_actionCrm=EspacePersonnel_**_labelCrm="+action+"#account-tab__inscription",
						},
						{
							attributes: {
								class: "btn",
							},
							anchor:
								'<span class="icon-g-compte"></span> Se connecter',
							url:
								php_vars.connexion_url +
								"?return_url=" +
								returnUrl + "_**_actionCrm="+action+"_**_labelCrm="+offer_name+"#account-tab__connexion",
						}
					],
				};
				new EntitiesNotification(message);
			});
        });
    }
}

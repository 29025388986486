export class InfiniteScroll {
    constructor(search, mapSelector = '#layout-choice-map') {
        this.search = search
        this.scrollPos = -1
        this.enabled = true

        //Si un switch pour passer en mode carte est présent, on s'abonne à l'event change pour savoir si la carte est affichée
        this.addToggleMapListener(mapSelector)

        document.addEventListener('scroll', () => {
            if (this.enabled) {
                let bounds = document.body.getBoundingClientRect();

                //Vérifie si l'internaute scroll vers le bas de la page
                if (bounds.top < this.scrollPos) {
                    this.search.loadMore()
                }
                this.scrollPos = bounds.top
            }
        }, false)
    }

    /**
     * Ajoute un écouteur d'évènement sur le switch
     */
    addToggleMapListener(mapSelector) {
        let me = this
        if (mapSelector != null) {
            const toggleMapElement = document.querySelector(mapSelector)

            if (toggleMapElement != null) {
                toggleMapElement.addEventListener('change', (e) => me.toggleEnabled(e.target))
                this.toggleEnabled(toggleMapElement)
            }
        }
    }

    /**
     * Active ou désactive le scroll infini
     * @param {*} toggleMapElement 
     */
    toggleEnabled(toggleMapElement) {
        this.enabled = !toggleMapElement.checked
    }
}

export class AnalyticsEvent {
	constructor(params = null) {
		if (!params || params == null) {
			console.warn("bad initialization");
			return;
		}

		if (typeof gtag == "undefined") {
			console.warn(
				"gtag is not defined. You have to activate gtag in the backoffice"
			);
			return;
		}

		if (!params.category || params.category == null) {
			console.warn("you must specify a category for the event");
			return;
		}

		if (!params.action || params.action == null) {
			console.warn("you must specify an action for the event");
			return;
		}

		if (!params.label || params.label == null) {
			console.warn("you must specify a label for the event");
			return;
		}

		this.category = params.category;
		this.action = params.action;
		this.label = params.label;

		this.createEvent();
	}

	createEvent() {
		gtag("event", this.action, {
			event_category: this.category,
			event_label: this.label,
		});
	}
}

export class DoubleClickEvent {
	constructor(params = null) {

		if (!params || params == null) {
			console.warn("bad initialization");
			return;
		}

		if (!params.propertyId || params.propertyId == null) {
			console.warn("You must provide a property Id");
			return;
		}

		if (!params.propertyCategory || params.propertyCategory == null) {
			console.warn("you must specify a category for the event");
			return;
		}

		if (!params.propertyType || params.propertyType == null) {
			console.warn("you must specify a type for the event");
			return;
		}

		if (!params.formType || params.formType == null) {
			console.warn("You must provide the form Type");
			return;
		}

		if (!params.prospectId || params.prospectId == null) {
			console.warn("You must provide a propsect Id");
			return;
		}

		let axel = Math.random() + "";
		this.secureNumber = axel * 10000000000000;

		this.property = tracking_vars.doubleClick;
		this.propertyId = params.propertyId;
		this.propertyType = params.propertyType;
		this.propertyCategory = params.propertyCategory;
		this.formType = params.formType;
		this.prospectId = params.prospectId;
		this.zipCode = params.zipCode ?? "";
		this.town = params.town ?? "";

		this.createEvent();
	}

	createEvent() {
		let frameURL =
			"https://" +
			this.propertyId +
			".fls.doubleclick.net/activityi;src=" +
			this.propertyId +
			";type="+this.propertyType+";cat="+this.propertyCategory+";u4=" +
			this.formType +
			";u3=" +
			this.zipCode +
			";u1=" +
			this.prospectId +
			";u2=" +
			this.town +
			";dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=" +
			this.secureNumber +
			"?";

		let iframe = document.createElement("iframe");
		iframe.setAttribute("src", frameURL);
		iframe.setAttribute("width", 1);
		iframe.setAttribute("height", 1);
		iframe.setAttribute("frameborder", 0);
		iframe.style.display = "none";

		let body = document.querySelector("body");
		body.appendChild(iframe);

		if (typeof gtag != "undefined") {
			gtag("event", "conversion", {
				allow_custom_scripts: true,
				u1: this.prospectId,
				u2: this.town,
				u3: this.zipCode,
				u4: this.formType,
				send_to: this.property+"/"+this.propertyType+"/audie0+standard",
			});
		}
	}
}

export class AdwordsEvent{

	constructor(params = null) {
		if (!params || params == null) {
			console.warn("bad initialization");
			return;
		}

		if (typeof gtag == "undefined") {
			console.warn(
				"gtag is not defined. You have to activate gtag in the backoffice"
			);
			return;
		}

		if (!params.event || params.event == null) {
			console.warn("you must specify an event for the event");
			return;
		}

		if (!params.send_to || params.send_to == null) {
			console.warn("you must specify a send to parameter for the event");
			return;
		}

		if (!params.email || params.email == null) {
			console.warn("you must specify the visitor email for the event");
			return;
		}

		this.event = params.event;
		this.send_to = params.send_to;
		this.transaction_id = this.generateTransactionId(params.email);

		this.createEvent();
	}

	/**
	 * Génère un numéro de transaction unique
	 * @param {string} email
	 * @returns l'email de l'internaute ayant soumis le formulaire
	 */
	generateTransactionId(email)
	{
        const keyDateStart = Math.floor(new Date("2014","02","23") / 60000);
        const keyDateEnd = Math.floor(new Date() / 60000);
        const transaction_id = keyDateEnd - keyDateStart;

		return transaction_id + "-" + email;

	}

	/**
	 * Envoie l'événement à Analytics
	 */
	createEvent() {
		gtag("event", this.event, {
			"send_to": this.send_to,
			"transaction_id": this.transaction_id
		});
	}
}

export class EntitiesNotification
{
    constructor(params = {}) {
        this.title = params.title ?? null;
        this.text = params.text ?? null;
        this.ctas = params.ctas ?? null;
        this.wrapper = params.wrapper ? params.wrapper : document.querySelector('body');
        this.class = params.class ?? 'entities-notification';
        this.boxWrapper = null;
        this.box = null;
        this.closeButton = null;

        this.createElement();
        this.events();
        this.render();
    }

    /**
     * Construit la div de message de notification
     */
    createElement()
    {
       const wrapper = document.createElement('div');
        wrapper.classList.add(this.class+'--wrapper');

        let box = document.createElement('div');
        box.classList.add(this.class);
        box.setAttribute('role', 'alert');
        wrapper.appendChild(box);

        //Titre
        if (this.title)
        {
            let title = document.createElement('div');
            title.classList.add(this.class+'--title');
            title.innerHTML = this.title;
            box.appendChild(title);
        }

        //Texte
        if (this.text)
        {
            let text = document.createElement('div');
            text.classList.add(this.class+'--text');
            text.innerHTML = this.text;
            box.appendChild(text);
        }

		// Les boutons :
    	if (this.ctas)
        {
			let ctas_container = document.createElement('div');
			ctas_container.classList.add(this.class+'--ctas-container');

			Array.prototype.forEach.call(this.ctas, cta => {
				if (cta && cta.anchor && cta.url)
				{
					let elm_cta = document.createElement('a');
					elm_cta.innerHTML = cta.anchor;
					elm_cta.setAttribute('href', cta.url);
					if ( cta.attributes )
					{
						Object.keys(cta.attributes).forEach(function (ctaAttribute) {
							let the_attribute = ctaAttribute;
							let the_value= cta.attributes[ctaAttribute];
							elm_cta.setAttribute(the_attribute, the_value);

							// si le bouton contient l'action "close"
							if (the_value === 'close')
							{
								elm_cta.addEventListener('click', function()
								{
									wrapper.remove();
								});
							}
						});
					}
					elm_cta.classList.add('btn');
					ctas_container.appendChild(elm_cta);
				}
			});
			box.appendChild( ctas_container );
        }

        //Bouton de fermeture
        let closeButton = document.createElement('button');
        closeButton.setAttribute('type', 'button');
        closeButton.setAttribute('title', 'Fermer');
        closeButton.classList.add(this.class+'--close');
        let closeButtonIcon = document.createElement('span');
        closeButtonIcon.classList.add('icon-g-fermer');
        closeButton.appendChild(closeButtonIcon);

        box.appendChild(closeButton);

        this.closeButton = closeButton;
        this.box = box;
        this.boxWrapper = wrapper;
    }

    events()
    {
        if (this.closeButton)
        {
            let ref = this;
            this.closeButton.addEventListener('click', function()
            {
                ref.boxWrapper.remove();
            });
        }
    }

    render()
    {
        this.wrapper.appendChild(this.boxWrapper);
    }
}

/**
 * Scripts des pages de Détails :
 */
!(function () {
    initDetail();
})();

function initDetail() {
	// Nous sommes sur une page de détail
	let is_page_detail = document.querySelector('body.model-detail, body.single-entities_land_houses');
    if ( is_page_detail )
	{
		/**
		 * Triggers vers le vrai bouton de fullscren
		 */
		let btn_triggers_fullscreen = document.querySelectorAll('.js-trigger-fullscreen');
		if (btn_triggers_fullscreen && btn_triggers_fullscreen.length > 0) {

			// identifier le "vrai" bouton de plein écran d'origine
			const btn_fullscreen_orig = document.querySelector('.js-btn-fullscreen');
			if ( ! btn_fullscreen_orig ) {
				console.error("Aucun bouton de plein écran.")
				return false
			}
			// identifier les autres boutons
			let btn_triggers_fullscreen = document.querySelectorAll('.js-trigger-fullscreen')
			if (btn_triggers_fullscreen && btn_triggers_fullscreen.length > 0) {

				// le lier au faux bouton
				btn_triggers_fullscreen.forEach((trigger) => {
					trigger.addEventListener('click', function (e) {
						// Créer l'événement
						let event_click_fullscreen = new MouseEvent('click', {
							'view': window,
							'bubbles': true,
							'cancelable': true
						});
						btn_fullscreen_orig.dispatchEvent(event_click_fullscreen)
					})
				})
			}
		}
    }
}



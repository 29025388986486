class WarningPopup {
	constructor(popup) {
		this.popup = popup;

		this.init();
	}

	/**
	 * Initialise les événements
	 */
	init() {
		this.closeAfterLoad();
		this.showOnTriggerClick();
		this.closeOnCloseButtonClick();
	}

	/**
	 * Ouvre la popup au clic sur le bouton
	 */
	showOnTriggerClick() {
		const me = this;
		let handler = this.popup.querySelector(".warning-popup-handler");

		if (handler) {
			handler.addEventListener("click", () => {
				me.openPopup();
			});
		}
	}

	/**
	 * Ferme la popup au clic sur le bouton
	 */
	closeOnCloseButtonClick() {
		const me = this;
		let handler = this.popup.querySelector(".warning-popup-close-icon");

		if (handler) {
			handler.addEventListener("click", () => {
				me.closePopup();
			});
		}
	}

	/**
	 * Ferme la popup 2 secondes après le chargement de la tête
	 */
	closeAfterLoad() {
		const me = this;
		setTimeout(me.closePopup.bind(this), 2000);
	}

	/**
	 * Ouvre la popup
	 */
	openPopup() {
		if (this.popup.classList.contains("minimize")) {
			this.popup.classList.remove("minimize");
		}
	}

	/**
	 * Ferme la popup
	 */
	closePopup() {
		if (!this.popup.classList.contains("minimize")) {
			this.popup.classList.add("minimize");
		}
	}
}

!(function () {
	let popups = document.querySelectorAll(".warning-popup");
	if (popups && popups.length > 0) {
		popups.forEach((popup) => {
			new WarningPopup(popup);
		});
	}
})();

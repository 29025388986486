import { debounce } from './utils';

window.addEventListener('scroll', debounce(function () {
	isStickyHeader();
}, 50));

/**
 * Ajoute une classe lorsque que le header est sticky au scroll
 */
function isStickyHeader()
{
    let header = document.querySelector('.js-header');

    if (header == null) {
        return;
    }

    let scrollTop = window.scrollY;
    let body = document.querySelector('body');

    if (scrollTop > 10) {
        header.classList.add('is-fixed');
        body.classList.add('is-fixed');
    } else if (scrollTop < 10) {
        header.classList.remove('is-fixed');
        body.classList.remove('is-fixed');
    }
}